import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Is the text or icon or element you want the tooltip to appear over when you hover*/
const TooltipPassage = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-text-passage", className)} {...other}>
      {children}
    </div>
  );
};

TooltipPassage.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

TooltipPassage.defaultProps = {
  className: "",
  children: null
};

export default TooltipPassage;

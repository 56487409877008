import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronUpIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14 11.439L8.513 5.811a.158.158 0 0 0-.222 0L2.8 11.439l-.716-.7L7.58 5.112a1.161 1.161 0 0 1 1.644 0l5.5 5.633z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40.5 34.914L25.414 19.828a2.049 2.049 0 0 0-2.828 0L7.5 34.914 6.086 33.5l15.086-15.086a4.1 4.1 0 0 1 5.656 0L41.914 33.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.886 17.553l-8.49-8.7-8.475 8.7-1.432-1.4 8.491-8.7a2 2 0 0 1 2.838-.009l8.5 8.709z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronUpIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronUpIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronUpIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ShoppingCartIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.5 15A1.5 1.5 0 1 1 6 13.5 1.5 1.5 0 0 1 4.5 15zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM11.5 15a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM13.5 11h-10a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5zM4 10h9V5H4z"
          fill="currentColor"
        />
        <path
          d="M11.5 13H2.1a.5.5 0 0 1-.409-.787l1.4-2 .818.574L3.061 12H11.5zM1.406 2.361l.832-.555 1.755 2.632-.832.555z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M13 46a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM37 46a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM42 30H10a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h32a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-31-2h30V12H11z"
          fill="currentColor"
        />
        <path
          d="M43.507 36.007H5.5a1.006 1.006 0 0 1-.834-1.57L9.284 27.6l1.668 1.127L7.4 33.993h36.107zM4.613 4.722l1.664-1.11 5.11 7.665-1.665 1.11z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M13 13h2v17h-2zM17 13h2v17h-2zM21 13h2v17h-2zM25 13h2v17h-2zM29 13h2v17h-2zM33 13h2v17h-2zM37 13h2v17h-2z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M6 23a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zM18 23a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zM20 15H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h15a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zM6 13h13V7H6z"
          fill="currentColor"
        />
        <path
          d="M22 19H2a1 1 0 0 1-.8-1.6l3.6-4.8 1.6 1.2L4 17h18zM.6 1.8L2.2.6l4.201 5.6-1.6 1.201z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ShoppingCartIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ShoppingCartIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ShoppingCartIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SunIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 12A4.5 4.5 0 1 1 13 7.5 4.5 4.5 0 0 1 8.5 12zm0-8A3.5 3.5 0 1 0 12 7.5 3.5 3.5 0 0 0 8.5 4zM8 0h1v2H8zM2.545 2.254l.708-.708 1.866 1.867-.707.707zM1 7h2v1H1zM2.546 12.747l1.867-1.867.707.707-1.867 1.867zM8 13h1v2H8zM11.88 11.587l.707-.707 1.867 1.866-.707.708zM14 7h2v1h-2zM11.88 3.413l1.867-1.867.707.707-1.867 1.867z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 37a13 13 0 1 1 13-13 13.014 13.014 0 0 1-13 13zm0-24a11 11 0 1 0 11 11 11.012 11.012 0 0 0-11-11z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M23 1h2v8h-2zM6.293 7.707l1.414-1.414 6 6-1.414 1.414zM1 23h8v2H1zM5.308 40.278l5.97-5.97 1.415 1.414-5.971 5.97zM23 39h2v8h-2zM34.293 35.707l1.414-1.414 6 6-1.414 1.414zM39 23h8v2h-8zM34.293 12.293l6-6 1.414 1.415-6 6z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 18.739A6.739 6.739 0 1 1 18.739 12 6.747 6.747 0 0 1 12 18.739zm0-11.478A4.739 4.739 0 1 0 16.739 12 4.745 4.745 0 0 0 12 7.261z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11.522 0h2v4.174h-2zM2.44 3.853L3.854 2.44l2.951 2.952-1.414 1.414zM0 11.522h4.174v2H0zM2.439 20.148l2.951-2.951 1.414 1.414-2.951 2.951zM11.522 19.826h2V24h-2zM17.195 18.609l1.415-1.415 2.952 2.953-1.414 1.414zM19.826 11.522H24v2h-4.174zM17.196 5.39l2.951-2.95 1.414 1.413-2.951 2.952z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SunIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SunIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SunIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaBlockBodyHeadline = props => {
  const { children, className, ...other } = props;
  return (
    <h2 className={clsx("em-c-media-block__headline", className)} {...other}>
      {children}
    </h2>
  );
};

MediaBlockBodyHeadline.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

MediaBlockBodyHeadline.defaultProps = {
  className: "",
  children: null
};

export default MediaBlockBodyHeadline;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import CardBody from "./CardBody";
import CardFooter from "./CardFooter";
import CardFlag from "./CardFlag";
import CardList from "./CardList";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-card--main";
    case "accent":
      return "em-c-card--accent";
    case "action":
      return "em-c-card--action";
    case "caution":
      return "em-c-card--caution";
    case "negative":
      return "em-c-card--negative";
    case "positive":
      return "em-c-card--positive";
    case "gray":
      return "em-c-card--gray";
    case "vermilion":
      return "em-c-card--vermilion";
    case "cyan":
    default:
      return "";
  }
}

const Card = props => {
  const { color, capped, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-card",
        "vcard",
        { "em-c-card--capped": capped },
        getColor(color),
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

Card.Body = CardBody;
Card.Footer = CardFooter;
Card.Flag = CardFlag;
Card.List = CardList;

Card.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Color of the top border. Must be in combination with capped */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "action",
    "caution",
    "negative",
    "positive",
    "gray",
    "vermilion",
    "cyan"
  ]),
  /** Makes the top border thicker */
  capped: PropTypes.bool,
  /** React component children */
  children: PropTypes.node
};

Card.defaultProps = {
  capped: false,
  color: "cyan",
  className: "",
  children: null
};

export default Card;

import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import PropTypes, { oneOfType } from "prop-types";
import Pikaday from "pikaday-ssrfix";
import clsx from "clsx";
import { CalendarIcon } from "../Icons";
import Field from "../Field";

/**
 * Input field that allows the user to type in a date or pick it from a calendar widget.
 *
 * @visibleName Date picker
 * */
const DatePicker = props => {
  const {
    value,
    format,
    label,
    note,
    onChange,
    valid,
    error,
    readOnly,
    disabled,
    id,
    placeholder,
    name,
    className,
    children,
    ...other
  } = props;

  const datePickerRef = useRef();

  useEffect(() => {
    new Pikaday({
      field: ReactDOM.findDOMNode(datePickerRef.current),
      format,
      toString: (date, format) => date.toDateString(),
      onSelect: e => onChange({ target: { value: e, name } })
    });
  }, []);

  return (
    <Field
      className={clsx("em-c-field--date-picker", className)}
      error={error}
      valid={valid}
      disabled={disabled}
      readOnly={readOnly}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <input
          name={name}
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e)}
          disabled={disabled}
          readOnly={readOnly}
          className="em-c-input em-c-field__input"
          ref={datePickerRef}
        />
        <CalendarIcon className="em-c-field__icon z-index-10" />
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

DatePicker.propTypes = {
  /** value of the datepicker */
  value: PropTypes.string,
  /** format of date string displayed */
  format: PropTypes.string,
  /** label above the datepicker */
  label: oneOfType([PropTypes.string, PropTypes.number]),
  /** note below the datepicker */
  note: oneOfType([PropTypes.string, PropTypes.number]),
  /** function emitted when the value changes */
  onChange: PropTypes.func,
  /** If the field is valid or not */
  valid: PropTypes.bool,
  /** If the field is error or not */
  error: PropTypes.bool,
  /** If the field is read-only or not */
  readOnly: PropTypes.bool,
  /** if the field is disabled or not */
  disabled: PropTypes.bool,
  /** placeholder text for when the field is empty */
  placeholder: PropTypes.string,
  /** name for accessibility */
  name: PropTypes.string,
  /** id for accessibility */
  id: PropTypes.string,
  /** additional css classes to add to the parent outer element */
  className: PropTypes.string
};

DatePicker.defaultProps = {
  value: "",
  format: "EEEE, d MMMM yyyy",
  label: "",
  note: "",
  onChange: () => {},
  valid: false,
  error: false,
  readOnly: false,
  disabled: false,
  name: "",
  id: "",
  placeholder: "",
  className: ""
};

export default DatePicker;

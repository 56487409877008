import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import FieldLabel from "./FieldLabel";
import FieldBody from "./FieldBody";
import FieldNote from "./FieldNote";

/** A Field has built in styling for different states such as default, valid, invalid, read-only, and disabled. This is the building blocks of most form inputs*/
const Field = props => {
  const {
    disabled,
    readOnly,
    valid,
    error,
    size,
    inline,
    className,
    children,
    innerRef,
    ...other
  } = props;

  return (
    <div
      className={clsx(
        "em-c-field",
        {
          "em-is-disabled": disabled,
          "em-is-readonly": readOnly,
          "em-is-valid": valid,
          "em-c-field--inline": inline,
          "em-has-error": error,
          "em-c-field--small": size === "small"
        },
        className
      )}
      ref={innerRef}
      {...other}
    >
      {children}
    </div>
  );
};

Field.propTypes = {
  /** If the field is valid or not */
  valid: PropTypes.bool,
  /** If the field is error or not */
  error: PropTypes.bool,
  /** If the field is read-only or not */
  readOnly: PropTypes.bool,
  /** if the field is disabled or not */
  disabled: PropTypes.bool,
  /** Flexes the field instead of stacks */
  inline: PropTypes.bool,
  /** Changes the size of the field's children */
  size: PropTypes.oneOf(["default", "small"]),
  /**children */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Field.defaultProps = {
  valid: false,
  error: false,
  readOnly: false,
  disabled: false,
  children: null,
  inline: false,
  size: "default",
  className: ""
};

Field.Label = FieldLabel;
Field.Body = FieldBody;
Field.Note = FieldNote;

export default Field;

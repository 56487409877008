import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PictureCardBodyKicker from "./PictureCardBodyKicker";
import PictureCardBodyTitle from "./PictureCardBodyTitle";
import PictureCardBodyDescription from "./PictureCardBodyDescription";

const PictureCardBody = props => {
  const { className, children, ...other } = props;
  return (
    <div className={clsx("em-c-picture-card__body", className)} {...other}>
      {children}
    </div>
  );
};

PictureCardBody.Kicker = PictureCardBodyKicker;
PictureCardBody.Title = PictureCardBodyTitle;
PictureCardBody.Description = PictureCardBodyDescription;

PictureCardBody.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

PictureCardBody.defaultProps = {
  className: "",
  children: null
};

export default PictureCardBody;

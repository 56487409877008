import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GaugeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 15A6.5 6.5 0 1 1 15 8.5 6.508 6.508 0 0 1 8.5 15zm0-12A5.5 5.5 0 1 0 14 8.5 5.507 5.507 0 0 0 8.5 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M4.794 5.5l.707-.707L9.208 8.5l-.707.708z"
        />
        <path
          d="M8.5 11A2.5 2.5 0 0 1 6 8.5V8h1v.5A1.5 1.5 0 1 0 8.5 7H8V6h.5a2.5 2.5 0 0 1 0 5zM8 2.5h1v2H8zM8 12.5h1v2H8zM12.5 8h2v1h-2zM2.5 8h2v1h-2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11.094 14.64l1.266-1.547 12.548 10.266-1.266 1.548z"
        />
        <path
          d="M23.5 30.5a7.008 7.008 0 0 1-7-7v-1h2v1a5 5 0 1 0 5-5h-1v-2h1a7 7 0 0 1 0 14zM23 7h2v4h-2zM23 37h2v4h-2zM37 23h4v2h-4zM7 23h4v2H7z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.5 24A11.5 11.5 0 1 1 24 12.5 11.513 11.513 0 0 1 12.5 24zm0-21a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 12.5 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M6.585 8L8 6.586l5.914 5.914-1.414 1.414z"
        />
        <path
          d="M12.5 18A5.507 5.507 0 0 1 7 12.5v-1h2v1A3.5 3.5 0 1 0 12.5 9h-1V7h1a5.5 5.5 0 0 1 0 11z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GaugeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GaugeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default GaugeIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretRightIcon } from "../../../Icons";

const PictureCardBodyTitle = props => {
  const { link, className, children, ...other } = props;
  return (
    <h2 className={clsx("em-c-picture-card__title", className)} {...other}>
      {children}
      {link && (
        <CaretRightIcon size="small" className="em-c-picture-card__icon" />
      )}
    </h2>
  );
};

PictureCardBodyTitle.propTypes = {
  /** Set to true if you would like to hide the right caretlink icon. */
  link: PropTypes.bool,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

PictureCardBodyTitle.defaultProps = {
  className: "",
  children: null
};

export default PictureCardBodyTitle;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CollapseIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 11h-5a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM6 10h4V6H6zM3.5 4H0V3h3V0h1v3.5a.5.5 0 0 1-.5.5zM16 4h-3.5a.5.5 0 0 1-.5-.5V0h1v3h3zM13 16h-1v-3.5a.5.5 0 0 1 .5-.5H16v1h-3zM4 16H3v-3H0v-1h3.5a.5.5 0 0 1 .5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M31 32H18a1 1 0 0 1-1-1V18a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1zm-12-2h11V19H19zM15 16H4v-2h10V4h2v11a1 1 0 0 1-1 1zM45 16H34a1 1 0 0 1-1-1V4h2v10h10zM35 45h-2V34a1 1 0 0 1 1-1h11v2H35zM16 45h-2V35H4v-2h11a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M15 16H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1zm-6-2h5V9H9zM6 7H0V5h5V0h2v6a1 1 0 0 1-1 1zM23 7h-6a1 1 0 0 1-1-1V0h2v5h5zM18 23h-2v-6a1 1 0 0 1 1-1h6v2h-5zM7 23H5v-5H0v-2h6a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CollapseIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CollapseIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CollapseIcon;

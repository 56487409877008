import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SitemapIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 5h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM7 4h3V1H7zM14.5 16h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM11 15h3v-3h-3zM6.5 16h-4a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5zM3 15h3v-3H3z"
          fill="currentColor"
        />
        <path
          d="M13 12h-1V9H5v3H4V8.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 4h1v5H8z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M29 17H19a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM19 5v10h10V5zM29 45H19a2 2 0 0 1-2-2V33a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM19 33v10h10V33zM13 45H3a2 2 0 0 1-2-2V33a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM3 33v10h10V33zM45 45H35a2 2 0 0 1-2-2V33a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM35 33v10h10V33zM41 30h-2v-5H9v5H7v-6a1 1 0 0 1 1-1h32a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 18h2v12h-2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14 8h-4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-3-2h2V4h-2zM14 21h-4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-3-2h2v-2h-2zM6 21H2a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-3-2h2v-2H3zM22 21h-4a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-3-2h2v-2h-2z"
          fill="currentColor"
        />
        <path
          d="M21 17h-2v-4H5v4H3v-5a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 7h2v9h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SitemapIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SitemapIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SitemapIcon;

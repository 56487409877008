import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Responsive video object */
const Video = props => {
  const { src, thumbnail, type, className, ...other } = props;

  return (
    <video
      className={clsx("em-c-video", className)}
      poster={thumbnail}
      controls
      {...other}
    >
      <source className="em-c-video__source" src={src} type={type} />
    </video>
  );
};

Video.propTypes = {
  /** the source (url) of the image to be displayed */
  src: PropTypes.string,
  /** alternate text for the image */
  thumbnail: PropTypes.string,
  /** Use a custom media type if needed. */
  type: PropTypes.string,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Video.defaultProps = {
  src: "",
  type: "video/mp4",
  thumbnail: "",
  className: ""
};

export default Video;

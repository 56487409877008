import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CirclePlusIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 5h1v7H8z" />
        <path fill="currentColor" d="M5 8h7v1H5z" />
        <path
          d="M8.5 16A7.5 7.5 0 0 1 3.2 3.2 7.5 7.5 0 1 1 8.5 16zm0-14a6.458 6.458 0 0 0-4.6 1.9A6.5 6.5 0 1 0 8.5 2zM3.55 3.55z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 13h2v22h-2z" />
        <path fill="currentColor" d="M13 23h22v2H13z" />
        <path
          d="M24 46.989A22.992 22.992 0 0 1 7.737 7.737 23 23 0 1 1 24 46.989zM24 3A20.862 20.862 0 0 0 9.151 9.151a21 21 0 0 0 29.7 29.7A21 21 0 0 0 24 3zM8.444 8.444z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 6h2v12h-2z" />
        <path fill="currentColor" d="M6 11h12v2H6z" />
        <path
          d="M12 23.994A11.994 11.994 0 0 1 3.515 3.515 12 12 0 1 1 12 23.994zM4.929 4.929a10 10 0 1 0 14.143 0 10.013 10.013 0 0 0-14.143 0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CirclePlusIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CirclePlusIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CirclePlusIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FolderOpenIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M2 10H1V2.5A1.5 1.5 0 0 1 2.5 1h3.383a1.506 1.506 0 0 1 .691.168l1.495.775A.507.507 0 0 0 8.3 2h4.2A1.5 1.5 0 0 1 14 3.5V5h-1V3.5a.5.5 0 0 0-.5-.5H8.3a1.509 1.509 0 0 1-.69-.168l-1.5-.776A.507.507 0 0 0 5.883 2H2.5a.5.5 0 0 0-.5.5z"
          fill="currentColor"
        />
        <path
          d="M12.317 14H2.356a1.148 1.148 0 0 1-1.088-1.514l2.147-5.727A1.176 1.176 0 0 1 4.511 6h9.9a1.171 1.171 0 0 1 1.1 1.579L13.4 13.233a1.14 1.14 0 0 1-1.083.767zM4.511 7a.171.171 0 0 0-.159.11L2.21 12.821a.139.139 0 0 0 .026.117.143.143 0 0 0 .12.062h9.961a.146.146 0 0 0 .139-.1l2.113-5.671a.164.164 0 0 0-.02-.156A.167.167 0 0 0 14.41 7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M4 31H2V5.8A2.874 2.874 0 0 1 4.938 3h11.934a5.071 5.071 0 0 1 2.725.785.954.954 0 0 1 .114.084L23.37 7H37c.974 0 3 0 3 2v5h-2V9.051A6.181 6.181 0 0 0 37 9H23a1 1 0 0 1-.65-.24l-3.881-3.322A3.079 3.079 0 0 0 16.872 5H4.938A.877.877 0 0 0 4 5.8z"
          fill="currentColor"
        />
        <path
          d="M8 21.5H6v-8c0-1.02.779-2.5 2-2.5h27v2H8.185a1.162 1.162 0 0 0-.185.5z"
          fill="currentColor"
        />
        <path
          d="M34.693 43H5a3 3 0 0 1-2.8-4.053l7.479-20A3.008 3.008 0 0 1 12.475 17H43a3 3 0 0 1 2.765 4.153l-8.31 20A2.987 2.987 0 0 1 34.693 43zM12.475 19a1 1 0 0 0-.931.648l-7.479 20A1 1 0 0 0 5 41h29.7a.993.993 0 0 0 .918-.614l8.31-20a.991.991 0 0 0-.092-.943A.978.978 0 0 0 43 19z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M3 16H1V3.969A1.971 1.971 0 0 1 2.969 2h5.967a2.931 2.931 0 0 1 1.659.513l1.932 1.323a.933.933 0 0 0 .53.164h5.975A1.971 1.971 0 0 1 21 5.969V8h-2V5.969L13.057 6a2.925 2.925 0 0 1-1.657-.514L9.466 4.164A.934.934 0 0 0 8.936 4H2.969z"
          fill="currentColor"
        />
        <path
          d="M18.634 22H3.981a2.017 2.017 0 0 1-1.912-2.662l3.383-9.025A2.025 2.025 0 0 1 7.342 9h14.633a2.017 2.017 0 0 1 1.892 2.718l-3.331 8.937A2 2 0 0 1 18.634 22zM7.342 11l-3.389 9.009L18.634 20a.017.017 0 0 0 .016-.012l3.341-8.967z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FolderOpenIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FolderOpenIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FolderOpenIcon;

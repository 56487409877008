import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** GlobalNavItems are the links that go in a GlobalNav. */
const HorizontalHeaderGlobalNavItem = props => {
  const { className, children, ...other } = props;

  return (
    <li
      className={clsx("em-c-global-nav__item", "em-u-clickable", className)}
      {...other}
    >
      <div className="em-c-global-nav__link">{children}</div>
    </li>
  );
};

HorizontalHeaderGlobalNavItem.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

HorizontalHeaderGlobalNavItem.defaultProps = {
  className: "",
  children: null
};

export default HorizontalHeaderGlobalNavItem;

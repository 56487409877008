import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileGenericIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h6.165a1.493 1.493 0 0 1 1.03.411l2.835 2.68a1.5 1.5 0 0 1 .47 1.09V13.5a1.5 1.5 0 0 1-1.5 1.5zm-9-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5.181a.5.5 0 0 0-.156-.363l-2.836-2.681A.5.5 0 0 0 9.665 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 45H10a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h20.343a4.968 4.968 0 0 1 3.536 1.464l7.656 7.657A4.968 4.968 0 0 1 43 15.657V42a3 3 0 0 1-3 3zM10 5a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V15.657a3.02 3.02 0 0 0-.879-2.121l-7.656-7.658A2.982 2.982 0 0 0 30.343 5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19.875 24H4.125A2.115 2.115 0 0 1 2 21.9V2.1A2.115 2.115 0 0 1 4.125 0h10.318a3.255 3.255 0 0 1 2.29.93l4.307 4.211a3.2 3.2 0 0 1 .96 2.27V21.9a2.115 2.115 0 0 1-2.125 2.1zM4.125 2A.115.115 0 0 0 4 2.1v19.8a.115.115 0 0 0 .125.1h15.75a.115.115 0 0 0 .125-.1V7.411a1.169 1.169 0 0 0-.358-.841l-4.307-4.211A1.283 1.283 0 0 0 14.443 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileGenericIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileGenericIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileGenericIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CommentTypingIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M2.5 15.214a.5.5 0 0 1-.5-.5V3.5A1.5 1.5 0 0 1 3.5 2h10A1.5 1.5 0 0 1 15 3.5v7a1.5 1.5 0 0 1-1.5 1.5H5.955l-3.1 3.07a.5.5 0 0 1-.355.144zM3.5 3a.5.5 0 0 0-.5.5v10.017l2.4-2.372A.5.5 0 0 1 5.75 11h7.75a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <circle cx={5.265} cy={7} r={1.25} fill="currentColor" />
        <circle cx={8.507} cy={7} r={1.25} fill="currentColor" />
        <circle cx={11.75} cy={7} r={1.25} fill="currentColor" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M13 17a2 2 0 1 1-2 2 2 2 0 0 1 2-2m0-2a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM24 17a2 2 0 1 1-2 2 2 2 0 0 1 2-2m0-2a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM34 17a2 2 0 1 1-2 2 2 2 0 0 1 2-2m0-2a4 4 0 1 0 4 4 4 4 0 0 0-4-4z"
          fill="currentColor"
        />
        <path
          d="M4 44a1 1 0 0 1-1-1V7a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v24a3 3 0 0 1-3 3H14.414l-9.707 9.707A1 1 0 0 1 4 44zM6 6a1 1 0 0 0-1 1v33.586l8.293-8.293A1 1 0 0 1 14 32h28a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17.154 8A1.846 1.846 0 1 0 19 9.846 1.846 1.846 0 0 0 17.154 8zM12 8a1.846 1.846 0 1 0 1.846 1.846A1.846 1.846 0 0 0 12 8zM6.846 8a1.846 1.846 0 1 0 1.846 1.846A1.846 1.846 0 0 0 6.846 8z"
          fill="currentColor"
        />
        <path
          d="M2 23a1 1 0 0 1-1-1V4a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H7.414l-4.707 4.707A1 1 0 0 1 2 23zM21 4H3v15.586l3.293-3.293A1 1 0 0 1 7 16h14z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CommentTypingIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CommentTypingIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CommentTypingIcon;

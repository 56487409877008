import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const UploadIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 14H11v-1h3V3H3v10h3v1H2.5a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 7h1v9H8z" />
        <path
          d="M11.639 9.193L8.5 7.1 5.361 9.193l-.555-.832 3.417-2.277a.5.5 0 0 1 .555 0l3.416 2.277z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 40h-9v-2h9a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H6a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h9v2H6a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v28a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 23h2v23h-2z" />
        <path
          d="M31.02 26.414l-6.3-6.128a1.038 1.038 0 0 0-1.435 0l-6.3 6.127-1.399-1.433 6.3-6.128a3.053 3.053 0 0 1 4.222 0l6.3 6.129z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.95 21H15v-2h5.95L21 3.05 3.05 3 3 18.95 9 19v2H3.05A2.053 2.053 0 0 1 1 18.95V3.05A2.053 2.053 0 0 1 3.05 1h17.9A2.053 2.053 0 0 1 23 3.05v15.9A2.053 2.053 0 0 1 20.95 21z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 11h2v10h-2z" />
        <path
          d="M16 13.123l-4-4-4 4-1.414-1.414 4.136-4.135a1.719 1.719 0 0 1 2.507-.047l4.186 4.182z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

UploadIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

UploadIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default UploadIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ShipIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13 6h-1V3H3v3H2V2.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5zM10 2H9V1H6v1H5V.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5zM7 6h1v7H7z"
          fill="currentColor"
        />
        <path
          d="M12.724 13.171l-.895-.447 1.986-3.974L7.5 6.044 1.185 8.75l1.986 3.974-.895.447L.053 8.723A.5.5 0 0 1 .3 8.04l7-3a.5.5 0 0 1 .395 0l7 3a.5.5 0 0 1 .25.683zM13.5 16a.5.5 0 0 1-.354-.146l-.646-.646-.646.646a.5.5 0 0 1-.707 0l-.646-.646-.646.646a.5.5 0 0 1-.707 0l-.648-.647-.646.646a.5.5 0 0 1-.707 0l-.647-.646-.646.646a.5.5 0 0 1-.707 0l-.647-.646-.646.646a.5.5 0 0 1-.707 0l-.647-.646-.646.646a.5.5 0 0 1-.707 0l-1-1 .707-.707.646.646.646-.646a.5.5 0 0 1 .707 0l.646.646.646-.646a.5.5 0 0 1 .707 0l.646.646.646-.646a.5.5 0 0 1 .707 0l.646.646.646-.646a.5.5 0 0 1 .707 0l.646.646.646-.646a.5.5 0 0 1 .707 0l.646.646.646-.646a.5.5 0 0 1 .707 0l.646.646.646-.646.707.707-1 1A.5.5 0 0 1 13.5 16z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M7.553 42.342L1.105 29.447a1 1 0 0 1 .481-1.357l22-10a.994.994 0 0 1 .827 0l22 10a1 1 0 0 1 .483 1.353l-6.3 12.75-1.793-.886 5.839-11.824L24 20.1 3.358 29.481l5.984 11.966z"
          fill="currentColor"
        />
        <path
          d="M39 23h-2V11a1 1 0 0 0-1-1H12a1 1 0 0 0-1 1v12H9V11a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3zM29 7h-2V3h-6v4h-2V3a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 19h2v23h-2z" />
        <path
          d="M31.5 47.378a2.98 2.98 0 0 1-2.121-.878l-1.172-1.172a1.025 1.025 0 0 0-1.414 0L25.621 46.5a3 3 0 0 1-4.242 0l-1.172-1.172a1.025 1.025 0 0 0-1.414 0L17.621 46.5a3.073 3.073 0 0 1-4.242 0l-1.172-1.172a1 1 0 0 0-1.414 0L9.621 46.5a3 3 0 0 1-4.242 0l-3.293-3.293L3.5 41.793l3.293 3.293a1 1 0 0 0 1.414 0l1.172-1.172a3 3 0 0 1 4.242 0l1.172 1.172a1.025 1.025 0 0 0 1.414 0l1.172-1.172a3 3 0 0 1 4.242 0l1.172 1.172a1 1 0 0 0 1.414 0l1.172-1.172a3 3 0 0 1 4.242 0l1.172 1.172a1.025 1.025 0 0 0 1.414 0l1.172-1.172a3 3 0 0 1 4.242 0l1.172 1.172a1 1 0 0 0 1.414 0l3.293-3.293 1.414 1.414-3.293 3.293a3 3 0 0 1-4.242 0l-1.172-1.172a1 1 0 0 0-1.414 0L33.621 46.5a2.98 2.98 0 0 1-2.121.878zM32.5 17h-17a2.5 2.5 0 0 1 0-5h17a2.5 2.5 0 0 1 0 5zm-17-3a.5.5 0 0 0 0 1h17a.5.5 0 0 0 0-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21.632 22.265l-1.9-.633 2.037-6.111-10.251-4.9-9.3 4.873 2.047 6.141-1.9.633-2.314-6.952a1 1 0 0 1 .485-1.2l10.5-5.5a1 1 0 0 1 .9-.017l11.5 5.5a1 1 0 0 1 .517 1.219z"
          fill="currentColor"
        />
        <path
          d="M20 12h-2V6H6v6H4V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zM15 4h-2V2h-2v2H9V1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 10h2v12h-2z" />
        <path
          d="M16.4 23.786a1.986 1.986 0 0 1-1.414-.586l-.786-.786-.786.786a2 2 0 0 1-2.828 0l-.786-.786-.786.786a2.047 2.047 0 0 1-2.829 0l-.785-.785-.786.785a2 2 0 0 1-2.829 0L-.414 21 1 19.586l2.2 2.2.786-.786a2.047 2.047 0 0 1 2.829 0l.786.785.785-.785a2 2 0 0 1 2.829 0l.786.786.785-.786a2 2 0 0 1 2.829 0l.786.786.785-.786a2.048 2.048 0 0 1 2.828 0l.786.786 2.2-2.2L24.414 21l-2.2 2.2a2 2 0 0 1-2.829 0l-.786-.786-.786.786a1.985 1.985 0 0 1-1.413.586z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ShipIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ShipIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ShipIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CloudIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 14H3.507a3.545 3.545 0 0 1-3.52-2.73 3.474 3.474 0 0 1 2.664-4.134 5.78 5.78 0 0 1 11.394.233 3.452 3.452 0 0 1 1.974 3.876A3.537 3.537 0 0 1 12.5 14zM8.33 3.44a4.777 4.777 0 0 0-4.744 4.193.5.5 0 0 1-.449.438 2.473 2.473 0 0 0-2.171 3A2.542 2.542 0 0 0 3.507 13H12.5a2.537 2.537 0 0 0 2.543-1.95 2.46 2.46 0 0 0-1.62-2.85.5.5 0 0 1-.337-.428A4.746 4.746 0 0 0 8.33 3.44z"
          fill="currentColor"
        />
        <path
          d="M5.86 8.17h-1a3.8 3.8 0 0 1 3.8-3.8v1a2.8 2.8 0 0 0-2.8 2.8z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M14.3 22.667l-1.967-.367.184-.983A12.127 12.127 0 0 1 24.5 11.5h1v2h-1a10.126 10.126 0 0 0-10.017 8.184z"
          fill="currentColor"
        />
        <path
          d="M37.219 41h-26.4A10.264 10.264 0 0 1 .555 32.064 10.016 10.016 0 0 1 7.7 21.4a17 17 0 0 1 33.66.382 10 10 0 0 1 6.024 10.781A10.207 10.207 0 0 1 37.219 41zM24.5 9A14.971 14.971 0 0 0 9.594 22.311a1 1 0 0 1-.782.867 8.016 8.016 0 0 0-6.268 8.68A8.246 8.246 0 0 0 10.818 39h26.4a8.194 8.194 0 0 0 8.182-6.74 8 8 0 0 0-5.295-8.813 1 1 0 0 1-.674-.855A14.914 14.914 0 0 0 24.5 9z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.815 20H6.275c-2.315 0-5.482-.392-6.25-3.779a6.1 6.1 0 0 1 1.05-4.908 5.6 5.6 0 0 1 3.048-2.167A8.472 8.472 0 0 1 12.5 2c4.582 0 7.782 4.11 8.419 8.285A4.831 4.831 0 0 1 24 15a1.007 1.007 0 0 1-.013.158A5.388 5.388 0 0 1 18.815 20zm-16.84-4.221c.262 1.154.963 2.221 4.3 2.221h12.54A3.4 3.4 0 0 0 22 14.92a2.93 2.93 0 0 0-2.32-2.973 1 1 0 0 1-.68-.857C18.687 7.6 16.167 4 12.5 4a6.588 6.588 0 0 0-6.507 6.12A1 1 0 0 1 5.1 11a3.446 3.446 0 0 0-2.4 1.489 4.156 4.156 0 0 0-.721 3.295z"
          fill="currentColor"
        />
        <path
          d="M9 11H7a6.007 6.007 0 0 1 6-6v2a4 4 0 0 0-4 4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CloudIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CloudIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CloudIcon;

const darkTheme = {
  palette: {
    main: {
      extraLight: "#47CDFF",
      light: "#14BFFF",
      base: "#00A3E0",
      dark: "#007EAD",
      extraDark: "#00597A",
      text: "#fff"
    },
    accent: {
      extraLight: "#d89096",
      light: "#ca6b73",
      base: "#bd454f",
      dark: "#99363e",
      extraDark: "#73292f",
      text: "#fff"
    },
    action: {
      light: "#9dc2df",
      base: "#6da3ce",
      dark: "#3c85be",
      text: "#111122"
    },
    caution: {
      light: "#fffbab",
      base: "#ffdd8d",
      dark: "#e5b961",
      text: "#000"
    },
    positive: {
      light: "#97deb9",
      base: "#5ccc91",
      dark: "#268f58",
      text: "#000"
    },
    negative: {
      light: "#d78f95",
      base: "#ca6a72",
      dark: "#bd454f",
      text: "#fff"
    },
    disabled: {
      light: "gray",
      base: "#808080",
      text: "#5f5f5f"
    },
    active: {
      light: "rgb(0, 163, 224, 0.4)",
      base: "rgb(0, 163, 224, 0.4)",
      text: "#DDDDDD"
    },
    surface: {
      "00": "#111122",
      "01": "#1b1b2c",
      "02": "#252536",
      "03": "#303041",
      "04": "#3a3a4b",
      "05": "#444455",
      "06": "#4e4e5f",
      "07": "#585869",
      "08": "#636374",
      "09": "#6d6d7e",
      "10": "#777788",
      "11": "#818192",
      inverted: {
        light: "#202036",
        base: "#313246",
        dark: "#424254",
        text: "#fff"
      }
    },
    text: {
      light: "#999999",
      base: "#DDDDDD"
    },
    alert: {
      default: {
        background: "#1E1E2F",
        base: "#72CFFF",
        text: "#72CFFF"
      },
      success: {
        background: "#1E1E2F",
        base: "#70ff8a",
        text: "#70ff8a"
      },
      warning: {
        background: "#1E1E2F",
        base: "#ffd470",
        text: "#ffd470"
      },
      error: {
        background: "#1E1E2F",
        base: "#FF9071",
        text: "#FF9071"
      }
    }
  },
  colors: {
    amber: "#f2ac33",
    black: "#000",
    blue: {
      extraLight: "#47CDFF",
      light: "#14BFFF",
      base: "#00A3E0",
      dark: "#007EAD",
      extraDark: "#00597A",
      text: "#fff"
    },
    burgundy: "#bd454f",
    cerise: "#b94084",
    curiousBlue: "#3190d9",
    cyan: "#00a3e0",
    deepBlue: "#111122",
    green: "#33bf75",
    indigo: "#002f6c",
    lightBurgundy: "#e05050",
    lime: "#b4d404",
    mediumGray: "#bbbfc0",
    orange: "#f1a233",
    plum: "#a13d79",
    purple: "#7a4183",
    ruby: "#c13366",
    seaBlue: "#0b65ae",
    turquoise: "#33bdb9",
    vermilion: "#e16133",
    violet: "#616195",
    deepViolet: "#2E2E62",
    white: "#ffffff",
    yellow: "#ffdf33"
  }
};

export default darkTheme;

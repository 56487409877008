import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ModalWindow from "./ModalWindow";

/** A modal is a popup window that spans the entire viewport window and contains various information */
const Modal = props => {
  const { show, onHide, className, children, ...other } = props;
  const modalRef = useRef(null);

  useEffect(() => {
    // When the component mounts, add the Unity JS and Mouse Down Event
    if (!document.body.classList.contains("em-js")) {
      document.body.classList.add("em-js");
    }
    document.addEventListener("mousedown", handleClickOutside);

    // When the component unmounts, remove the Unity JS and Mouse Down Event
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (modalRef.current === event.target) {
      onHide();
    }
  }

  return (
    <div
      ref={modalRef}
      className={clsx("em-c-modal", { "em-is-closed": !show }, className)}
      tabIndex={show ? 0 : -1}
      aria-label={!!show}
      {...other}
    >
      {children}
    </div>
  );
};

Modal.Window = ModalWindow;

Modal.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node,
  /** Boolean to control state of modal. True = open; False = closed.  */
  show: PropTypes.bool,
  /** Function called when user clicks outside of modal space. Usually used to toggle modals state to closed.  */
  onHide: PropTypes.func
};

Modal.defaultProps = {
  children: null,
  className: "",
  show: false,
  onHide: null
};

export default Modal;

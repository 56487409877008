import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilePptxIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.789 7.142a1.944 1.944 0 0 1-.119.668 1.5 1.5 0 0 1-.342.548 2.09 2.09 0 0 1-.68.455 2.449 2.449 0 0 1-.931.154H1.9v1.658H.588V5.536h2.16a3.409 3.409 0 0 1 .818.083 1.918 1.918 0 0 1 .59.251 1.355 1.355 0 0 1 .471.516 1.638 1.638 0 0 1 .162.756zm-1.357.029a.6.6 0 0 0-.115-.37.631.631 0 0 0-.271-.216 1.226 1.226 0 0 0-.4-.091c-.131-.005-.3-.008-.52-.008H1.9v1.521h.377a3.048 3.048 0 0 0 .551-.041.785.785 0 0 0 .365-.166.626.626 0 0 0 .182-.261 1.033 1.033 0 0 0 .057-.368zM10.34 7.142a1.945 1.945 0 0 1-.12.668 1.486 1.486 0 0 1-.342.548 2.076 2.076 0 0 1-.679.455 2.458 2.458 0 0 1-.932.154h-.816v1.658H6.139V5.536H8.3a3.415 3.415 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.352 1.352 0 0 1 .47.516 1.626 1.626 0 0 1 .162.756zm-1.357.029a.6.6 0 0 0-.116-.37.62.62 0 0 0-.267-.216 1.221 1.221 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008h-.229v1.521h.376a3.053 3.053 0 0 0 .552-.041.786.786 0 0 0 .364-.166.62.62 0 0 0 .183-.261 1.056 1.056 0 0 0 .056-.368zM15.671 6.52h-1.589v4.1H12.77v-4.1h-1.59v-.984h4.491zM3 4H2V2.5A1.5 1.5 0 0 1 3.5 1h6.506a1.491 1.491 0 0 1 1.061.439l1.7 1.7-.707.707-1.7-1.7A.5.5 0 0 0 10.006 2H3.5a.5.5 0 0 0-.5.5zM12.5 15h-9A1.5 1.5 0 0 1 2 13.5V12h1v1.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V12h1v1.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M44 35H6a3 3 0 0 1-3-3V19a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3v13a3 3 0 0 1-3 3zM6 18a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V19a1 1 0 0 0-1-1zM40 14.414l-7.527-7.527A3 3 0 0 0 30.34 6H10a1 1 0 0 0-1 1v7H7V7a3 3 0 0 1 3-3h20.34a4.983 4.983 0 0 1 3.547 1.473L41.414 13zM40 46H10a3 3 0 0 1-3-3v-6h2v6a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-6h2v6a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M10 22.287a.747.747 0 0 1 .728-.787h2.512a2.849 2.849 0 0 1 3.123 2.967v.026a2.982 2.982 0 0 1-3.288 3.03h-1.63v2.142a.724.724 0 1 1-1.445 0zm3.123 3.829A1.592 1.592 0 0 0 14.9 24.53v-.03c0-1.039-.692-1.584-1.773-1.584h-1.682v3.195zM17.848 22.287a.747.747 0 0 1 .728-.787h2.513a2.849 2.849 0 0 1 3.123 2.967v.026a2.982 2.982 0 0 1-3.288 3.03h-1.632v2.142a.724.724 0 1 1-1.444 0zm3.122 3.829a1.592 1.592 0 0 0 1.773-1.586v-.03c0-1.039-.692-1.584-1.773-1.584h-1.678v3.195zM27.715 22.945h-2a.725.725 0 0 1 0-1.445h5.459a.726.726 0 0 1 0 1.445H29.17v6.719a.729.729 0 1 1-1.455 0zM33.1 29.194l2.443-3.27-2.3-3.08a1.049 1.049 0 0 1-.222-.583.722.722 0 0 1 .715-.761.73.73 0 0 1 .658.38l2.078 2.954 2.055-2.9a.8.8 0 0 1 .7-.43.689.689 0 0 1 .658.709.955.955 0 0 1-.224.6l-2.3 3.093 2.419 3.259a1.006 1.006 0 0 1 .22.575.732.732 0 0 1-1.373.38l-2.207-3.106-2.173 3.056a.815.815 0 0 1-.7.43.686.686 0 0 1-.658-.723 1.015 1.015 0 0 1 .211-.583z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M8.789 11.395a1.944 1.944 0 0 1-.119.668 1.5 1.5 0 0 1-.342.548 2.09 2.09 0 0 1-.68.455 2.449 2.449 0 0 1-.931.154H5.9v1.658H4.588v-5.09h2.16a3.409 3.409 0 0 1 .818.083 1.918 1.918 0 0 1 .59.251 1.355 1.355 0 0 1 .471.516 1.638 1.638 0 0 1 .162.757zm-1.357.029a.6.6 0 0 0-.115-.37.631.631 0 0 0-.271-.216 1.226 1.226 0 0 0-.4-.091c-.131-.005-.3-.008-.52-.008H5.9v1.521h.377a3.048 3.048 0 0 0 .551-.041.785.785 0 0 0 .365-.164.626.626 0 0 0 .182-.261 1.033 1.033 0 0 0 .057-.37zM14.34 11.395a1.945 1.945 0 0 1-.12.668 1.486 1.486 0 0 1-.342.548 2.076 2.076 0 0 1-.679.455 2.458 2.458 0 0 1-.932.154h-.816v1.658h-1.312v-5.09H12.3a3.415 3.415 0 0 1 .818.083 1.928 1.928 0 0 1 .59.251 1.352 1.352 0 0 1 .47.516 1.626 1.626 0 0 1 .162.757zm-1.357.029a.6.6 0 0 0-.116-.37.62.62 0 0 0-.271-.216 1.221 1.221 0 0 0-.4-.091c-.13-.005-.3-.008-.52-.008h-.226v1.521h.376a3.053 3.053 0 0 0 .552-.041.786.786 0 0 0 .364-.164.62.62 0 0 0 .183-.261 1.056 1.056 0 0 0 .057-.37zM19.671 10.772h-1.589v4.1H16.77v-4.1h-1.59v-.984h4.491z"
          fill="currentColor"
        />
        <path
          d="M23 19H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1zM2 17h20V8H2zM19.151 5.406L16.654 3.4a.879.879 0 0 1-.081-.073C16.265 3.013 16.175 3 16 3H5v2H3V3a1.979 1.979 0 0 1 2-2h11a2.609 2.609 0 0 1 1.949.87l2.457 1.978zM17.857 24H5.143A2.077 2.077 0 0 1 3 22v-2h2v2c0-.042.07 0 .143 0h12.714a.234.234 0 0 0 .159-.063L18 20h2v2a2.077 2.077 0 0 1-2.143 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilePptxIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilePptxIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilePptxIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const QuoteLeftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <rect
          x={1.5}
          y={8.5}
          width={5}
          height={5}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path
          d="M5.5 14h-3A1.5 1.5 0 0 1 1 12.5v-3A1.5 1.5 0 0 1 2.5 8h3A1.5 1.5 0 0 1 7 9.5v3A1.5 1.5 0 0 1 5.5 14zm-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <rect
          x={8.5}
          y={8.5}
          width={5}
          height={5}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path
          d="M12.5 14h-3A1.5 1.5 0 0 1 8 12.5v-3A1.5 1.5 0 0 1 9.5 8h3A1.5 1.5 0 0 1 14 9.5v3a1.5 1.5 0 0 1-1.5 1.5zm-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM2 10H1v-.5c0-3.078 2.225-6.875 5.418-7.4l.493-.082.164.982-.493.081C3.959 3.517 2 6.829 2 9.5z"
          fill="currentColor"
        />
        <path
          d="M9 10H8v-.5c0-3.078 2.225-6.875 5.418-7.4l.493-.082.164.982-.493.081C10.959 3.517 9 6.829 9 9.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <rect
          x={5}
          y={24}
          width={14}
          height={14}
          rx={2.667}
          ry={2.667}
          fill="currentColor"
        />
        <path
          d="M16.333 39H7.667A3.671 3.671 0 0 1 4 35.333v-8.666A3.671 3.671 0 0 1 7.667 23h8.667A3.671 3.671 0 0 1 20 26.667v8.666A3.671 3.671 0 0 1 16.333 39zM7.667 25A1.669 1.669 0 0 0 6 26.667v8.666A1.669 1.669 0 0 0 7.667 37h8.667A1.669 1.669 0 0 0 18 35.333v-8.666A1.669 1.669 0 0 0 16.333 25z"
          fill="currentColor"
        />
        <rect
          x={27}
          y={24}
          width={14}
          height={14}
          rx={2.667}
          ry={2.667}
          fill="currentColor"
        />
        <path
          d="M38.333 39h-8.666A3.671 3.671 0 0 1 26 35.333v-8.666A3.671 3.671 0 0 1 29.667 23h8.667A3.671 3.671 0 0 1 42 26.667v8.666A3.671 3.671 0 0 1 38.333 39zm-8.666-14A1.669 1.669 0 0 0 28 26.667v8.666A1.669 1.669 0 0 0 29.667 37h8.667A1.669 1.669 0 0 0 40 35.333v-8.666A1.669 1.669 0 0 0 38.333 25z"
          fill="currentColor"
        />
        <path
          d="M28 27h-2v-1c0-7.868 6.071-14.848 14.763-16.972l.971-.237.475 1.943-.972.237C33.443 12.876 28 19.056 28 26zM6 27H4v-1c0-7.868 6.071-14.848 14.763-16.972l.971-.237.475 1.943-.972.237C11.443 12.876 6 19.056 6 26z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <rect
          x={3}
          y={12}
          width={7}
          height={7}
          rx={1.333}
          ry={1.333}
          fill="currentColor"
        />
        <path
          d="M8.667 20H4.333A2.336 2.336 0 0 1 2 17.667v-4.334A2.336 2.336 0 0 1 4.333 11h4.334A2.336 2.336 0 0 1 11 13.333v4.334A2.336 2.336 0 0 1 8.667 20zm-4.334-7a.334.334 0 0 0-.333.333v4.334a.334.334 0 0 0 .333.333h4.334A.334.334 0 0 0 9 17.667v-4.334A.334.334 0 0 0 8.667 13z"
          fill="currentColor"
        />
        <rect
          x={14}
          y={12}
          width={7}
          height={7}
          rx={1.333}
          ry={1.333}
          fill="currentColor"
        />
        <path
          d="M19.667 20h-4.334A2.336 2.336 0 0 1 13 17.667v-4.334A2.336 2.336 0 0 1 15.333 11h4.333A2.336 2.336 0 0 1 22 13.333v4.334A2.336 2.336 0 0 1 19.667 20zm-4.333-7a.334.334 0 0 0-.333.333v4.334a.334.334 0 0 0 .333.333h4.333a.334.334 0 0 0 .333-.333v-4.334a.334.334 0 0 0-.333-.333z"
          fill="currentColor"
        />
        <path
          d="M15 13h-2v-1a8.862 8.862 0 0 1 6.731-8.963l.963-.27.539 1.926-.963.27A6.872 6.872 0 0 0 15 12zM4 13H2v-1a8.862 8.862 0 0 1 6.731-8.963l.963-.27.539 1.926-.963.27A6.872 6.872 0 0 0 4 12z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

QuoteLeftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

QuoteLeftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default QuoteLeftIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RepeatIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 13H1v-1h12.5a.5.5 0 0 0 .5-.5V9h1v2.5a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M2.5 15.207L.146 12.854a.5.5 0 0 1 0-.707L2.5 9.793l.707.707-2 2 2 2zM2 8H1V5.5A1.5 1.5 0 0 1 2.5 4H15v1H2.5a.5.5 0 0 0-.5.5z"
          fill="currentColor"
        />
        <path
          d="M13.5 7.207l-.707-.707 2-2-2-2 .707-.707 2.354 2.354a.5.5 0 0 1 0 .707z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 37H7v-2h33a1 1 0 0 0 1-1v-9h2v9a3 3 0 0 1-3 3zM9 23H7v-9a3 3 0 0 1 3-3h32v2H10a1 1 0 0 0-1 1z"
          fill="currentColor"
        />
        <path
          d="M39.03 20.914l-1.444-1.384 6.131-6.4a1.059 1.059 0 0 0 .043-1.4l-6.169-7.354 1.533-1.285 6.169 7.358a3.08 3.08 0 0 1-.133 4.065zM8.876 44.909l-6.169-7.358a3.08 3.08 0 0 1 .133-4.065l6.13-6.4 1.444 1.384-6.131 6.4a1.059 1.059 0 0 0-.043 1.4l6.169 7.358z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path d="M22 19H5v-2h17v-4h2v4a2 2 0 0 1-2 2z" fill="currentColor" />
        <path
          d="M6 24.414L1.656 20.07A2.924 2.924 0 0 1 .682 18a2.924 2.924 0 0 1 .975-2.07L6 11.586 7.414 13 3.07 17.344c-.521.521-.521.791 0 1.313L7.414 23zM18 12.414L16.586 11l4.344-4.344c.521-.521.521-.791 0-1.312L16.586 1 18-.414l4.344 4.344A2.924 2.924 0 0 1 23.318 6a2.924 2.924 0 0 1-.975 2.07z"
          fill="currentColor"
        />
        <path d="M3 11H1V7a2 2 0 0 1 2-2h15.682v2H3z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RepeatIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RepeatIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RepeatIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronLeftFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M9.6 13.614L3.986 8 9.6 2.386 11.014 3.8 6.814 8l4.2 4.2L9.6 13.614z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M31 40.121L14.879 24 31 7.879 33.121 10l-14 14 14 14L31 40.121z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M14 21.121L4.879 12 14 2.879 16.121 5l-7 7 7 7L14 21.121z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronLeftFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronLeftFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronLeftFilledIcon;

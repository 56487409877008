import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const User2Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14 16h-1v-1a5 5 0 0 0-10 0v1H2v-1a6 6 0 0 1 12 0zM9.5 8h-3A1.5 1.5 0 0 1 5 6.5V4a3 3 0 0 1 6 0v2.5A1.5 1.5 0 0 1 9.5 8zM8 2a2 2 0 0 0-2 2v2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5V4a2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M28 22h-6a7.008 7.008 0 0 1-7-7v-3a10 10 0 0 1 20 0v3a7.008 7.008 0 0 1-7 7zM25 4a8.009 8.009 0 0 0-8 8v3a5.006 5.006 0 0 0 5 5h6a5.006 5.006 0 0 0 5-5v-3a8.009 8.009 0 0 0-8-8zM43 45h-2v-3.012a16 16 0 0 0-32 0V45H7v-3.012a18 18 0 0 1 36 0z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 11h-3a4 4 0 0 1-4-4V5.5a5.5 5.5 0 0 1 11 0V7a4 4 0 0 1-4 4zm-1.5-9A3.5 3.5 0 0 0 8 5.5V7a2 2 0 0 0 2 2h3a2 2 0 0 0 2-2V5.5A3.5 3.5 0 0 0 11.5 2z"
          fill="currentColor"
        />
        <path
          d="M21 23h-2v-2a7.5 7.5 0 0 0-15 0v2H2v-2a9.5 9.5 0 0 1 19 0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

User2Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

User2Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default User2Icon;

import React from 'react';
import { Toggle } from 'react-unity';

export function toSelectOptions(obj, valueKey, displayKey) {
    let options = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = valueKey ? obj[key][valueKey] : key;
            const display = displayKey ? obj[key][displayKey] : obj[key];
            options.push(
                <option value={value} key={value}>
                    {display}
                </option>
            );
        }
    }

    return options;
}

export function toToggleItem(obj, idPrefix, isChecked, onChangeFunc) {
    let items = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = key;
            const display = obj[key];
            items.push(
                <Toggle.Item
                    id={`${idPrefix}${value}`}
                    key={`${idPrefix}${value}`}
                    checked={isChecked(value)}
                    value={value}
                    onChange={(e) => onChangeFunc(e.target.value)}
                >
                    {display}
                </Toggle.Item>
            );
        }
    }
    return items;
}

export const parseAccessJSON = (itemsString) => {
    let items = itemsString === '*' ? [] : JSON.parse(itemsString);
    return items.map((item) => {
        return { id: item, label: item };
    });
};

export const getHostname = (url) => {
    const matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    return matches && matches[1];
};

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const InfoIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.5 15.083A7.583 7.583 0 0 1 2.138 2.138a7.583 7.583 0 1 1 10.724 10.724A7.534 7.534 0 0 1 7.5 15.083zm0-14a6.416 6.416 0 0 0-4.537 10.954 6.415 6.415 0 0 0 9.074 0A6.417 6.417 0 0 0 7.5 1.083z"
          fill="currentColor"
        />
        <path
          d="M9 11H7.5a.5.5 0 0 1-.5-.5V7H6V6h1.5a.5.5 0 0 1 .5.5V10h1z"
          fill="currentColor"
        />
        <circle cx={7.5} cy={4.5} r={0.5} fill="#112" />
        <path d="M7.5 4a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z" fill="currentColor" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M25 43.991a18.991 18.991 0 1 1 13.435-5.556A18.941 18.941 0 0 1 25 43.991zm0-35.983a16.992 16.992 0 1 0 12.021 4.971A16.948 16.948 0 0 0 25 8.008zm12.728 29.72z"
          fill="currentColor"
        />
        <path
          d="M28 34h-3a1 1 0 0 1-1-1v-9h-2v-2h3a1 1 0 0 1 1 1v9h2zM24.5 17a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zm0 2a.5.5 0 1 1 .5-.5.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 22A10 10 0 0 1 4.929 4.929a10 10 0 1 1 14.142 14.142A9.933 9.933 0 0 1 12 22zm0-18a8 8 0 1 0 5.657 13.657A8 8 0 0 0 12 4z"
          fill="currentColor"
        />
        <path
          d="M14 17h-2a1 1 0 0 1-1-1v-4h-1v-2h2a1 1 0 0 1 1 1v4h1z"
          fill="currentColor"
        />
        <circle cx={12} cy={8} r={1} fill="currentColor" />
        <path d="M12 7a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

InfoIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

InfoIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default InfoIcon;

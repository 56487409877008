import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MoonIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.651 14.751a7.1 7.1 0 0 1-6.469-4.183.5.5 0 0 1 .664-.66 5.989 5.989 0 0 0 2.5.542 6.107 6.107 0 0 0 6.1-6.1 6 6 0 0 0-.542-2.5.5.5 0 0 1 .66-.664 7.1 7.1 0 0 1-2.913 13.565zm-4.91-3.485a6.1 6.1 0 1 0 8.525-8.525 7.027 7.027 0 0 1 .185 1.61 7.108 7.108 0 0 1-7.1 7.1 7.049 7.049 0 0 1-1.61-.184z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22.172 45A20.318 20.318 0 0 1 3.585 32.847a1 1 0 0 1 1.438-1.255 16.689 16.689 0 0 0 8.725 2.45 16.923 16.923 0 0 0 13.19-27.421 1 1 0 0 1 1.072-1.579A20.419 20.419 0 0 1 22.172 45zM6.885 34.746A18.312 18.312 0 0 0 40.5 24.592 18.548 18.548 0 0 0 30.307 8.1a18.746 18.746 0 0 1 2.282 9.031 18.836 18.836 0 0 1-25.7 17.618z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.5 22.5a11 11 0 0 1-10.022-6.479A1 1 0 0 1 2.8 14.7a8.864 8.864 0 0 0 3.7.8 9.01 9.01 0 0 0 9-9 8.86 8.86 0 0 0-.8-3.7 1 1 0 0 1 1.32-1.327A11 11 0 0 1 11.5 22.5zm-6.838-5.153A9 9 0 1 0 17.347 4.663 11.007 11.007 0 0 1 4.662 17.347z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MoonIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MoonIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MoonIcon;

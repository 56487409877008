import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TrashcanIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M3 4h11v1H3z" />
        <path
          d="M11.5 15h-6A1.5 1.5 0 0 1 4 13.5V4h1v9.5a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V4h1v9.5a1.5 1.5 0 0 1-1.5 1.5zM11 4h-1V3H7v1H6V2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M6 6h1v7H6zM8 6h1v7H8zM10 6h1v7h-1z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M5 9h38v2H5zM36 45H12a3 3 0 0 1-3-3V13h2v29a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1V13h2v29a3 3 0 0 1-3 3zM31 7h-2V5H19v2h-2V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1z"
        />
        <path
          fill="currentColor"
          d="M17 17h2v20h-2zM23 17h2v20h-2zM29 17h2v20h-2z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M2 4h21v2H2zM19 24H6a2 2 0 0 1-2-2V6h2v16h13V6h2v16a2 2 0 0 1-2 2z"
        />
        <path
          d="M17 5h-2V3h-5v2H8V2a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1zM9 8h2v12H9zM14 8h2v12h-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TrashcanIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TrashcanIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TrashcanIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** TreeNavigationItem is the leaf item of a tree navigation */
const TreeNavigationItem = props => {
  const { active, label, className, children, ...other } = props;

  return (
    <li className={clsx("em-c-tree__item", className)} {...other}>
      <div className={clsx("em-c-tree__link", { "em-is-active": active })}>
        <span className="em-c-tree__text">{label}</span>
      </div>
    </li>
  );
};

TreeNavigationItem.propTypes = {
  /** active dtermines wether to bold the nav item or not */
  active: PropTypes.bool,
  /** label is the text that is on the nav item */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TreeNavigationItem.defaultProps = {
  active: false,
  label: "",
  className: ""
};

export default TreeNavigationItem;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const InsetBlockBodyHeadline = props => {
  const { className, children, ...other } = props;

  return (
    <h2
      className={clsx(
        "em-c-inset-block__headline",
        "em-js-carousel-headline",
        className
      )}
      {...other}
    >
      {children}
    </h2>
  );
};

InsetBlockBodyHeadline.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

InsetBlockBodyHeadline.defaultProps = {
  className: "",
  children: null
};

export default InsetBlockBodyHeadline;

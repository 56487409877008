import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function VerticalHeaderBodyNavigation(props) {
  const { children, className, isMobileMenuOpen } = props;

  return (
    <div className={clsx("em-c-header__inner", className)}>
      <div
        className={clsx("em-c-header__nav-container", "em-js-nav-panel", {
          "em-is-active": isMobileMenuOpen
        })}
      >
        {children}
      </div>
    </div>
  );
}

VerticalHeaderBodyNavigation.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node,
  /** Automatically passed by the VerticalHeader to indicate if the mobile menu is open */
  isMobileMenuOpen: PropTypes.bool
};

VerticalHeaderBodyNavigation.defaultProps = {
  className: "",
  children: null
};

export default VerticalHeaderBodyNavigation;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CheckInDoc2Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 2h1v6H8z" />
        <path
          d="M13.5 14h-10A1.5 1.5 0 0 1 2 12.5v-9A1.5 1.5 0 0 1 3.5 2H6v1H3.5a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H11V2h2.5A1.5 1.5 0 0 1 15 3.5v9a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M8.5 10.423a.5.5 0 0 1-.354-.146L5.813 7.943l.707-.707 1.98 1.98 1.98-1.98.707.707-2.333 2.334a.5.5 0 0 1-.354.146z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M41 43H5a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h13v2H5a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1H28V7h13a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M22 7h2v20h-2z" />
        <path
          d="M23 31a3.019 3.019 0 0 1-2.111-.849l-6.3-6.127 1.394-1.434 6.3 6.128a1.036 1.036 0 0 0 1.434 0l6.3-6.128 1.394 1.434-6.3 6.127A3.021 3.021 0 0 1 23 31z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21.9 22H2.1A2.1 2.1 0 0 1 0 19.9V3.4C0 1.808.673 1 2 1h6v2H2.013C2 3.121 2 3.293 2 3.4v16.5a.1.1 0 0 0 .1.1h19.8a.1.1 0 0 0 .1-.1V3.4c0-.106 0-.279-.013-.4H16V1h6c1.327 0 2 .808 2 2.4v16.5a2.1 2.1 0 0 1-2.1 2.1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 2h2v12h-2z" />
        <path
          d="M11.956 17.449a2.314 2.314 0 0 1-1.663-.742L6.586 13 8 11.586l3.707 3.707c.113.112.281.281.6-.013L16 11.586 17.414 13l-3.707 3.707a2.562 2.562 0 0 1-1.751.742z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CheckInDoc2Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CheckInDoc2Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default CheckInDoc2Icon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SearchOutlineIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <circle
          cx={7}
          cy={8}
          r={3.5}
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
        <path
          d="M13.793 14.207l-.586.586a1 1 0 0 1-1.414 0L9.75 12.75a5.4 5.4 0 0 1-3.264.726 5.5 5.5 0 1 1 1.2-10.935 5.543 5.543 0 0 1 4.792 4.945 5.4 5.4 0 0 1-.726 3.264l2.043 2.043a1 1 0 0 1-.002 1.414z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <circle
          cx={21}
          cy={21}
          r={12}
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth={2}
        />
        <path
          d="M45.4 43.992l-1.443 1.419a2.068 2.068 0 0 1-2.891 0L31.506 36a19.094 19.094 0 0 1-12.445 3.111 18.819 18.819 0 0 1-16.969-16.65A18.764 18.764 0 0 1 22.911 2.1a18.81 18.81 0 0 1 16.86 17.211 18.24 18.24 0 0 1-3.819 12.539l9.449 9.3a1.988 1.988 0 0 1-.001 2.842z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.349 15.21a9.7 9.7 0 0 0 1.1-6.283 9.385 9.385 0 0 0-7.583-7.794A9.4 9.4 0 0 0 1.115 12.021a9.4 9.4 0 0 0 7.459 7.821 9.184 9.184 0 0 0 7.165-1.642l3.816 3.9a.962.962 0 0 0 1.381 0l1.382-1.4a1.016 1.016 0 0 0 0-1.415zM10.1 16a5.444 5.444 0 0 1-5.281-5.6A5.433 5.433 0 0 1 10.285 5a5.444 5.444 0 0 1 5.281 5.6A5.433 5.433 0 0 1 10.1 16z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="square"
          strokeLinejoin="round"
          strokeWidth={2}
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SearchOutlineIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SearchOutlineIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SearchOutlineIcon;

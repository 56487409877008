import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const InboxIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 0h1v8H8z" />
        <path
          d="M8.5 9a.5.5 0 0 1-.354-.146L5.793 6.5l.707-.707 2 2 2-2 .707.707-2.353 2.354A.5.5 0 0 1 8.5 9zM14.676 8.686L14.006 4H10V3h4.44a.5.5 0 0 1 .5.429l.731 5.115zM2.324 8.686l-.99-.142.731-5.115A.5.5 0 0 1 2.56 3H7v1H2.994z"
          fill="currentColor"
        />
        <path
          d="M15.5 15h-14a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5V11h5V9.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM2 14h13v-4h-3v1.5a.5.5 0 0 1-.5.5h-6a.5.5 0 0 1-.5-.5V10H2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43.669 26.145l-2.106-13.271A1.17 1.17 0 0 0 40.351 12H29v-2h11.351a3.138 3.138 0 0 1 3.188 2.561l2.106 13.271zM4.343 26.135l-1.979-.292 1.962-13.272A3.063 3.063 0 0 1 7.363 10H19v2H7.363a1.052 1.052 0 0 0-1.059.863zM42 46H6a3 3 0 0 1-3-3V30a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4h14v-4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v13a3 3 0 0 1-3 3zM5 30v13a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V30H33v4a2 2 0 0 1-2 2H17a2 2 0 0 1-2-2v-4zM23 0h2v18h-2z"
          fill="currentColor"
        />
        <path
          d="M24 24a3.019 3.019 0 0 1-2.111-.849l-6.3-6.127 1.394-1.434 6.3 6.128a1.036 1.036 0 0 0 1.434 0l6.3-6.128 1.394 1.434-6.3 6.127A3.021 3.021 0 0 1 24 24z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21.215 12.177L20.02 6.2a.337.337 0 0 0-.11-.214L15 6V4h5a2.124 2.124 0 0 1 1.99 1.859l1.187 5.926zM21 23H3a2 2 0 0 1-2-2v-7a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h6v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2zM3 15v6h18v-6h-4v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-2zM11 0h2v9h-2z"
          fill="currentColor"
        />
        <path
          d="M12 12.748c-.506 0-1.013-.347-1.707-1.041L7.586 9 9 7.586l2.707 2.707c.1.1.2.2.293.29.092-.089.2-.192.293-.29L15 7.586 16.414 9l-2.707 2.707c-.694.693-1.201 1.041-1.707 1.041zM2.785 12.177l-1.962-.392L2.02 5.8A2.106 2.106 0 0 1 4 4h5v2H4c.055 0 0 .087-.01.141z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

InboxIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

InboxIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default InboxIcon;

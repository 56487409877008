import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Vertical Header Band Link Item */
function VerticalHeaderBodyBandItem(props) {
  const { children } = props;

  return React.Children.toArray(children).map(child =>
    React.cloneElement(child, {
      className: clsx("em-c-solid-nav__link", child.props.className)
    })
  );
}

VerticalHeaderBodyBandItem.propTypes = {
  /** children of component */
  children: PropTypes.node
};

VerticalHeaderBodyBandItem.defaultProps = {
  children: null
};

export default VerticalHeaderBodyBandItem;

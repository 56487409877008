export const FILTER_DATE_RANGE = {
    today: { value: 1, display: 'Yesterday', summary: 'daily', type: 'day' },
    week: { value: 7, display: 'Last 7 days', summary: 'weekly', type: 'day' },
    thirtyDays: { value: 30, display: 'Last 30 days', summary: 'monthly', type: 'day' },
    ninetyDays: { value: 90, display: 'Last 90 days', summary: 'last90days', type: 'day' },
    thirteenMonths: { value: 13, display: 'Last 13 months', summary: 'last13months', type: 'month' },
};

export const SORT_OPTIONS = {
    session_desc: { display: 'Sort by sessions ↓', field: 'sessions', ascending: false },
    session_asc: { display: 'Sort by sessions ↑', field: 'sessions', ascending: true },
    user_desc: { display: 'Sort by users ↓', field: 'activeDevices', ascending: false },
    user_asc: { display: 'Sort by users ↑', field: 'activeDevices', ascending: true },
    crash_desc: { display: 'Sort by crashes ↓', field: 'crashes', ascending: false },
    crash_asc: { display: 'Sort by crashes ↑', field: 'crashes', ascending: true },
    crashfree_desc: { display: 'Sort by crash free % ↓', field: 'crashFreeSessions', ascending: false },
    crashfree_asc: { display: 'Sort by crash free % ↑', field: 'crashFreeSessions', ascending: true },
    unique_desc: { display: 'Sort by unique crashes ↓', field: 'uniqueCrashes', ascending: false },
    unique_asc: { display: 'Sort by unique crashes ↑', field: 'uniqueCrashes', ascending: true },
};

export const SORT_OPTIONS_WEB = {
    session_users_desc: { display: 'Sort by session users ↓', field: 'sessionUsers', ascending: false },
    unique_user_desc: { display: 'Sort by unique users ↓', field: 'uniqueUsers', ascending: false },
    session_user_asc: { display: 'Sort by session users ↑', field: 'sessionUsers', ascending: true },
    unique_user_asc: { display: 'Sort by unique users ↑', field: 'uniqueUsers', ascending: true },
};

export const CHART_TYPES = {
    activity: 'Activity',
};

export const colors = {
    sessions: '#113ea8',
    users: '#29a8d6',
    crashFree: '#ff8f17',
    crashes: '#d14343',
};

export const GRAPH_SCOPE = 'Directory.Read.All';
export const DASHBOARD_HUB_API_DEV_SCOPE = 'api://dashboard-hub-api-dev/user.read';
export const DASHBOARD_HUB_API_PRD_SCOPE = 'api://dashboard-hub-api-prd/user.read';

export const configStatuses = {
    0: ['negative', 'Inactive'],
    1: ['positive', 'Active'],
    2: ['caution', 'Archived'],
};

export default {
    FILTER_DATE_RANGE,
};

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowRightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M1.5 8h10v1h-10z" />
        <path
          d="M10.176 13.039l-.736-.677 3.5-3.8a.16.16 0 0 0-.009-.2L9.44 4.563l.736-.677 3.5 3.8a1.167 1.167 0 0 1 .009 1.534z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M3 23h33v2H3z" />
        <path
          d="M30.414 36.414L29 35l10.293-10.293a1 1 0 0 0 0-1.414L29 13l1.414-1.414 10.293 10.293a3 3 0 0 1 0 4.243z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16.108 20.41l-1.519-1.3L20.683 12 14.59 4.892l1.519-1.3L22.2 10.7a1.99 1.99 0 0 1 0 2.6z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M2 11h16v2H2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowRightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowRightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowRightIcon;

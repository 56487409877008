import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import StackedBlockBodyDescription from "./StackedBlockBodyDescription";

const StackedBlockBody = props => {
  const { className, children, ...other } = props;
  return (
    <div className={clsx("em-c-stacked-block__body", className)} {...other}>
      {children}
    </div>
  );
};

StackedBlockBody.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

StackedBlockBody.defaultProps = {
  className: "",
  children: null
};

StackedBlockBody.Description = StackedBlockBodyDescription;

export default StackedBlockBody;

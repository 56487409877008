import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function CheckboxDropdownItem(props) {
  const { className, label, inputProps, ...other } = props;

  return (
    <li className={clsx("em-c-dropdown-check__item", className)} {...other}>
      <label className="em-c-input-group">
        <input
          className="em-c-input-group__control"
          type="checkbox"
          name=""
          value=""
          {...inputProps}
        />
        <span className="em-c-input-group__text">{label}</span>
      </label>
    </li>
  );
}

CheckboxDropdownItem.propTypes = {
  /** Class to append to the outer element */
  className: PropTypes.string,
  /** Title of the option */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Props to send to the <Input /> element */
  inputProps: PropTypes.object
};

CheckboxDropdownItem.defaultProps = {
  className: "",
  label: "",
  inputProps: {}
};

export default CheckboxDropdownItem;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowDownIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 1.5h1v10H8z" />
        <path
          d="M8.5 13.938a1.151 1.151 0 0 1-.758-.283l-3.819-3.511.677-.736 3.8 3.5a.16.16 0 0 0 .2-.009l3.8-3.491.677.736-3.8 3.5a1.179 1.179 0 0 1-.777.294z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 3h2v33h-2z" />
        <path
          d="M24 41.5a2.981 2.981 0 0 1-2.122-.879L11.586 30.329 13 28.915l10.293 10.292a1.023 1.023 0 0 0 1.414 0L35 28.915l1.414 1.414-10.293 10.292A2.979 2.979 0 0 1 24 41.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 23a2 2 0 0 1-1.294-.477l-6.067-5.145 1.294-1.525L12 21l6.067-5.147 1.294 1.525-6.067 5.147A2 2 0 0 1 12 23z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 2.312h2v16h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowDownIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowDownIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowDownIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import StackedBlockHeaderLinkImage from "./StackedBlockHeaderLinkImage";

const StackedBlockHeaderLink = props => {
  const { className, children, ...other } = props;
  return (
    <div className={clsx("em-c-stacked-block__link", className)} {...other}>
      {children}
    </div>
  );
};

StackedBlockHeaderLink.Image = StackedBlockHeaderLinkImage;

StackedBlockHeaderLink.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

StackedBlockHeaderLink.defaultProps = {
  className: "",
  children: null
};

export default StackedBlockHeaderLink;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { XFilledIcon } from "../Icons";
import TagAvatar from "./TagAvatar";
import TagGroup from "./TagGroup";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-tags__link--main";
    case "accent":
      return "em-c-tags__link--accent";
    case "action":
      return "em-c-tags__link--action";
    case "caution":
      return "em-c-tags__link--caution";
    case "positive":
      return "em-c-tags__link--positive";
    case "negative":
      return "em-c-tags__link--negative";
    default:
      return "";
  }
}

/** Tag is another component that is not generally meant to be used alone, it is meant to be use only within TagList. Think very carefully before using it alone. */
const Tag = props => {
  const {
    color,
    onClick,
    onCloseAction,
    className,
    children,
    ...other
  } = props;

  return (
    <div className={clsx("em-c-tags__item", className)} {...other}>
      <span
        className={clsx("em-c-tags__link", getColor(color))}
        style={{ cursor: "pointer" }}
      >
        <span
          role="button"
          tabIndex={0}
          onKeyPress={onClick}
          className="em-c-tags__text em-c-tags--flex"
          onClick={onClick}
        >
          {children}
        </span>

        {onCloseAction && (
          <span onClick={onCloseAction}>
            <XFilledIcon size="small" className="em-c-tags__icon-inside" />
          </span>
        )}
      </span>
    </div>
  );
};

Tag.propTypes = {
  /** Theme Color */
  color: PropTypes.oneOf([
    "default",
    "main",
    "accent",
    "action",
    "caution",
    "positive",
    "negative"
  ]),
  /** Function called when the tag text is clicked */
  onClick: PropTypes.func,
  /** Function called when the x is clicked */
  onCloseAction: PropTypes.func,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  children: PropTypes.node
};

Tag.defaultProps = {
  color: "default",
  onClick: () => {},
  onCloseAction: null,
  className: "",
  children: null
};

Tag.Avatar = TagAvatar;
Tag.Group = TagGroup;

export default Tag;

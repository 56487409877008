import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HeadphonesIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.5 13h-2A1.5 1.5 0 0 1 1 11.5v-3A1.5 1.5 0 0 1 2.5 7h2A1.5 1.5 0 0 1 6 8.5v3A1.5 1.5 0 0 1 4.5 13zm-2-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM13.5 13h-2a1.5 1.5 0 0 1-1.5-1.5v-3A1.5 1.5 0 0 1 11.5 7h2A1.5 1.5 0 0 1 15 8.5v3a1.5 1.5 0 0 1-1.5 1.5zm-2-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM13 6.5h-1V6a4 4 0 0 0-8 0v.5H3V6a5 5 0 0 1 10 0z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M14 43H7a5.006 5.006 0 0 1-5-5V28a5.006 5.006 0 0 1 5-5h7a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zM7 25a3 3 0 0 0-3 3v10a3 3 0 0 0 3 3h7V25zM41 43h-7a2 2 0 0 1-2-2V25a2 2 0 0 1 2-2h7a5.006 5.006 0 0 1 5 5v10a5.006 5.006 0 0 1-5 5zm-7-18v16h7a3 3 0 0 0 3-3V28a3 3 0 0 0-3-3zM42 21h-2v-1A15.017 15.017 0 0 0 25 5h-2A15.017 15.017 0 0 0 8 20v1H6v-1A17.019 17.019 0 0 1 23 3h2a17.019 17.019 0 0 1 17 17z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7 21H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h4a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm-4-8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h3v-6zM21 21h-4a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3zm-3-2h3a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-3zM21.5 10h-2V9a7.008 7.008 0 0 0-7-7h-1a7.008 7.008 0 0 0-7 7v1h-2V9a9.01 9.01 0 0 1 9-9h1a9.01 9.01 0 0 1 9 9z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HeadphonesIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HeadphonesIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HeadphonesIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SnowflakeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 0h1v2H8zM8 5h1v6H8zM8.5 4a.5.5 0 0 1-.224-.053l-2-1 .447-.895 1.777.889 1.776-.888.447.895-2 1A.5.5 0 0 1 8.5 4zM8 14h1v2H8zM10.276 13.947L8.5 13.059l-1.776.888-.447-.895 2-1a.5.5 0 0 1 .447 0l2 1zM1.349 4.417l.5-.866 1.732 1-.5.866z"
        />
        <path
          fill="currentColor"
          d="M5.68 6.918l.5-.866 5.196 3-.5.866zM3.039 7.384l-.551-.835 1.658-1.094.119-1.982 1 .06-.136 2.231a.5.5 0 0 1-.224.388zM13.473 11.417l.5-.866 1.732 1-.5.866zM12.789 12.5l-1-.06.134-2.232a.5.5 0 0 1 .224-.387l1.866-1.232.551.835-1.657 1.094zM1.348 11.52l1.733-1 .5.866-1.733 1z"
        />
        <path
          fill="currentColor"
          d="M5.679 9.02l5.196-3 .5.866-5.196 3zM4.265 12.465l-.119-1.982-1.658-1.094.551-.835 1.866 1.232a.5.5 0 0 1 .224.388l.134 2.232zM13.473 4.52l1.732-1 .5.866-1.732 1zM14.015 7.353l-1.866-1.232a.5.5 0 0 1-.224-.387L11.791 3.5l1-.06.119 1.982 1.657 1.094z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 0h2v6h-2zM23 14h2v20h-2z" />
        <path
          d="M24 11a1 1 0 0 1-.707-.293l-4-4 1.414-1.414L24 8.586l3.293-3.293 1.414 1.414-4 4A1 1 0 0 1 24 11zM27.293 42.707L24 39.414l-3.293 3.293-1.414-1.414 4-4a1 1 0 0 1 1.414 0l4 4z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M23 42h2v6h-2zM2.215 12.867l1-1.732 5.196 3-1 1.732zM14.34 19.865l1-1.732 17.32 10-1 1.732z"
        />
        <path
          d="M6.6 19.68l-.518-1.932 4.5-1.205-1.205-4.5 1.932-.518 1.464 5.464a1 1 0 0 1-.707 1.225zM35.689 36.473l-1.464-5.464a1 1 0 0 1 .707-1.225L40.4 28.32l.518 1.932-4.5 1.205 1.205 4.5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M38.588 33.866l1-1.732 5.197 3-1 1.732zM2.215 35.134l5.196-3 1 1.733-5.196 3zM14.34 28.133l17.32-9.999 1 1.732-17.321 9.999z"
        />
        <path
          d="M11.311 36.473l-1.932-.518 1.205-4.5-4.5-1.205.516-1.93 5.464 1.464a1 1 0 0 1 .707 1.225zM40.4 19.68l-5.464-1.464a1 1 0 0 1-.707-1.225l1.464-5.464 1.932.518-1.205 4.5 4.5 1.205z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M38.588 14.134l5.196-3 1 1.732-5.196 3z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11 1h2v2h-2zM11 9h2v6h-2zM12 7.5a1 1 0 0 1-.707-.293l-2-2 1.414-1.414L12 5.086l1.293-1.293 1.414 1.414-2 2A1 1 0 0 1 12 7.5zM11 21h2v2h-2zM13.293 20.207L12 18.914l-1.293 1.293-1.414-1.414 2-2a1 1 0 0 1 1.414 0l2 2zM.553 6.894l.894-1.788 2 1-.894 1.788z"
        />
        <path
          fill="currentColor"
          d="M8.445 10.832l1.11-1.665 6 4-1.11 1.665zM3.316 10.949l-.633-1.9 2.051-.684-.683-2.049 1.9-.633 1 3a1 1 0 0 1-.632 1.265zM20.553 17.894l.894-1.789 2 1-.894 1.79zM18.051 18.316l-1-3a1 1 0 0 1 .632-1.265l3-1 .633 1.9-2.051.685.684 2.051zM.487 17.134l1.925-1.14 1.018 1.722-1.924 1.139z"
        />
        <path
          fill="currentColor"
          d="M8.445 13.168l6-4 1.11 1.665-6 4zM6.411 18.729l-1.937-.5.537-2.095-2.095-.534.5-1.937 3.063.785a1 1 0 0 1 .721 1.217zM20.57 6.284l1.924-1.139 1.018 1.722-1.924 1.139zM20.588 10.336l-3.063-.785a1 1 0 0 1-.725-1.217l.785-3.063 1.938.5-.537 2.094 2.095.537z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SnowflakeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SnowflakeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SnowflakeIcon;

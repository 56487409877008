import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SearchLineIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M7.5 13A5.5 5.5 0 1 1 13 7.5 5.507 5.507 0 0 1 7.5 13zm0-10A4.5 4.5 0 1 0 12 7.5 4.5 4.5 0 0 0 7.5 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M10.432 11.14l.708-.707 4.006 4.007-.707.707z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M22 38a16 16 0 1 1 16-16 16.018 16.018 0 0 1-16 16zm0-30a14 14 0 1 0 14 14A14.016 14.016 0 0 0 22 8z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M31.586 33L33 31.586 44.414 43 43 44.414z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9.5 18A8.5 8.5 0 1 1 18 9.5 8.51 8.51 0 0 1 9.5 18zm0-15A6.5 6.5 0 1 0 16 9.5 6.508 6.508 0 0 0 9.5 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M13.585 15L15 13.586 22.414 21l-1.415 1.414z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SearchLineIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SearchLineIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SearchLineIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TrainIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.27 16H1.73a.5.5 0 0 1-.354-.854l3.96-3.96.707.707L2.938 15h11.125l-3.106-3.106.707-.707 3.96 3.96a.5.5 0 0 1-.354.853z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M4 13h9v1H4zM12.5 12h-8A1.5 1.5 0 0 1 3 10.5v-9A1.5 1.5 0 0 1 4.5 0h8A1.5 1.5 0 0 1 14 1.5v9a1.5 1.5 0 0 1-1.5 1.5zm-8-11a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5z"
        />
        <path
          d="M11.5 6h-6a.5.5 0 0 1-.5-.5v-3a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5zM6 5h5V3H6zM15 5h1v3h-1zM1 5h1v3H1zM11.5 10h-6a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM6 9h5V8H6z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 7.5h1v2H8z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 47H5a1 1 0 0 1-.707-1.707l12-12 1.414 1.414L7.414 45h33.172L30.293 34.707l1.414-1.414 12 12A1 1 0 0 1 43 47z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M9 41h30v2H9zM13 37h22v2H13zM35 35H13a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h22a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3zM13 5a1 1 0 0 0-1 1v26a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z"
        />
        <path
          d="M33 23H15a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1zm-17-2h16V9H16zM22 31h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM33 31h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM10 25H7a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3v2H8v6h2zM41 25h-3v-2h2v-6h-2v-2h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21.5 24h-19a1 1 0 0 1-.707-1.707l6-6 1.414 1.414L4.914 22h14.172l-4.293-4.293 1.414-1.414 6 6A1 1 0 0 1 21.5 24z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M6 19h12v2H6zM17 18H7a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v13a2 2 0 0 1-2 2zM7 3v13h10V3z"
        />
        <path
          d="M15 12H9a1 1 0 0 1-1-1V5a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4V6h-4zM9 13h2v2H9zM13 13h2v2h-2zM20 7h2v6h-2zM2 7h2v6H2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TrainIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TrainIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TrainIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FlaskIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.955 15h-5.91a2.5 2.5 0 0 1-2.017-3.978L6 6.968V3h-.5a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H8V2h2V1H6v1h.5a.5.5 0 0 1 .5.5v4.631a.5.5 0 0 1-.1.3l-3.065 4.182A1.5 1.5 0 0 0 5.045 14h5.909a1.5 1.5 0 0 0 1.21-2.387L9.1 7.427a.5.5 0 0 1-.1-.3V5H8V4h1.5a.5.5 0 0 1 .5.5v2.468l2.971 4.055A2.5 2.5 0 0 1 10.955 15z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 6h2v1H8z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39.559 47H10.441a5 5 0 0 1-3.87-8.167L19 23.643V9h-3a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1h-7V7h6V3H17v4h3a1 1 0 0 1 1 1v16a1 1 0 0 1-.226.633L8.119 40.1a3 3 0 0 0 2.322 4.9h29.118a3 3 0 0 0 2.322-4.9L29.226 24.633A1 1 0 0 1 29 24V13h-2v-2h3a1 1 0 0 1 1 1v11.643l12.429 15.191A5 5 0 0 1 39.559 47z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M27 15h4v2h-4zM27 19h4v2h-4zM27 23h4v2h-4zM29.03 37a9.825 9.825 0 0 1-4.483-1.108 7.637 7.637 0 0 0-5.418-.657l-2.881.735-.494-1.937 2.881-.735a9.6 9.6 0 0 1 6.819.813 7.639 7.639 0 0 0 5.418.657l2.881-.735.494 1.938-2.881.735A9.433 9.433 0 0 1 29.03 37z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 24H4a2.474 2.474 0 0 1-2.357-1.374 3.09 3.09 0 0 1 .583-3.26L8 13.586V6H7a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4V4h3V2H8v2h1a1 1 0 0 1 1 1v9a1 1 0 0 1-.293.707l-6 6a1.128 1.128 0 0 0-.257 1.063c.072.153.257.23.55.23h16c.293 0 .478-.077.55-.23a1.188 1.188 0 0 0-.324-1.137l-5.933-5.926A1 1 0 0 1 14 14V9h-2V7h3a1 1 0 0 1 1 1v5.586l5.707 5.707a3.132 3.132 0 0 1 .65 3.333A2.474 2.474 0 0 1 20 24z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M12 11h3.5v2H12z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FlaskIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FlaskIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FlaskIcon;

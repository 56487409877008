import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PrinterIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 16h-8a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM5 15h7V8H5z"
          fill="currentColor"
        />
        <path
          d="M15.5 13H14v-1h1V5.5a.5.5 0 0 0-.5-.5h-12a.5.5 0 0 0-.5.5V12h1v1H1.5a.5.5 0 0 1-.5-.5v-7A1.5 1.5 0 0 1 2.5 4h12A1.5 1.5 0 0 1 16 5.5v7a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M13 5h-1V1H5v4H4V.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5zM6 9h5v1H6zM6 11h5v1H6zM6 13h4v1H6z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37 48H11a1 1 0 0 1-1-1V27a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v20a1 1 0 0 1-1 1zm-25-2h24V28H12z"
          fill="currentColor"
        />
        <path
          d="M44 38h-4v-2h4V12H4v24h4v2H4a2 2 0 0 1-2-2V12a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v24a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M35.5 21a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM38 12h-2V2H12v10h-2V1a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1zM14 30h20v2H14zM14 34h18v2H14zM14 38h20v2H14zM14 42h10v2H14z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 24H5a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1zM6 22h12v-7H6z"
          fill="currentColor"
        />
        <path
          d="M23 19h-2v-2h1V7H2v10h1v2H1a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <circle cx={19} cy={9} r={1} fill="#112" />
        <path
          d="M19 8a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM20 7h-2V2H6v5H4V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1zM7 16h10v2H7zM7 19h10v2H7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PrinterIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PrinterIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PrinterIcon;

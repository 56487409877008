import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { HamburgerIcon } from "../../Icons";
import Button from "../../Button";

/** Vertical Header Title */
const VerticalHeaderTitle = props => {
  const {
    className,
    children,
    icon,
    onMobileMenuClick,
    isMobileMenuOpen,
    ...other
  } = props;

  const Title = () => {
    // If child is just a string, let's just style it
    if (typeof children === "string") {
      return (
        <span className={clsx("em-c-header__title-logo", className)}>
          {children}
        </span>
      );
    }

    // If child is anything else (i.e. <a>, <Link>), just clone and add class.
    return React.Children.toArray(children).map(child =>
      React.cloneElement(child, {
        className: clsx("em-c-header__title-logo", child.props.className)
      })
    );
  };

  return (
    <div className="em-c-header__band" {...other}>
      <div className="em-c-header__body">
        <div className="em-c-header__title-container">
          <h2 className="em-c-header__title">
            <Title />
          </h2>
        </div>

        {/** Show the Menu button for mobile */}
        {children ? (
          <Button
            className="em-c-header__nav-btn"
            variant="inverted"
            size="small"
            onClick={onMobileMenuClick}
          >
            <HamburgerIcon />
            <span>&nbsp;Menu</span>
          </Button>
        ) : (
          ""
        )}
        {icon}
      </div>
    </div>
  );
};

VerticalHeaderTitle.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node,
  /** Automatically passed by the VerticalHeader to indicate if the mobile menu is open */
  isMobileMenuOpen: PropTypes.bool,
  /** Automatically passed by the VerticalHeader to change if the mobile menu is open */
  onMobileMenuClick: PropTypes.func,
  /** Icon to display next to the title. Most likely a search icon */
  icon: PropTypes.node
};

VerticalHeaderTitle.defaultProps = {
  className: "",
  children: null,
  icon: <></>
};

export default VerticalHeaderTitle;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretDownIcon } from "../../../Icons";
import HorizontalHeaderGlobalNavDropdownItem from "./HorizontalHeaderGlobalNavDropdownItem";

/** GlobalNavDropdown creates a dropdown for more global items within it. */
const HorizontalHeaderGlobalNavDropdown = props => {
  const { className, img, text, open, children, ...other } = props;
  const [isOpen, setIsOpen] = useState(open || false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // When the component mounts, add the Unity JS and Mouse Down Event
    if (!document.body.classList.contains("em-js")) {
      document.body.classList.add("em-js");
    }
    document.addEventListener("mousedown", handleClickOutside);

    // When the component unmounts, remove the Unity JS and Mouse Down Event
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function handleClickOutside(event) {
    if (!dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  function handleClick() {
    setIsOpen(prev => !prev);
  }

  return (
    <li
      className={clsx(
        "em-c-global-nav__item",
        "em-is-aligned-right",
        { "em-is-active": isOpen },
        className
      )}
      ref={dropdownRef}
      {...other}
    >
      <span
        className="em-c-global-nav__link  em-c-global-nav__link--has-children em-no-outline em-u-clickable"
        onClick={handleClick}
        role="button"
        tabIndex={0}
        onKeyPress={handleClick}
      >
        <img src={img} alt="Alt Text" className="em-c-avatar em-c-avatar" />
        {text}
        <CaretDownIcon size="small" className="em-c-global-nav__icon" />
      </span>
      <ul
        className={clsx("em-c-global-nav__sublist", "em-js-nav-dropdown", {
          "em-is-active": isOpen
        })}
      >
        {children}
      </ul>
    </li>
  );
};

HorizontalHeaderGlobalNavDropdown.propTypes = {
  /** img is the url to the image that will be displayed in the global nav item */
  img: PropTypes.string,
  /** text is the string that will be shown */
  text: PropTypes.node,
  /** If the dropdown is initially expanded or not */
  open: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case Checkboxs */
  children: PropTypes.node
};

HorizontalHeaderGlobalNavDropdown.defaultProps = {
  text: "Global Link",
  img: "https://unity.exxonmobil.com/images/sample/avatar-nick.png",
  open: false,
  className: "",
  children: null
};

HorizontalHeaderGlobalNavDropdown.Item = HorizontalHeaderGlobalNavDropdownItem;

export default HorizontalHeaderGlobalNavDropdown;

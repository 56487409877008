import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CheckInDoc1Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 3h1v5H8zM15.5 16h-14a.5.5 0 0 1-.5-.5V13h1v2h13v-2h1v2.5a.5.5 0 0 1-.5.5z"
        />
        <path
          d="M8.5 9.81a.5.5 0 0 1-.354-.146L5.813 7.33l.707-.707L8.5 8.6l1.98-1.98.707.707-2.333 2.337a.5.5 0 0 1-.354.146z"
          fill="currentColor"
        />
        <path
          d="M12.5 13h-8A1.5 1.5 0 0 1 3 11.5v-10A1.5 1.5 0 0 1 4.5 0h5.165A1.493 1.493 0 0 1 10.7.411l2.835 2.68A1.507 1.507 0 0 1 14 4.181V11.5a1.5 1.5 0 0 1-1.5 1.5zm-8-12a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V4.181a.5.5 0 0 0-.157-.363l-2.835-2.681A.5.5 0 0 0 9.665 1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 48H3a3 3 0 0 1-3-3v-5h2v5a1 1 0 0 0 1 1h40a1 1 0 0 0 1-1v-5h2v5a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M37 42H9a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h18.343a4.967 4.967 0 0 1 3.535 1.464l7.657 7.657A4.967 4.967 0 0 1 40 12.657V39a3 3 0 0 1-3 3zM9 2a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1V12.657a3.022 3.022 0 0 0-.878-2.121l-7.658-7.658A3.022 3.022 0 0 0 27.343 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M22 10h2v16h-2z" />
        <path
          d="M23 30a3.019 3.019 0 0 1-2.111-.849l-6.3-6.127 1.394-1.434 6.3 6.128a1.036 1.036 0 0 0 1.434 0l6.3-6.128 1.394 1.434-6.3 6.127A3.021 3.021 0 0 1 23 30z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11 5h2v9h-2zM23 24H1a1 1 0 0 1-1-1v-4h2v3h20v-3h2v4a1 1 0 0 1-1 1z"
        />
        <path
          d="M11.956 17.449a2.314 2.314 0 0 1-1.663-.742L6.586 13 8 11.586l3.707 3.707c.113.112.282.281.6-.013L16 11.586 17.414 13l-3.707 3.707a2.562 2.562 0 0 1-1.751.742z"
          fill="currentColor"
        />
        <path
          d="M19 21H5a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h9.171a2.98 2.98 0 0 1 2.122.879l3.414 3.414A4.515 4.515 0 0 1 21 6.829V19a2 2 0 0 1-2 2zM5 2v17h14V6.829a3.387 3.387 0 0 0-.707-1.122l-3.414-3.414A.994.994 0 0 0 14.171 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CheckInDoc1Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CheckInDoc1Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default CheckInDoc1Icon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HomeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 15h-11a.5.5 0 0 1-.5-.5V8h1v6h10V8h1v6.5a.5.5 0 0 1-.5.5zM15.328 7.625L8.022 3.14a.744.744 0 0 0-.091.025L.672 7.625l-.524-.853 7.307-4.485A1.18 1.18 0 0 1 8.5 2.262l7.353 4.51z"
          fill="currentColor"
        />
        <path
          d="M10 13H9V8H7v5H6V7.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 44H9a3 3 0 0 1-3-3V22h2v19a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V22h2v19a3 3 0 0 1-3 3zM46.471 20.849L24.384 7.079a.965.965 0 0 0-.769 0L1.529 20.849l-1.058-1.7 22.14-13.8A.909.909 0 0 1 22.7 5.3a2.964 2.964 0 0 1 2.591 0 .909.909 0 0 1 .093.052l22.14 13.8z"
          fill="currentColor"
        />
        <path
          d="M30 38h-2V24h-8v14h-2V23a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.941 24H5.059A2.082 2.082 0 0 1 3 21.9V11h2v10.9c0 .066.041.1.059.1h13.882c.018 0 .059-.034.059-.1V11h2v10.9a2.082 2.082 0 0 1-2.059 2.1zM1.614 10.79L.386 9.21l8.956-6.965a4.04 4.04 0 0 1 5.365.048l7.952 6.955-1.317 1.5-8-7a2.08 2.08 0 0 0-2.634-.045 1.072 1.072 0 0 1-.093.083z"
          fill="currentColor"
        />
        <path
          d="M16 20h-2v-7h-4v7H8v-8a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HomeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HomeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HomeIcon;

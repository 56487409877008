import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SolidCardBodyIcon = props => {
  const { children } = props;

  return children
    ? React.cloneElement(children, {
        className: clsx("em-c-solid-card__icon", children.props.className),
        size: "large"
      })
    : "";
};

SolidCardBodyIcon.propTypes = {
  /** React component children */
  children: PropTypes.node
};

SolidCardBodyIcon.defaultProps = {
  children: null
};

export default SolidCardBodyIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LinkIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M4 8h8v1H4z" />
        <path
          d="M6.5 12h-6a.5.5 0 0 1-.5-.5v-6A.5.5 0 0 1 .5 5h6a.5.5 0 0 1 .5.5V7H6V6H1v5h5v-1h1v1.5a.5.5 0 0 1-.5.5zM15.5 12h-6a.5.5 0 0 1-.5-.5V10h1v1h5V6h-5v1H9V5.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M12 24h24v2H12z" />
        <path
          d="M19.1 36H3.9A2.955 2.955 0 0 1 1 33V17a2.955 2.955 0 0 1 2.9-3h15.2a2.955 2.955 0 0 1 2.9 3v5h-2v-5a.956.956 0 0 0-.9-1H3.9a.956.956 0 0 0-.9 1v16a.956.956 0 0 0 .9 1h15.2a.956.956 0 0 0 .9-1v-5h2v5a2.955 2.955 0 0 1-2.9 3zM44.1 36H28.9a2.955 2.955 0 0 1-2.9-3v-5h2v5a.956.956 0 0 0 .9 1h15.2a.956.956 0 0 0 .9-1V17a.956.956 0 0 0-.9-1H28.9a.956.956 0 0 0-.9 1v5h-2v-5a2.955 2.955 0 0 1 2.9-3h15.2a2.955 2.955 0 0 1 2.9 3v16a2.955 2.955 0 0 1-2.9 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M6 12h12v2H6z" />
        <path
          d="M10 19H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v3H9V9H2v8h7v-2h2v3a1 1 0 0 1-1 1zM23 19h-9a1 1 0 0 1-1-1v-3h2v2h7V9h-7v2h-2V8a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LinkIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LinkIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LinkIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OutletIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 16h-10a.5.5 0 0 1-.5-.5v-14a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5zM4 15h9V2H4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M7 5h1v2H7zM9 5h1v2H9z" />
        <path
          d="M10.3 9H6.7a.5.5 0 0 1-.416-.223l-.534-.8A3.509 3.509 0 0 1 5.682 4.2l.593-.963A.5.5 0 0 1 6.7 3h3.6a.5.5 0 0 1 .426.238l.593.963a3.509 3.509 0 0 1-.068 3.775l-.534.8A.5.5 0 0 1 10.3 9zM6.968 8h3.064l.386-.578a2.509 2.509 0 0 0 .049-2.7L10.021 4H6.979l-.446.725a2.509 2.509 0 0 0 .049 2.7zM7 10h1v2H7zM9 10h1v2H9z"
          fill="currentColor"
        />
        <path
          d="M10.3 14H6.7a.5.5 0 0 1-.426-.238l-.592-.962a3.509 3.509 0 0 1 .068-3.777l.534-.8A.5.5 0 0 1 6.7 8h3.6a.5.5 0 0 1 .416.223l.534.8a3.509 3.509 0 0 1 .068 3.775l-.593.963A.5.5 0 0 1 10.3 14zm-3.32-1h3.041l.446-.725a2.509 2.509 0 0 0-.049-2.7L10.032 9H6.968l-.386.578a2.509 2.509 0 0 0-.049 2.7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M38 46H12a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h26a3 3 0 0 1 3 3v38a3 3 0 0 1-3 3zM12 4a1 1 0 0 0-1 1v38a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M22 11h2v6h-2zM26 11h2v6h-2z" />
        <path
          d="M30.132 21.013H19.868a1.013 1.013 0 0 1-.9-.55l-.784-1.528a10.825 10.825 0 0 1 0-9.869l.784-1.528a1.013 1.013 0 0 1 .9-.55h10.264a1.013 1.013 0 0 1 .9.55l.784 1.528a10.825 10.825 0 0 1 0 9.869l-.784 1.528a1.013 1.013 0 0 1-.9.55zm-9.645-2.025h9.025l.5-.979a8.8 8.8 0 0 0 0-8.018l-.5-.979h-9.025l-.5.979a8.8 8.8 0 0 0 0 8.018zM30.132 41.013H19.868a1.013 1.013 0 0 1-.9-.55l-.784-1.528a10.825 10.825 0 0 1 0-9.869l.784-1.528a1.013 1.013 0 0 1 .9-.55h10.264a1.013 1.013 0 0 1 .9.55l.784 1.528a10.825 10.825 0 0 1 0 9.869l-.784 1.528a1.013 1.013 0 0 1-.9.55zm-9.645-2.025h9.025l.5-.979a8.8 8.8 0 0 0 0-8.018l-.5-.979h-9.025l-.5.979a8.8 8.8 0 0 0 0 8.018z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M22 31h2v6h-2zM26 31h2v6h-2zM25 22a2 2 0 1 0 2 2 2 2 0 0 0-2-2zm0 2.5a.5.5 0 1 1 .5-.5.5.5 0 0 1-.5.5z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M8 7h2v9H8zM15 7h2v9h-2z" />
        <path
          d="M21.067 21H3.937a1 1 0 0 1-.948-.682l-1.058-3.147a17.926 17.926 0 0 1 0-11.342l1.057-3.147A1 1 0 0 1 3.937 2h17.13a1 1 0 0 1 .948.682l1.058 3.147a17.926 17.926 0 0 1 0 11.342l-1.058 3.147a1 1 0 0 1-.948.682zM4.655 19h15.694l.828-2.466a15.9 15.9 0 0 0 0-10.068L20.349 4H4.655l-.828 2.466a15.9 15.9 0 0 0 0 10.068z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OutletIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OutletIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default OutletIcon;

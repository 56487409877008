import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Renders an avatar on the tag, given an image in the src */
const TagAvatar = props => {
  const { className, ...other } = props;

  return <img className={clsx("em-c-avatar", className)} {...other} />;
};

TagAvatar.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TagAvatar.defaultProps = {
  className: ""
};

export default TagAvatar;

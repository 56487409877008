import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CheckOutDoc2Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 8h1v6H8z" />
        <path
          d="M13.5 14H11v-1h2.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-10a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H6v1H3.5A1.5 1.5 0 0 1 2 12.5v-9A1.5 1.5 0 0 1 3.5 2h10A1.5 1.5 0 0 1 15 3.5v9a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M10.48 8.764L8.5 6.784l-1.98 1.98-.707-.707 2.333-2.334a.5.5 0 0 1 .707 0l2.333 2.333z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M41 43H28v-2h13a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h13v2H5a3 3 0 0 1-3-3V10a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M22 23h2v20h-2z" />
        <path
          d="M30.02 26.414l-6.3-6.128a1.036 1.036 0 0 0-1.434 0l-6.3 6.128-1.4-1.434 6.3-6.127a3.049 3.049 0 0 1 4.223 0l6.3 6.127z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 22h-6v-2h5.987c.013-.121.013-.293.013-.4V3.1a.1.1 0 0 0-.1-.1H2.1a.1.1 0 0 0-.1.1v16.5c0 .106 0 .279.013.4H8v2H2c-1.327 0-2-.808-2-2.4V3.1A2.1 2.1 0 0 1 2.1 1h19.8A2.1 2.1 0 0 1 24 3.1v16.5c0 1.592-.673 2.4-2 2.4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 9h2v12h-2z" />
        <path
          d="M16 11.414l-3.707-3.707c-.112-.111-.28-.28-.6.013L8 11.414 6.586 10l3.707-3.707a2.346 2.346 0 0 1 3.414 0L17.414 10z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CheckOutDoc2Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CheckOutDoc2Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default CheckOutDoc2Icon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SwitchList from "./SwitchList";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-switch--main";
    case "accent":
      return "em-c-switch--accent";
    case "action":
      return "em-c-switch--action";
    case "caution":
      return "em-c-switch--caution";
    case "negative":
      return "em-c-switch--negative";
    case "positive":
      return "em-c-switch--positive";
    case "default":
    default:
      return "";
  }
}

/** Switch Component is an on/off switch */
const Switch = props => {
  const { className, active, label, color, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-switch",
        { "em-is-active": active },
        getColor(color),
        className
      )}
      {...other}
    >
      <label className="em-c-switch__label" htmlFor="switch-1">
        <span className="em-c-switch__toggle" />
        {label && <span className="em-c-switch__label-name">{label}</span>}
      </label>
      <input type="checkbox" name="switch" className="em-c-switch__input" />
    </div>
  );
};

Switch.defaultProps = {
  active: false,
  className: "",
  label: "",
  color: "default"
};

Switch.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** If the switch is active or not */
  active: PropTypes.bool,
  /** Title describing the functionality of the switch */
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node
  ]),
  /** Background color */
  color: PropTypes.oneOf([
    "default",
    "main",
    "accent",
    "action",
    "caution",
    "negative",
    "positive"
  ])
};

Switch.List = SwitchList;

export default Switch;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BatteryHalfFullIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 12H14v-1h1V7h-1V6h1.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM12.5 14h-11A1.5 1.5 0 0 1 0 12.5v-7A1.5 1.5 0 0 1 1.5 4h11A1.5 1.5 0 0 1 14 5.5v7a1.5 1.5 0 0 1-1.5 1.5zm-11-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M2.5 6.5h5v5h-5z" />
        <path
          d="M7.5 12h-5a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM3 11h4V7H3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 39H3a3 3 0 0 1-3-3V14a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3zM3 13a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V14a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <rect
          x={5}
          y={16}
          width={18}
          height={18}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path
          d="M22 35H6a2 2 0 0 1-2-2V17a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zM6 17v16h16V17zM46 33h-2v-2h2V19h-2v-2h2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M3.5 8.5h8v8h-8z" />
        <path
          d="M11.5 17h-8a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM4 16h7V9H4z"
          fill="currentColor"
        />
        <path
          d="M19 20H2a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h17a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2zM2 7v11h17V7zM23 17h-2v-2h1v-5h-1V8h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BatteryHalfFullIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BatteryHalfFullIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BatteryHalfFullIcon;

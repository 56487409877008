import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const XFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M1.586 3L3 1.586 14.414 13 13 14.414z" />
        <path fill="currentColor" d="M1.586 13L13 1.586 14.414 3 3 14.414z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M11.879 14L14 11.88l22.121 22.12L34 36.122z"
        />
        <path
          fill="currentColor"
          d="M11.879 34L34 11.879 36.121 14l-22.12 22.121z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M4.878 7L7 4.879 19.12 17 17 19.121z" />
        <path fill="currentColor" d="M4.878 17L17 4.879 19.12 7 7 19.121z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

XFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

XFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default XFilledIcon;

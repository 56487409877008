import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CheckFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M6.449 12.216L2.943 8.711l1.414-1.414 2.092 2.091 5.692-5.692 1.414 1.414-7.106 7.106z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M18 35.606l-9.092-9.091 2.121-2.121L18 31.364l18.971-18.97 2.121 2.121L18 35.606z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M9.249 19.82l-5.606-5.606 2.121-2.121 3.485 3.485 9.485-9.485 2.121 2.121L9.249 19.82z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CheckFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CheckFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CheckFilledIcon;

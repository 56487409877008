import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import HorizontalListItem from "./HorizontalListItem";

/**
 * A display component for items that should appear side-by-side.
 *
 * @visibleName Horizontal list
 * */
const HorizontalList = props => {
  const { className, color, children, ...other } = props;

  return (
    <ul className={clsx("em-c-horizontal-list", className)} {...other}>
      {React.Children.toArray(children).map(c =>
        React.cloneElement(c, {
          color
        })
      )}
    </ul>
  );
};

HorizontalList.Item = HorizontalListItem;

HorizontalList.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the list items */
  color: PropTypes.oneOf(["default", "main", "accent"]),
  /** React component children */
  children: PropTypes.node
};

HorizontalList.defaultProps = {
  className: "",
  color: "default",
  children: null
};

export default HorizontalList;

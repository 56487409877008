import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ThumbsDownIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.5 10h-3a.5.5 0 0 1-.5-.5v-8A.5.5 0 0 1 .5 1h3a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM1 9h2V2H1zM14.5 9H14V8h.5a.5.5 0 0 0 0-1H13V6h1.5a1.5 1.5 0 0 1 0 3z"
          fill="currentColor"
        />
        <path
          d="M13.5 7H13V6h.5a.5.5 0 0 0 0-1H12V4h1.5a1.5 1.5 0 0 1 0 3z"
          fill="currentColor"
        />
        <path
          d="M12.5 5H12V4h.5a.5.5 0 0 0 0-1H6.81a5.492 5.492 0 0 0-1.71.271l-.9.295-.311-.951.905-.3A6.508 6.508 0 0 1 6.81 2h5.69a1.5 1.5 0 0 1 0 3zM8.421 16h-.207A1.216 1.216 0 0 1 7 14.786v-2l-1.643-2.743a2.341 2.341 0 0 0-.943-.884l-.445-.229.458-.889.444.229a3.336 3.336 0 0 1 1.345 1.26l1.713 2.855a.5.5 0 0 1 .071.258v2.143a.217.217 0 0 0 .214.214h.207a1.5 1.5 0 0 0 1.442-1.913l-.7-2.45a.5.5 0 0 1 .48-.637H14.5a.5.5 0 0 0 0-1H13V8h1.5a1.5 1.5 0 0 1 0 3h-4.194l.519 1.812A2.5 2.5 0 0 1 8.421 16z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M41 21h-1v-2h1a2 2 0 0 0 0-4h-2v-2h2a4 4 0 0 1 0 8z"
          fill="currentColor"
        />
        <path
          d="M42 27h-2v-2h2a2 2 0 0 0 0-4h-2v-2h2a4 4 0 0 1 0 8z"
          fill="currentColor"
        />
        <path
          d="M28.04 47A3.012 3.012 0 0 1 25 44v-5.723l-4.657-7.762a6.949 6.949 0 0 0-2.8-2.626l-.889-.458.917-1.778.889.458a8.941 8.941 0 0 1 3.6 3.378l4.8 8A1 1 0 0 1 27 38v6a1 1 0 0 0 1 1 5.005 5.005 0 0 0 4.857-6.368l-1.819-6.357A1 1 0 0 1 32 31h11a2 2 0 0 0 0-4h-1v-2h1a4 4 0 0 1 0 8h-9.674l1.455 5.085A7 7 0 0 1 28.04 47zM39 15h-1v-2h1a2 2 0 0 0 0-4H24.28a13.015 13.015 0 0 0-4.124.669l-2.8.912-.62-1.9 2.794-.911A15.007 15.007 0 0 1 24.28 7H39a4 4 0 0 1 0 8zM12 31H4a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3zM4 7a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M8 15a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 9h-1V7h1c1.5 0 1.5-.376 1.5-.5A.5.5 0 0 0 20 6h-1V4h1a2.5 2.5 0 0 1 2.5 2.5c0 1.141-.607 2.5-3.5 2.5z"
          fill="currentColor"
        />
        <path
          d="M21 12h-2v-2h2a.5.5 0 0 0 0-1h-1V7h1a2.5 2.5 0 0 1 0 5z"
          fill="currentColor"
        />
        <path
          d="M14.02 22A2.007 2.007 0 0 1 12 20v-2.764l-1.895-3.789a9.636 9.636 0 0 0-2.553-1.553l-.895-.447.895-1.789.895.447c1.991 1 3.011 1.708 3.413 2.384l2.035 4.063A1 1 0 0 1 14 17v3a2.055 2.055 0 0 0 1.642-.856 2.162 2.162 0 0 0 .4-1.871l-.986-2.956A1 1 0 0 1 16 13h5.5a.5.5 0 0 0 0-1h-1v-2h1a2.5 2.5 0 0 1 0 5h-4.113l.562 1.684a4.185 4.185 0 0 1-.711 3.666A4.029 4.029 0 0 1 14.02 22zM18 6h-1V4h1c1.5 0 1.5-.363 1.5-.5A.5.5 0 0 0 19 3h-7.36a22.737 22.737 0 0 0-3.314.945l-.945.326-.652-1.89.946-.326A20.026 20.026 0 0 1 11.64 1H19a2.5 2.5 0 0 1 2.5 2.5c0 1.141-.607 2.5-3.5 2.5z"
          fill="currentColor"
        />
        <path
          d="M6 14H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM2 2v10h4V2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ThumbsDownIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ThumbsDownIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ThumbsDownIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DownloadIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 15H.5a.5.5 0 0 1-.5-.5v-11A.5.5 0 0 1 .5 3H5v1H1v10h13V4h-4V3h4.5a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M7 0h1v9H7z" />
        <path
          d="M7.5 10a.5.5 0 0 1-.277-.084L3.807 7.639l.555-.832L7.5 8.9l3.139-2.092.555.832-3.417 2.276A.5.5 0 0 1 7.5 10z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 44H6a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h9v2H6a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V13a1 1 0 0 0-1-1h-9v-2h9a3 3 0 0 1 3 3v28a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 3.5h2v23h-2z" />
        <path
          d="M24 31a3.019 3.019 0 0 1-2.111-.849l-6.3-6.127 1.394-1.434 6.3 6.128a1.036 1.036 0 0 0 1.434 0l6.3-6.128 1.394 1.434-6.3 6.127A3.021 3.021 0 0 1 24 31z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.9 22H3.1A2.1 2.1 0 0 1 1 19.9V3.4C1 1.808 1.673 1 3 1h5v2H3.013C3 3.121 3 3.293 3 3.4v16.5a.1.1 0 0 0 .1.1h17.8a.1.1 0 0 0 .1-.1V3.4c0-.106 0-.279-.013-.4H16V1h5c1.327 0 2 .808 2 2.4v16.5a2.1 2.1 0 0 1-2.1 2.1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 2h2v12h-2z" />
        <path
          d="M11.956 17.449a2.314 2.314 0 0 1-1.663-.742L6.586 13 8 11.586l3.707 3.707c.112.112.282.28.6-.013L16 11.586 17.414 13l-3.707 3.707a2.562 2.562 0 0 1-1.751.742z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DownloadIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DownloadIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DownloadIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TileListItem = props => {
  const { className, children, ...other } = props;

  return (
    <li
      className={clsx("em-c-tile-list__item", "em-l-grid__item", className)}
      {...other}
    >
      {children}
    </li>
  );
};

TileListItem.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

TileListItem.defaultProps = {
  className: "",
  children: null
};

export default TileListItem;

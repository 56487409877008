import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FlagSimpleIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M2 1h1v14H2zM13.5 10H4V9h8.691l-1.638-3.276a.5.5 0 0 1 0-.447L12.691 2H4V1h9.5a.5.5 0 0 1 .447.724L12.059 5.5l1.888 3.776A.5.5 0 0 1 13.5 10z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M9 46a3 3 0 0 1-3-3V5a3 3 0 0 1 6 0v38a3 3 0 0 1-3 3zM9 4a1 1 0 0 0-1 1v38a1 1 0 0 0 2 0V5a1 1 0 0 0-1-1zM37.5 28H14v-2h21.678l-6.022-9.463a1 1 0 0 1 0-1.074L35.678 6H14V4h23.5a1 1 0 0 1 .844 1.537L31.686 16l6.658 10.463A1 1 0 0 1 37.5 28z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M6 24H5a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1a2 2 0 0 1 2 2v19a2 2 0 0 1-2 2zM5 3v19h1V3zM20 16H9v-2h9.131l-2.963-4.445a1 1 0 0 1 0-1.109L18.131 4H9V2h11a1 1 0 0 1 .832 1.555L17.2 9l3.63 5.445A1 1 0 0 1 20 16z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FlagSimpleIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FlagSimpleIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FlagSimpleIcon;

import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import clsx from "clsx";
import Field from "../../Field";

/** CheckboxGroup contains Checkboxs to encapsulate them in a list and handle Unity's css correctly. It also has the capability to disable all of its children at once. */
const CheckboxGroup = props => {
  const {
    label,
    message,
    valid,
    error,
    disabled,
    note,
    className,
    children,
    ...other
  } = props;

  return (
    <Field
      className={className}
      error={error}
      valid={valid}
      disabled={disabled}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <div
          className={clsx("em-c-option-list", {
            "em-has-error": error,
            "em-is-valid": !error
          })}
        >
          {React.Children.map(children, child =>
            React.cloneElement(child, {
              disabled,
              valid,
              error
            })
          )}
        </div>
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

CheckboxGroup.propTypes = {
  /** Label for checkbox list */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** warning if there is an error with validation */
  message: oneOfType([PropTypes.string, PropTypes.number]),
  /** If the CheckboxGroup is valid or not */
  valid: PropTypes.bool,
  /** If the CheckboxGroup is error state or not */
  error: PropTypes.bool,
  /** If the child Checkboxs are disabled or not */
  disabled: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case Checkboxs */
  children: PropTypes.node
};

CheckboxGroup.defaultProps = {
  label: "",
  message: "",
  valid: false,
  error: false,
  disabled: false,
  className: "",
  children: null
};

export default CheckboxGroup;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TriangleDownFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 11L4.052 5.351h7.896L8 11z" />
        <path
          d="M8 12.746l-5.867-8.4h11.734zm-2.029-6.4L8 9.254l2.029-2.9z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23.5 38.5L3 9.509h41L23.5 38.5z" />
        <path
          d="M23.5 41.1L.1 8.009h46.8zM5.9 11.009L23.5 35.9l17.6-24.891z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M12 17.616L3 4.888h18l-9 12.728z" />
        <path
          d="M12 20.214L.1 3.389h23.8zM5.9 6.389l6.1 8.63 6.1-8.63z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TriangleDownFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TriangleDownFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TriangleDownFilledIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TreeNavigationItem from "./TreeNavigationItem";
import TreeNavigationDropdown from "./TreeNavigationDropdown";

/**
 * A collapsible vertical menu for broad and deep navigation structures
 *
 * @visibleName Tree navigation
 * */
const TreeNavigation = props => {
  const { className, children, ...other } = props;

  return (
    <nav className={clsx("em-c-tree", className)} role="navigation" {...other}>
      <ul
        className="em-c-tree__list em-c-tree__list--flush"
        role="tablist"
        aria-multiselectable="true"
      >
        {children}
      </ul>
    </nav>
  );
};

TreeNavigation.propTypes = {
  /** children are the contents of the TreeNavigation */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TreeNavigation.defaultProps = {
  children: PropTypes.node,
  className: ""
};

TreeNavigation.Item = TreeNavigationItem;
TreeNavigation.Dropdown = TreeNavigationDropdown;

export default TreeNavigation;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PagerNext from "./PagerNext";
import PagerPrev from "./PagerPrev";

/** Pager gives you a component for a previous page / next page ux */
const Pager = props => {
  const { className, children, ...other } = props;

  return (
    <ol
      className={clsx("em-c-pagination", "em-c-pager", className)}
      role="navigation"
      {...other}
    >
      {children}
    </ol>
  );
};

Pager.propTypes = {
  /** children are the contents of the Pager */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Pager.defaultProps = {
  children: null,
  className: ""
};

Pager.Next = PagerNext;
Pager.Prev = PagerPrev;

export default Pager;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cssVars from "css-vars-ponyfill";
import ThemeContext from "../ThemeContext";
import { unityTheme as unityDefaultTheme, unityDarkTheme } from "../Themes";
import useBrowserPreference, {
  BrowserPreference
} from "./useBrowserPreference";

function ThemeProvider(props) {
  const { theme, browserDefault, children } = props;
  const browserPreference = useBrowserPreference(!browserDefault);
  const defaultTheme =
    browserPreference === BrowserPreference.light
      ? {
          ...unityDefaultTheme,
          ...theme
        }
      : {
          ...unityDarkTheme,
          ...theme
        };
  const [unityTheme, setUnityTheme] = useState(defaultTheme);

  // Every time that the theme changes, use the ponyfill to populate the new
  // css variables that also works for legacy browsers
  useEffect(() => renderThemeInDOM(unityTheme), [unityTheme]);
  useEffect(() => renderThemeInDOM(theme), [theme]);
  useEffect(() => renderThemeInDOM(defaultTheme), [browserPreference]);

  function renderThemeInDOM(newTheme, from) {
    cssVars({
      variables: getCSSProperties(newTheme)
    });
  }

  function setTheme(newTheme) {
    setUnityTheme({
      ...unityDefaultTheme,
      ...newTheme
    });
  }

  return (
    <ThemeContext.Provider value={{ theme: unityTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.defaultProps = {
  children: null,
  theme: {},
  browserDefault: false
};

ThemeProvider.propTypes = {
  /** Application components */
  children: PropTypes.node,
  /** Provided or custom theme object */
  theme: PropTypes.object,
  /** Indicates if the browser's light or dark preference should be used for the initial load. Legacy browsers will default to light mode. */
  browserDefault: PropTypes.bool
};

export default ThemeProvider;

/**
 * Converts an object of nested objects into one object where
 * the keys are camel cased:
 * @example { palette: { common: { black: "#000", white: "#fff"}}} -> { paletteCommonBlack: "#000", paletteCommonWhite: "#fff"}
 * @param {object} obj Theme object.
 * @param {string} prefix Prefix to the object's key.
 */
function getCSSProperties(obj, prefix = "") {
  let properties = {};

  function attr(key) {
    if (prefix) {
      return `${prefix}${key.toString()[0].toUpperCase()}${key.slice(1)}`;
    }
    return key;
  }

  for (const key in obj) {
    if (typeof obj[key] !== "object") {
      properties[attr(key)] = obj[key];
    } else {
      properties = {
        ...properties,
        ...getCSSProperties(obj[key], attr(key))
      };
    }
  }

  return properties;
}

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import BreadcrumbsItem from "./BreadcrumbsItem";

/** Indicate location and provide navigation through parent levels. */
const Breadcrumbs = props => {
  const { className, color, children, ...other } = props;

  return (
    <ol
      className={clsx("em-c-breadcrumbs", className)}
      role="navigation"
      {...other}
    >
      {React.Children.toArray(children).map(c =>
        React.cloneElement(c, {
          color
        })
      )}
    </ol>
  );
};

Breadcrumbs.propTypes = {
  /** children are the contents of the Breadcrumbs */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the text and arrows */
  color: PropTypes.oneOf(["blue", "main", "accent"])
};

Breadcrumbs.defaultProps = {
  className: "",
  children: [],
  color: "blue"
};

Breadcrumbs.Item = BreadcrumbsItem;

export default Breadcrumbs;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronRightFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M6.4 13.614L4.986 12.2l4.2-4.2-4.2-4.2L6.4 2.386 12.014 8 6.4 13.614z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M17 40.121L14.879 38l14-14-14-14L17 7.879 33.121 24 17 40.121z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M10 21.121L7.879 19l7-7-7-7L10 2.879 19.121 12 10 21.121z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronRightFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronRightFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronRightFilledIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getSize(size) {
  switch (size) {
    case "xsmall":
      return "em-c-avatar--xsmall";
    case "small":
      return "em-c-avatar--small";
    case "large":
      return "em-c-avatar--large";
    default:
      return "em-c-avatar--medium";
  }
}
/**
 * Avatar image for people
 *
 * @visibleName Profile image
 * */
const ProfileImage = props => {
  const { src, size, alt, className, ...other } = props;

  return (
    <div
      className={clsx("em-c-avatar", getSize(size), className)}
      style={{
        borderRadius: "50%",
        overflow: "hidden",
        background: `url(${src}`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
      alt={alt}
      {...other}
    />
  );
};

ProfileImage.propTypes = {
  /** the source (url) of the image to be displayed */
  src: PropTypes.string,
  /** the size of the profile image */
  size: PropTypes.oneOf(["xsmall", "small", "medium", "large"]),
  /** alternate text for the image */
  alt: PropTypes.string,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

ProfileImage.defaultProps = {
  src: "https://unity.exxonmobil.com/images/sample/avatar-nick.png",
  size: "medium",
  alt: "Profile Image.",
  className: ""
};

export default ProfileImage;

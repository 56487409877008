import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowHorizontalCapsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M2.5 8h11v1h-11z" />
        <path
          d="M3.026 11.783L.466 9.046a.878.878 0 0 1-.012-1.155l2.572-2.75.73.683-2.468 2.638L3.757 11.1zM12.974 11.783l-.73-.683 2.469-2.638-2.47-2.638.73-.683 2.561 2.737a.878.878 0 0 1 .012 1.155z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M7 23h34v2H7z" />
        <path
          d="M9.586 33.414l-7.293-7.293a3 3 0 0 1 0-4.243l7.293-7.292L11 16l-7.293 7.293a1 1 0 0 0 0 1.414L11 32zM38.586 33.414L37.172 32l7.293-7.293a1 1 0 0 0 0-1.414L37.172 16l1.414-1.414 7.293 7.293a3 3 0 0 1 0 4.242z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 17.414L17.586 16l4-4-4-4L19 6.586l4 4a2 2 0 0 1 0 2.828zM5 17.414l-4-4a2 2 0 0 1 0-2.828l4-4L6.414 8l-4 4 4 4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M5 11h14v2H5z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowHorizontalCapsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowHorizontalCapsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowHorizontalCapsIcon;

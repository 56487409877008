import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const WarningIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.087 15H3.914a2.5 2.5 0 0 1-2.207-3.677l4.587-8.6a2.5 2.5 0 0 1 4.412 0l4.587 8.6A2.5 2.5 0 0 1 13.087 15zM2.59 11.794A1.5 1.5 0 0 0 3.914 14h9.173a1.5 1.5 0 0 0 1.323-2.206l-4.587-8.6a1.5 1.5 0 0 0-2.646 0z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 5h1v5H8z" />
        <circle cx={8.5} cy={11.75} r={0.75} fill="#112" />
        <path
          d="M8.5 11a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42.058 46H7.942a4.95 4.95 0 0 1-4.356-7.278l17.058-32.1a4.929 4.929 0 0 1 8.712 0l17.058 32.1A4.951 4.951 0 0 1 42.058 46zM5.352 39.659A2.95 2.95 0 0 0 7.942 44h34.116a2.95 2.95 0 0 0 2.59-4.34L27.59 7.562a2.929 2.929 0 0 0-5.18 0L5.352 39.66z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M24 16h2v17h-2zM25 36a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 25 36z"
        />
        <circle cx={25} cy={37} r={0.667} fill="currentColor" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.918 24H3.292a3.343 3.343 0 0 1-2.828-1.642 3 3 0 0 1-.13-2.966l.011-.02 9.331-16.75A2.808 2.808 0 0 1 12.1 1a2.821 2.821 0 0 1 2.44 1.642l9.321 16.731a3 3 0 0 1-.12 2.985A3.341 3.341 0 0 1 20.918 24zM2.1 20.335a1.01 1.01 0 0 0 .08.991A1.352 1.352 0 0 0 3.292 22h17.626a1.352 1.352 0 0 0 1.114-.674 1.01 1.01 0 0 0 .075-1l-9.32-16.731c-.4-.761-.973-.739-1.353-.02z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 8h2v9h-2z" />
        <circle cx={12} cy={19} r={1} fill="#112" />
        <path d="M12 18a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

WarningIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

WarningIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default WarningIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileCodeIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h6.349a1.491 1.491 0 0 1 1.063.441L13.563 4.1A1.49 1.49 0 0 1 14 5.163V13.5a1.5 1.5 0 0 1-1.5 1.5zm-9-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5.163a.5.5 0 0 0-.146-.353L10.2 2.147A.5.5 0 0 0 9.849 2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M7.83 9.98h3.64v1H7.83zM5.69 11.187l-.707-.707 1.98-1.98-1.98-1.98.707-.707 2.333 2.333a.5.5 0 0 1 0 .707z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 46H10a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h20.343a4.967 4.967 0 0 1 3.535 1.464l7.657 7.657A4.967 4.967 0 0 1 43 16.657V43a3 3 0 0 1-3 3zM10 6a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V16.657a3.022 3.022 0 0 0-.878-2.121l-7.658-7.658A3.022 3.022 0 0 0 30.343 6z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M25 30h10v2H25zM17.524 32.414l-1.438-1.391 5.129-5.3a1.042 1.042 0 0 0 0-1.437l-5.129-5.305 1.438-1.39 5.128 5.305a3.058 3.058 0 0 1 0 4.218z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11 15h6v2h-6zM8 16.414L6.586 15l3.5-3.5-3.5-3.5L8 6.586l3.707 3.707a1.54 1.54 0 0 1 0 2.414z"
        />
        <path
          d="M19.875 24H4.125A2.115 2.115 0 0 1 2 21.9V2.1A2.115 2.115 0 0 1 4.125 0h10.318a3.258 3.258 0 0 1 2.29.929l4.307 4.212a3.2 3.2 0 0 1 .96 2.27V21.9a2.115 2.115 0 0 1-2.125 2.1zM4.125 2A.116.116 0 0 0 4 2.1v19.8a.116.116 0 0 0 .125.1h15.75a.116.116 0 0 0 .125-.1V7.411a1.167 1.167 0 0 0-.358-.84l-4.307-4.212A1.287 1.287 0 0 0 14.443 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileCodeIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileCodeIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileCodeIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** FieldBody is the content for a field on a form. */
const FieldBody = props => {
  const { className, stretch, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-field__body",
        { "em-c-field__body--stretch": stretch },
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

FieldBody.propTypes = {
  /** children is the contents */
  children: PropTypes.node,
  /** Takes up the remaining space */
  stretch: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

FieldBody.defaultProps = {
  children: null,
  className: "",
  stretch: false
};

export default FieldBody;

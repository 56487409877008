import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DatabaseIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M6 8h1v2H6zM6 5h1v2H6zM6 11h1v2H6z" />
        <path
          d="M8.5 15C5.851 15 3 14.374 3 13V4c0-1.374 2.851-2 5.5-2s5.5.626 5.5 2v9c0 1.374-2.851 2-5.5 2zM4 13c.085.279 1.584 1 4.5 1s4.425-.73 4.5-1.007V4c-.075-.27-1.575-1-4.5-1S4.075 3.73 4 4.007zm0 0z"
          fill="currentColor"
        />
        <path
          d="M8.5 6C5.851 6 3 5.374 3 4h1c.075.27 1.575 1 4.5 1s4.425-.73 4.5-1.007L14 4c0 1.374-2.851 2-5.5 2zM8.5 9C5.851 9 3 8.374 3 7h1c.075.27 1.575 1 4.5 1s4.425-.73 4.5-1.007L14 7c0 1.374-2.851 2-5.5 2zM8.5 12C5.851 12 3 11.374 3 10h1c.075.27 1.575 1 4.5 1s4.425-.73 4.5-1.007L14 10c0 1.374-2.851 2-5.5 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M25 25c-5.88 0-17-1.045-17-5v-1h2v1c0 .871 5.256 3 15 3s15-2.129 15-3v-1h2v1c0 3.955-11.12 5-17 5zM25 35c-5.88 0-17-1.045-17-5v-1h2v1c0 .871 5.256 3 15 3s15-2.129 15-3v-1h2v1c0 3.955-11.12 5-17 5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M18 23h2v6h-2zM18 14h2v6h-2zM18 33h2v6h-2z"
        />
        <path
          d="M25 45c-5.88 0-17-1.045-17-5V10c0-3.955 11.12-5 17-5s17 1.045 17 5v30c0 3.955-11.12 5-17 5zm0-38c-9.744 0-15 2.129-15 3v30c0 .871 5.256 3 15 3s15-2.129 15-3V10c0-.871-5.256-3-15-3z"
          fill="currentColor"
        />
        <path
          d="M25 16c-5.88 0-17-1.045-17-5v-1h2v1c0 .871 5.256 3 15 3s15-2.129 15-3v-1h2v1c0 3.955-11.12 5-17 5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 23c-2.111 0-9-.216-9-3V4.105C3 1.087 11.079 1 12 1s9 .087 9 3.105V20c0 2.784-6.889 3-9 3zm-7-3.146c.486.43 2.867 1.146 7 1.146s6.514-.716 7-1.146V4.242C18.527 3.791 16.085 3 12 3s-6.527.791-7 1.242z"
          fill="currentColor"
        />
        <path
          d="M12 8c-3.343 0-9-.737-9-3.5S8.657 1 12 1s9 .737 9 3.5S15.343 8 12 8zM5.027 4.5C5.392 4.976 7.753 6 12 6s6.608-1.024 6.973-1.5C18.608 4.024 16.247 3 12 3S5.392 4.024 5.027 4.5zm13.983.059zM12 13c-1.5 0-9-.145-9-3V9h2v.833C5.518 10.262 7.97 11 12 11s6.482-.738 7-1.167V9h2v1c0 2.855-7.5 3-9 3zM12 18c-1.5 0-9-.145-9-3v-1h2v.833C5.518 15.262 7.97 16 12 16s6.482-.738 7-1.167V14h2v1c0 2.855-7.5 3-9 3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 11h2v4H8zM8 6h2v4H8zM8 16h2v4H8z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DatabaseIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DatabaseIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DatabaseIcon;

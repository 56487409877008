import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-horizontal-list__item--main";
    case "accent":
      return "em-c-horizontal-list__item--accent";
    case "default":
    default:
      return "";
  }
}

const HorizontalListItem = props => {
  const { className, color, children, ...other } = props;

  return (
    <li
      className={clsx("em-c-horizontal-list__item", getColor(color), className)}
      {...other}
    >
      {children}
    </li>
  );
};

HorizontalListItem.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the list items */
  color: PropTypes.oneOf(["default", "main", "accent"]),
  /** React component children */
  children: PropTypes.node
};

HorizontalListItem.defaultProps = {
  className: "",
  color: "default",
  children: null
};

export default HorizontalListItem;

import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import PaginationItem from "./PaginationItem";
import PaginationFirst from "./PaginationFirst";
import PaginationLast from "./PaginationLast";

/**Navigation for multiple pages or data sets */
const Pagination = props => {
  const {
    initialPage,
    maxPages,
    pages,
    color,
    onChange,
    className,
    children,
    ...other
  } = props;

  const [currentPage, setCurrentPage] = useState(initialPage || 1);
  const [baselinePage, setBaselinePage] = useState(initialPage || 1);

  const changeBaselineHandler = (page, baseline) => {
    setCurrentPage(page);
    setBaselinePage(baseline);
    onChange(page, pages[page - 1]);
  };

  const changeHandler = page => {
    setCurrentPage(page);
    onChange(page, pages[page - 1]);
  };

  const pagesToRender = pages.map((page, index) => {
    if (index == baselinePage - 2) {
      return (
        <PaginationItem
          href={page}
          key={page}
          active={index + 1 == currentPage}
          color={color}
          onChange={() =>
            changeBaselineHandler(index + 1, baselinePage - maxPages)
          }
        >
          ...
        </PaginationItem>
      );
    }
    if (index + 1 >= baselinePage && index + 1 < baselinePage + maxPages) {
      return (
        <PaginationItem
          href={page}
          key={page}
          active={index + 1 == currentPage}
          color={color}
          onChange={() => changeHandler(index + 1)}
        >
          {index + 1}
        </PaginationItem>
      );
    }
    if (index + 1 == baselinePage + maxPages) {
      return (
        <PaginationItem
          href={page}
          key={page}
          active={index + 1 == currentPage}
          color={color}
          onChange={() =>
            changeBaselineHandler(index + 1, baselinePage + maxPages)
          }
        >
          ...
        </PaginationItem>
      );
    }
  });

  const first = pages ? pages[0] : "";
  const last = pages ? pages[pages.length - 1] : "";

  if (pages && pages.length > 0) {
    return (
      <ol
        className={clsx("em-c-pagination", className)}
        role="navigation"
        aria-labelledby="pagination-label"
        {...other}
      >
        <PaginationFirst
          href={first}
          disabled={currentPage == 1}
          color={color}
          onChange={() => changeBaselineHandler(1, 1)}
        />
        {pagesToRender}
        <PaginationLast
          href={last}
          disabled={currentPage == pages.length}
          color={color}
          onChange={() =>
            changeBaselineHandler(pages.length, pages.length - maxPages + 1)
          }
        />
      </ol>
    );
  }
  return (
    <ol
      className={clsx("em-c-pagination", className)}
      role="navigation"
      aria-labelledby="pagination-label"
      {...other}
    >
      {children}
    </ol>
  );
};

Pagination.Item = PaginationItem;

Pagination.First = PaginationFirst;

Pagination.Last = PaginationLast;

Pagination.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** initialPage is the current page (starting at 1 not 0) */
  initialPage: PropTypes.number,
  /** max number of pages the paginator will show before adding ellipses */
  maxPages: PropTypes.number,
  /** pages is an array of hrefs you should pass in to generate the pagination */
  pages: PropTypes.arrayOf(PropTypes.string),
  /** Theme color of the buttons */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** children are the contents of the Pagination */
  children: PropTypes.node,
  /** onChange emits the page number when it is changed */
  onChange: PropTypes.func
};

Pagination.defaultProps = {
  initialPage: 1,
  maxPages: 3,
  pages: [],
  onChange: () => {},
  children: null,
  className: "",
  color: "blue"
};

export default Pagination;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DefinitionListItemValue = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-definition-list__value", className)} {...other}>
      {children}
    </div>
  );
};

DefinitionListItemValue.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

DefinitionListItemValue.defaultProps = {
  className: "",
  children: null
};

export default DefinitionListItemValue;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TableBodyGroupedRowContext } from "../TableBodyGroupedRow/TableBodyGroupedRow.jsx";
import CaretRightIcon from "../../../Icons/CaretRight.jsx";
import CaretDownIcon from "../../../Icons/CaretDown.jsx";

/** TableBodyCell is where you put data in the table body */
const TableBodyCell = props => {
  const { className, dropdown, icon, extraPadding, children, ...other } = props;
  const { open } = useContext(TableBodyGroupedRowContext);

  function DropdownIcon() {
    if (icon) {
      return icon;
    }

    if (open) {
      return <CaretDownIcon className="em-c-table__cell--icon" size="small" />;
    }
    return <CaretRightIcon className="em-c-table__cell--icon" size="small" />;
  }

  return (
    <td
      colSpan=""
      className={clsx(
        "em-c-table__cell",
        "em-js-cell",
        "em-js-cell-editable",
        {
          "em-c-table__cell--dropdown em-js-collapse-trigger": dropdown,
          "em-c-table__cell--padding": extraPadding
        },
        className
      )}
      {...other}
    >
      {dropdown ? (
        <div className="em-c-table__cell--container">
          <DropdownIcon />
          {children}
        </div>
      ) : (
        children
      )}
    </td>
  );
};

TableBodyCell.propTypes = {
  /** children are the contents of the TableBodyCell */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Use with non-grouped rows when they are in the same table with grouped-rows */
  extraPadding: PropTypes.bool,
  /** Indicates that this cell contains the dropdown icon for a grouped row */
  dropdown: PropTypes.bool,
  /** Icon to use for the dropdown. Defaults to the Caret Arrows */
  icon: PropTypes.node
};

TableBodyCell.defaultProps = {
  children: null,
  className: "",
  extraPadding: false,
  dropdown: false,
  icon: null
};

export default TableBodyCell;

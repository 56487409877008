import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** ToolbarItem are the items that position elemtns in the toolbar, can be on the left or right */
const ToolbarItem = props => {
  const { right, className, stretch, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-toolbar__item",
        {
          "em-is-aligned-right": right,
          "em-c-toolbar__item--stretch": stretch
        },
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

ToolbarItem.propTypes = {
  /** whether or not the toolbar item will be right aligned, default left aligned */
  right: PropTypes.bool,
  /** children are the contents of the ToolbarItem */
  children: PropTypes.node,
  /** Takes up the remaining space */
  stretch: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

ToolbarItem.defaultProps = {
  right: false,
  children: null,
  className: "",
  stretch: false
};

export default ToolbarItem;

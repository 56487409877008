import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HamburgerIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M1 3h14v2H1zM1 7h14v2H1zM1 11h14v2H1z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M2 11h44v2H2zM2 23h44v2H2zM2 35h44v2H2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M0 5h23v2H0zM0 11h23v2H0zM0 17h23v2H0z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HamburgerIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HamburgerIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HamburgerIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DeviceMobileIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 15h-6A1.5 1.5 0 0 1 4 13.5v-11A1.5 1.5 0 0 1 5.5 1h6A1.5 1.5 0 0 1 13 2.5v11a1.5 1.5 0 0 1-1.5 1.5zm-6-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-11a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M10.5 11h-4a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5zM7 10h3V4H7z"
          fill="currentColor"
        />
        <circle cx={8.5} cy={12.5} r={0.5} fill="currentColor" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M34 46H14a4 4 0 0 1-4-4V6a4 4 0 0 1 4-4h20a4 4 0 0 1 4 4v36a4 4 0 0 1-4 4zM14 4a2 2 0 0 0-2 2v36a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          d="M32 36H16a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v26a2 2 0 0 1-2 2zM16 8v26h16V8zM24 43a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18 24H6a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2zM6 2v20h12V2z"
          fill="currentColor"
        />
        <path
          d="M16 18H8a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1zm-7-2h6V5H9z"
          fill="currentColor"
        />
        <circle cx={12} cy={20} r={1} fill="#112" />
        <path d="M12 19a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DeviceMobileIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DeviceMobileIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DeviceMobileIcon;

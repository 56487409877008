import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PasteIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M9.5 4h-4a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM6 3h3V2H6zM14 6h-1V3h-2V2h2.5a.5.5 0 0 1 .5.5zM6 14H1.5a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H4v1H2v10h4zM8 8h5v1H8zM8 10h5v1H8zM8 12h5v1H8z"
          fill="currentColor"
        />
        <path
          d="M14.5 15h-8a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM7 14h7V7H7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M29 14H15a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2zM15 6v6h14V6zM41 16h-2v-5a1 1 0 0 0-1-1h-5V8h5a3 3 0 0 1 3 3zM22 42H6a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h5v2H6a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h16zM28 34h14v2H28zM28 30h14v2H28zM28 26h14v2H28zM28 22h14v2H28zM28 38h14v2H28z"
          fill="currentColor"
        />
        <path
          d="M44 44H26a2 2 0 0 1-2-2V20a2 2 0 0 1 2-2h18a2 2 0 0 1 2 2v22a2 2 0 0 1-2 2zM26 20v22h18V20z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14 7H7a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM8 5h5V3H8zM21 8h-2V5h-3V3h3a2 2 0 0 1 2 2zM8 21H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3v2H2v14h6zM12 11h8v2h-8zM12 14h8v2h-8zM12 17h8v2h-8z"
          fill="currentColor"
        />
        <path
          d="M22 22H10a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1zm-11-2h10V10H11z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PasteIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PasteIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PasteIcon;

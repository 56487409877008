import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FileMultipleIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 14h-8A1.5 1.5 0 0 1 3 12.5v-10A1.5 1.5 0 0 1 4.5 1h5.15a1.491 1.491 0 0 1 1.058.437l2.851 2.837A1.51 1.51 0 0 1 14 5.337V12.5a1.5 1.5 0 0 1-1.5 1.5zm-8-12a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5V5.337a.5.5 0 0 0-.147-.354L10 2.146A.5.5 0 0 0 9.65 2z"
          fill="currentColor"
        />
        <path
          d="M12 16H2.5A1.5 1.5 0 0 1 1 14.5V3h1v11.5a.5.5 0 0 0 .5.5H12z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 42H11a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h18.343a4.968 4.968 0 0 1 3.535 1.465l7.657 7.656A4.968 4.968 0 0 1 42 14.657V39a3 3 0 0 1-3 3zM11 4a1 1 0 0 0-1 1v34a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1V14.657a2.982 2.982 0 0 0-.878-2.122l-7.658-7.656A3.02 3.02 0 0 0 29.343 4z"
          fill="currentColor"
        />
        <path
          d="M36 46H9a5.006 5.006 0 0 1-5-5V8h2v33a3 3 0 0 0 3 3h27z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path d="M19 24H4a2 2 0 0 1-2-2V4h2v18h15z" fill="currentColor" />
        <path
          d="M21 21H7a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h9.171a2.98 2.98 0 0 1 2.122.879l3.414 3.414A4.515 4.515 0 0 1 23 6.829V19a2 2 0 0 1-2 2zM7 2v17h14V6.829a3.387 3.387 0 0 0-.707-1.122l-3.414-3.414A.994.994 0 0 0 16.171 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FileMultipleIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FileMultipleIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FileMultipleIcon;

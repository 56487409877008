import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ShareIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 15h-12a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5H5v1H2v8h11V6h-3V5h3.5a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M7 1h1v9H7z" />
        <path
          d="M10.639 3.193L7.5 1.1 4.361 3.193l-.555-.832L7.223.084a.5.5 0 0 1 .555 0l3.416 2.277z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M24 6.5h2v23h-2z" />
        <path
          d="M32.02 10.414l-6.3-6.128a1.038 1.038 0 0 0-1.435 0l-6.3 6.127-1.399-1.433 6.3-6.128a3.053 3.053 0 0 1 4.222 0l6.3 6.129zM41 45H9a3 3 0 0 1-3-3V20a3 3 0 0 1 3-3h11v2H9a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h32a1 1 0 0 0 1-1V20a1 1 0 0 0-1-1H30v-2h11a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19.9 24H4.1A2.1 2.1 0 0 1 2 21.9V11.4C2 9.808 2.673 9 4 9h4v2H4.013C4 11.121 4 11.294 4 11.4v10.5a.1.1 0 0 0 .1.1h15.8a.1.1 0 0 0 .1-.1V11.4c0-.1 0-.271-.012-.393L16 11V9h4c1.327 0 2 .808 2 2.4v10.5a2.1 2.1 0 0 1-2.1 2.1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 5h2v12h-2z" />
        <path
          d="M17 6.414l-3.707-3.707a2.073 2.073 0 0 0-2.586 0L7 6.414 5.586 5l3.707-3.707a4.03 4.03 0 0 1 5.414 0L18.414 5z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ShareIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ShareIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ShareIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArrowSubdirectoryLeftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.045 13h-6.34v-1h6.34a.161.161 0 0 0 .16-.16V2.5h1v9.34a1.162 1.162 0 0 1-1.16 1.16z"
          fill="currentColor"
        />
        <path
          d="M5.786 15.913l-2.56-2.737a.878.878 0 0 1-.012-1.155l2.572-2.75.73.683-2.468 2.638 2.469 2.638z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M38 38H15v-2h23a1 1 0 0 0 1-1V6h2v29a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M17.586 46.414l-7.293-7.293a3 3 0 0 1 0-4.243l7.293-7.292L19 29l-7.293 7.293a1 1 0 0 0 0 1.414L19 45z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path d="M18 19H7v-2h11V2h2v15a2 2 0 0 1-2 2z" fill="currentColor" />
        <path
          d="M7 23.414l-4-4a2 2 0 0 1 0-2.828l4-4L8.414 14l-4 4 4 4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArrowSubdirectoryLeftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArrowSubdirectoryLeftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArrowSubdirectoryLeftIcon;

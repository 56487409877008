import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import StackedBlockBody from "./StackedBlockBody";
import StackedBlockHeader from "./StackedBlockHeader";
import StackedBlockFooter from "./StackedBlockFooter";

function getVariant(variant) {
  switch (variant) {
    case "large":
      return "em-c-stacked-block--large";
    default:
      return "";
  }
}

/**
 * Promotional link in a stacked format of image, headline, description and footer
 *
 * @visibleName Stacked block
 * */
const StackedBlock = props => {
  const { variant, className, children, ...other } = props;

  return (
    <div
      className={clsx("em-c-stacked-block", getVariant(variant), className)}
      {...other}
    >
      {children}
    </div>
  );
};

StackedBlock.Body = StackedBlockBody;
StackedBlock.Header = StackedBlockHeader;
StackedBlock.Footer = StackedBlockFooter;

StackedBlock.propTypes = {
  /** Size of the block */
  variant: PropTypes.oneOf(["default", "large"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

StackedBlock.defaultProps = {
  className: "",
  children: null
};

export default StackedBlock;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MicrophoneIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 11h-4A1.5 1.5 0 0 1 5 9.5v-7A1.5 1.5 0 0 1 6.5 1h4A1.5 1.5 0 0 1 12 2.5v7a1.5 1.5 0 0 1-1.5 1.5zm-4-9a.5.5 0 0 0-.5.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M11.5 13h-6A2.5 2.5 0 0 1 3 10.5V8h1v2.5A1.5 1.5 0 0 0 5.5 12h6a1.5 1.5 0 0 0 1.5-1.5V8h1v2.5a2.5 2.5 0 0 1-2.5 2.5zM3 14h11v1H3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 12h1v3H8zM7 3h3v1H7zM7 5h3v1H7z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M28 32h-8a5.006 5.006 0 0 1-5-5V7a5.006 5.006 0 0 1 5-5h8a5.006 5.006 0 0 1 5 5v20a5.006 5.006 0 0 1-5 5zM20 4a3 3 0 0 0-3 3v20a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3z"
          fill="currentColor"
        />
        <path
          d="M30 38H18a9.01 9.01 0 0 1-9-9v-5h2v5a7.008 7.008 0 0 0 7 7h12a7.008 7.008 0 0 0 7-7v-5h2v5a9.01 9.01 0 0 1-9 9zM9 44h30v2H9z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M23 36h2v10h-2zM19 6h10v2H19zM19 10h10v2H19zM19 14h10v2H19zM19 18h10v2H19z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14 17h-4a3 3 0 0 1-3-3V4a3 3 0 0 1 3-3h4a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3zM10 3a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M15 20H9a5.006 5.006 0 0 1-5-5v-3h2v3a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3v-3h2v3a5.006 5.006 0 0 1-5 5zM4 22h16v2H4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 18h2v6h-2zM9 6h6v2H9zM9 10h6v2H9z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MicrophoneIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MicrophoneIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MicrophoneIcon;

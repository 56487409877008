import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const WifiIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.608 13.385L8 13.993l-.608-.608a.853.853 0 0 1 1.215 0zM11.038 10.955L9.823 12.17a2.559 2.559 0 0 0-3.646 0l-1.215-1.215a4.265 4.265 0 0 1 6.076 0z"
          fill="currentColor"
        />
        <path
          d="M13.483 8.51L12.26 9.732a6 6 0 0 0-8.521 0L2.517 8.51a7.744 7.744 0 0 1 10.965 0z"
          fill="currentColor"
        />
        <path
          d="M15.92 6.073L14.7 7.295a9.458 9.458 0 0 0-13.4 0L.08 6.073a11.189 11.189 0 0 1 15.84 0z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M26 39.18L24 42l-2-2.82A2.5 2.5 0 0 1 24 38a2.5 2.5 0 0 1 2 1.18zM33 32.091L29.4 36a7.167 7.167 0 0 0-10.8 0L15 32.091a11.946 11.946 0 0 1 18 0z"
          fill="currentColor"
        />
        <path
          d="M41 24.505L37.21 28A19.4 19.4 0 0 0 24 22.929 19.4 19.4 0 0 0 10.79 28L7 24.505A24.928 24.928 0 0 1 24 18a24.928 24.928 0 0 1 17 6.505z"
          fill="currentColor"
        />
        <path
          d="M48 17.2L44.318 21A28.171 28.171 0 0 0 3.7 21L0 17.2a33.327 33.327 0 0 1 48 0z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 20l-1 1-1-1c.216-.216.666-1 1-1s.784.784 1 1zM16.219 15.952l-1.687 1.688a3.554 3.554 0 0 0-5.063 0l-1.688-1.688a5.923 5.923 0 0 1 8.439 0z"
          fill="currentColor"
        />
        <path
          d="M19.615 12.557l-1.7 1.7a8.339 8.339 0 0 0-11.834 0l-1.7-1.7a10.755 10.755 0 0 1 15.229 0z"
          fill="currentColor"
        />
        <path
          d="M23 9.171l-1.688 1.7a13.136 13.136 0 0 0-18.615 0L1 9.171a15.541 15.541 0 0 1 22 0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

WifiIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

WifiIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default WifiIcon;

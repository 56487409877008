import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HeroBlockBody = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-hero__body", className)} {...other}>
      {children}
    </div>
  );
};

HeroBlockBody.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

HeroBlockBody.defaultProps = {
  className: "",
  children: null
};

export default HeroBlockBody;

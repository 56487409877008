import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BuildingIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 16h-8a.5.5 0 0 1-.5-.5v-14a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5zM5 15h7V2H5z"
          fill="currentColor"
        />
        <path
          d="M5 16H2.5a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5H4v1H3v4h2zM14.5 16H12v-1h2v-4h-1v-1h1.5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM9 3h2v1H9zM9 5h2v1H9zM9 7h2v1H9zM9 9h2v1H9zM9 11h2v1H9zM6 3h2v1H6zM6 5h2v1H6zM6 7h2v1H6zM6 9h2v1H6zM6 11h2v1H6zM8 13h1v2H8z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M8 13h1v2H8z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37 47H11a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v44a1 1 0 0 1-1 1zm-25-2h24V3H12zM9 47H5a1 1 0 0 1-1-1V30a1 1 0 0 1 1-1h4v2H6v14h3zM43 47h-4v-2h3V31h-3v-2h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M28 47h-2v-8h-4v8h-2v-9a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1zM22 11h-7a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5V7h-5zM22 19h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM22 27h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM22 35h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM33 11h-7a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5V7h-5zM33 19h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM33 27h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5zM33 35h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 24H5a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v22a1 1 0 0 1-1 1zM6 22h12V2H6zM3 24H1a1 1 0 0 1-1-1v-9a1 1 0 0 1 1-1h2v2H2v7h1zM23 24h-2v-2h1v-7h-1v-2h2a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M13 4h4v2h-4zM7 4h4v2H7zM13 8h4v2h-4zM7 8h4v2H7zM13 12h4v2h-4zM7 12h4v2H7zM15 22h-2v-4h-2v4H9v-6h6v6z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BuildingIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BuildingIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BuildingIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PlusFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M7 3h2v10H7z" />
        <path fill="currentColor" d="M3 7h10v2H3z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 11h3v27h-3z" />
        <path fill="currentColor" d="M11 23h27v3H11z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 5h3v15h-3z" />
        <path fill="currentColor" d="M5 11h15v3H5z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PlusFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PlusFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PlusFilledIcon;

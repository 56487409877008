import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CopyIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 15h-7a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h4.714a.5.5 0 0 1 .34.133l2.286 2.118a.5.5 0 0 1 .16.367V14.5a.5.5 0 0 1-.5.5zM9 14h6V8.836L13.018 7H9z"
          fill="currentColor"
        />
        <path
          d="M7 14H1.5a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5h7.333a.5.5 0 0 1 .354.146l3.021 3.02-.707.707L8.626 2H2v11h5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M20.775 42H6.829A2.868 2.868 0 0 1 4 39.1V6.9A2.868 2.868 0 0 1 6.829 4H24a5.468 5.468 0 0 1 3.663 1.409l8.749 9.661-1.482 1.343-8.73-9.641A3.513 3.513 0 0 0 24 6H6.829A.867.867 0 0 0 6 6.9v32.2a.867.867 0 0 0 .829.9h13.946z"
          fill="currentColor"
        />
        <path
          d="M42.825 44h-17.65A2.177 2.177 0 0 1 23 41.825v-21.65A2.177 2.177 0 0 1 25.175 18H37a3.635 3.635 0 0 1 2.522 1l4.52 4.61A3.423 3.423 0 0 1 45 26v15.825A2.177 2.177 0 0 1 42.825 44zm-17.65-24a.175.175 0 0 0-.175.175v21.65a.175.175 0 0 0 .175.175h17.65a.175.175 0 0 0 .175-.175V26a1.453 1.453 0 0 0-.386-.986L38.095 20.4A1.669 1.669 0 0 0 37 20z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22.321 22h-9.642A1.68 1.68 0 0 1 11 20.321V9.679A1.68 1.68 0 0 1 12.679 8h6.2a2.351 2.351 0 0 1 1.6.627l2.768 2.564a2.365 2.365 0 0 1 .753 1.73v7.4A1.68 1.68 0 0 1 22.321 22zM13 20h9v-7.079a.358.358 0 0 0-.114-.262l-2.768-2.564a.357.357 0 0 0-.242-.095H13z"
          fill="currentColor"
        />
        <path
          d="M9 22H3a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9.17a4.514 4.514 0 0 1 2.537 1.292L16.414 5 15 6.414l-1.707-1.707A3.23 3.23 0 0 0 12.146 4H3v16h6z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CopyIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CopyIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CopyIcon;

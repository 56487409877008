import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ExclamationFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M7 1h2v8H7z" />
        <circle cx={8} cy={12} r={1.25} fill="currentColor" />
        <path
          d="M8 10.75A1.25 1.25 0 1 0 9.25 12 1.25 1.25 0 0 0 8 10.75z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M23 8h3v23h-3z" />
        <circle cx={24.5} cy={38} r={2} fill="currentColor" />
        <path
          d="M24.5 37a1 1 0 1 1-1 1 1 1 0 0 1 1-1m0-1a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 3h3v12h-3z" />
        <circle cx={12.5} cy={19} r={2} fill="currentColor" />
        <path
          d="M12.5 18a1 1 0 1 1-1 1 1 1 0 0 1 1-1m0-1a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ExclamationFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ExclamationFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ExclamationFilledIcon;

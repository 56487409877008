import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ToolbarItem from "./ToolbarItem";

/** Toolbar is a bar that can often hold useful features for an item or page, such as sorting/filtering/searching/etc. */
const Toolbar = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-toolbar", className)} {...other}>
      {children}
    </div>
  );
};

Toolbar.propTypes = {
  /** children are the contents of the Toolbar */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Toolbar.defaultProps = {
  children: null,
  className: ""
};

Toolbar.Item = ToolbarItem;

export default Toolbar;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { QuoteLeftIcon } from "../Icons";
import PullquoteCitation from "./PullquoteCitation";
import PullquoteText from "./PullquoteText";

/** A decorative design element to call out important text and give readers’s eyes visual breaks from long passages of text. */
const Pullquote = props => {
  const { className, children, ...other } = props;

  return (
    <blockquote className={clsx("em-c-pullquote", className)} {...other}>
      <QuoteLeftIcon size="large" className="em-c-pullquote__icon" />
      {children}
    </blockquote>
  );
};

Pullquote.propTypes = {
  /** children are the contents of the pullquote */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Pullquote.defaultProps = {
  children: PropTypes.node,
  className: ""
};

Pullquote.Text = PullquoteText;
Pullquote.Citation = PullquoteCitation;

export default Pullquote;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronDownFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 12.014L2.386 6.4 3.8 4.986l4.2 4.2 4.2-4.2L13.614 6.4 8 12.014z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M24 33.121L7.879 17 10 14.879l14 14 14-14L40.121 17 24 33.121z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M12 19.121L2.879 10 5 7.879l7 7 7-7L21.121 10 12 19.121z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronDownFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronDownFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronDownFilledIcon;

import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import clsx from "clsx";
import Field from "../../Field";

/** RadioGroup is a group of radios . */
const RadioGroup = props => {
  const {
    label,
    note,
    error,
    valid,
    disabled,
    children,
    className,
    ...other
  } = props;

  return (
    <Field
      className={className}
      error={error}
      valid={valid}
      disabled={disabled}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <div
          className={clsx("em-c-option-list", {
            "em-has-error": error,
            "em-is-valid": !error
          })}
        >
          {children}
        </div>
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

RadioGroup.propTypes = {
  /** Label for checkbox list */
  label: oneOfType([PropTypes.string, PropTypes.number]),
  /** note to go below the field */
  note: oneOfType([PropTypes.string, PropTypes.number]),
  /** If the RadioGroup is error state or not */
  error: PropTypes.bool,
  /** If the RadioGroup is valid or not */
  valid: PropTypes.bool,
  /** If the child Radios are disabled or not */
  disabled: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case Radios */
  children: PropTypes.node
};

RadioGroup.defaultProps = {
  label: "",
  note: "",
  error: false,
  valid: false,
  disabled: false,
  className: "",
  children: null
};

export default RadioGroup;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import SolidCardBodyKicker from "./SolidCardBodyKicker";
import SolidCardBodyTitle from "./SolidCardBodyTitle";
import SolidCardBodyIcon from "./SolidCardBodyIcon";

const SolidCardBody = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-solid-card__body", className)} {...other}>
      {children}
    </div>
  );
};

SolidCardBody.Kicker = SolidCardBodyKicker;
SolidCardBody.Title = SolidCardBodyTitle;
SolidCardBody.Icon = SolidCardBodyIcon;

SolidCardBody.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

SolidCardBody.defaultProps = {
  className: "",
  children: null
};

export default SolidCardBody;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** NavDropdownItem is for drop down menus off of the primary nav. */
const HorizontalHeaderNavDropdownItem = props => {
  const { className, children, ...other } = props;

  return (
    <li className="em-c-primary-nav__subitem" {...other}>
      <div className={clsx("em-c-primary-nav__sublink", className)}>
        {children}
      </div>
    </li>
  );
};

HorizontalHeaderNavDropdownItem.propTypes = {
  /** Text shown  */
  text: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of the component */
  children: PropTypes.node
};

HorizontalHeaderNavDropdownItem.defaultProps = {
  text: "link",
  className: "",
  children: null
};

export default HorizontalHeaderNavDropdownItem;

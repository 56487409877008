import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const EditIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 15h-13a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H8v1H2v10h12v-4h1v4.5a.5.5 0 0 1-.5.5zM13.829 6.793a.5.5 0 0 1-.354-.146l-2.121-2.122a.5.5 0 0 1 0-.707L13.475 1.7a.5.5 0 0 1 .707 0L16.3 3.818a.5.5 0 0 1 0 .707l-2.118 2.121a.5.5 0 0 1-.353.147zm-1.415-2.622l1.415 1.415 1.414-1.415-1.414-1.414z"
          fill="currentColor"
        />
        <path
          d="M7.111 11.389a.5.5 0 0 1-.5-.544l.207-2.328a.5.5 0 0 1 .145-.31l4.743-4.743.707.707L7.8 8.786l-.138 1.552 1.552-.138 4.615-4.615.707.707-4.744 4.744a.5.5 0 0 1-.31.145l-2.328.207z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M41 44H7a3 3 0 0 1-3-3V13a3 3 0 0 1 3-3h19v2H7a1 1 0 0 0-1 1v28a1 1 0 0 0 1 1h34a1 1 0 0 0 1-1V24h2v17a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M25.757 32H20a1 1 0 0 1-1-1v-5.757a1 1 0 0 1 .293-.707L38.94 4.889a3.038 3.038 0 0 1 4.292 0l2.878 2.878a3.038 3.038 0 0 1 0 4.292L26.464 31.707a1 1 0 0 1-.707.293zM21 30h4.343L44.7 10.646a1.036 1.036 0 0 0 0-1.464L41.818 6.3a1.038 1.038 0 0 0-1.464 0L21 25.657z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M33.941 10.709l1.35-1.35 6.504 6.503-1.35 1.35z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 22H3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h10v2H3v14h17v-9h2v9a2 2 0 0 1-2 2zM20.9 7.927a1 1 0 0 1-.707-.293l-2.827-2.829a1 1 0 0 1 0-1.414l2.121-2.121a2 2 0 0 1 2.829 0l1.414 1.414a2 2 0 0 1 0 2.829l-2.121 2.121a1 1 0 0 1-.709.293zM19.487 4.1L20.9 5.513 22.316 4.1 20.9 2.684z"
          fill="currentColor"
        />
        <path
          d="M12.829 16H10a1 1 0 0 1-1-1v-2.829a1 1 0 0 1 .293-.707l8.28-8.28L18.987 4.6 11 12.585V14h1.415L20.4 6.013l1.414 1.414-8.28 8.28a1 1 0 0 1-.705.293z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

EditIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

EditIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default EditIcon;

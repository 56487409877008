import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MegaphoneIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 12.286a.5.5 0 0 1-.186-.036C11.3 11.044 6.364 9.092 5.97 8.989L4 9a2 2 0 0 1 0-4h2.067c.3-.092 5.23-2.044 8.247-3.25a.5.5 0 0 1 .686.464v9.571a.5.5 0 0 1-.5.5zM4 6a1 1 0 0 0 0 2h2.067c.119 0 .3 0 7.933 3.047V2.953C6.365 6 6.187 6 6.067 6z"
          fill="currentColor"
        />
        <path
          d="M7.661 14.111a.5.5 0 0 1-.354-.146L3.56 10.217l.707-.707 3.394 3.39.565-.566-2.084-2.081.707-.707 2.438 2.438a.5.5 0 0 1 0 .707l-1.272 1.274a.5.5 0 0 1-.354.146z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 38a.992.992 0 0 1-.375-.073l-18.994-7.69A6.025 6.025 0 0 0 20.947 30H13a8 8 0 0 1 0-16h7.947a5.989 5.989 0 0 0 1.765-.266l18.913-7.661A1 1 0 0 1 43 7v30a1 1 0 0 1-1 1zM13 16a6 6 0 0 0 0 12h7.947a8.009 8.009 0 0 1 2.353.354L41 35.517V8.484l-17.619 7.133a8.032 8.032 0 0 1-2.434.383z"
          fill="currentColor"
        />
        <path
          d="M24.639 44.5a3.831 3.831 0 0 1-2.731-1.135L11.086 32.5l1.414-1.414 10.824 10.867a1.843 1.843 0 0 0 1.314.547 1.845 1.845 0 0 0 1.314-.547 1.884 1.884 0 0 0 0-2.652l-6.774-6.8 1.422-1.415 6.775 6.8a3.888 3.888 0 0 1 0 5.474 3.832 3.832 0 0 1-2.735 1.14z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21 20a1 1 0 0 1-.485-.126L11.741 15H6c-2.484 0-5-1.374-5-4 0-2.485 1.374-5 4-5h5c.106 0 .289.008.506.018.278.013.888.041 1.16.023l8.849-4.916A1 1 0 0 1 22 2v17a1 1 0 0 1-1 1zM5 8c-1.468 0-2 1.794-2 3 0 1.467 1.794 2 3 2h6a1 1 0 0 1 .485.126L20 17.3V3.7l-7.515 4.174a.944.944 0 0 1-.191.082 6.276 6.276 0 0 1-1.881.06A10.06 10.06 0 0 0 10 8z"
          fill="currentColor"
        />
        <path
          d="M12 22.452a2.42 2.42 0 0 1-1.707-.745L5.586 17 7 15.586l4.707 4.707a.477.477 0 0 0 .293.159.477.477 0 0 0 .293-.159.351.351 0 0 0 0-.586L9.586 17 11 15.586l2.707 2.707a2.336 2.336 0 0 1 0 3.414 2.42 2.42 0 0 1-1.707.745z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MegaphoneIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MegaphoneIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MegaphoneIcon;

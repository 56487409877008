import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DownloadThickerIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15 16H1a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h5v2H2v9h12V5h-4V3h5a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M7 0h2v9H7z" />
        <path
          d="M8 13a1 1 0 0 1-.707-.293L3.586 9 5 7.586l3 3 3-3L12.414 9l-3.707 3.707A1 1 0 0 1 8 13z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      ""
    ) : (
      ""
    )}
  </div>

  /* eslint-enable */
);

DownloadThickerIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DownloadThickerIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DownloadThickerIcon;

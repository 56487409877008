import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StackedBlockHeaderLinkImage = props => {
  const { src, className, children, ...other } = props;

  return (
    <img
      src={src}
      className={clsx("em-c-stacked-block__img", className)}
      {...other}
    />
  );
};

StackedBlockHeaderLinkImage.propTypes = {
  /** Source of the image */
  src: PropTypes.string,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string
};

StackedBlockHeaderLinkImage.defaultProps = {
  src: "",
  className: ""
};

export default StackedBlockHeaderLinkImage;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TextPassageIntro from "./TextPassageIntro";

/**
 * Required container to activate styles for any body text
 *
 * @visibleName Text passage
 * */
const TextPassage = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-text-passage", className)} {...other}>
      {children}
    </div>
  );
};

TextPassage.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TextPassage.defaultProps = {
  className: ""
};

TextPassage.Intro = TextPassageIntro;

export default TextPassage;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const User1Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 8A3.5 3.5 0 1 1 12 4.5 3.5 3.5 0 0 1 8.5 8zm0-6A2.5 2.5 0 1 0 11 4.5 2.5 2.5 0 0 0 8.5 2zM14.5 16h-12a.5.5 0 0 1-.5-.5v-2a6.445 6.445 0 0 1 1.669-4.335L4 8.794l.741.671-.335.371A5.449 5.449 0 0 0 3 13.5V15h11v-1.5a5.449 5.449 0 0 0-1.411-3.665l-.335-.371.746-.67.335.371A6.445 6.445 0 0 1 15 13.5v2a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 23a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8z"
          fill="currentColor"
        />
        <path
          d="M39 45H9a3 3 0 0 1-3-3v-3a17.981 17.981 0 0 1 9.549-15.882l.882-.471.941 1.765-.882.471A15.984 15.984 0 0 0 8 39v3a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1v-3a15.9 15.9 0 0 0-4.687-11.313 15.451 15.451 0 0 0-3.794-2.8l-.887-.461.923-1.774.887.461a17.451 17.451 0 0 1 4.286 3.16A17.881 17.881 0 0 1 42 39v3a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.5 12A5.5 5.5 0 1 1 17 6.5a5.507 5.507 0 0 1-5.5 5.5zm0-9A3.5 3.5 0 1 0 15 6.5 3.5 3.5 0 0 0 11.5 3z"
          fill="currentColor"
        />
        <path
          d="M19 23H4a2 2 0 0 1-2-2v-1.5c0-4.644 1.932-6.584 3.553-7.395l.895-.447.895 1.789-.895.447C4.823 14.706 4 16.593 4 19.5V21h15v-1.5c0-2.154-.916-4.84-2.447-5.605l-.895-.447.895-1.789.895.447C19.78 13.271 21 16.708 21 19.5V21a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

User1Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

User1Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default User1Icon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StarHalfIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M8 .63v10.74l-4.568 3.319L5.175 9.65.609 6h5.646L8 .63z"
        />
        <path
          d="M12.568 15.688a.5.5 0 0 1-.294-.1L8 12.488l-4.274 3.1a.5.5 0 0 1-.77-.559l1.632-5.023L.315 6.9A.5.5 0 0 1 .609 6h5.283L7.524.976a.52.52 0 0 1 .951 0L10.108 6h5.283a.5.5 0 0 1 .294.9l-4.274 3.106 1.632 5.023a.5.5 0 0 1-.476.654zM8 11.37a.5.5 0 0 1 .294.1l3.323 2.414-1.269-3.91a.5.5 0 0 1 .182-.559L13.853 7H9.745a.5.5 0 0 1-.476-.346L8 2.748 6.731 6.654A.5.5 0 0 1 6.255 7H2.147l3.324 2.415a.5.5 0 0 1 .182.559l-1.27 3.906 3.323-2.414A.5.5 0 0 1 8 11.37z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M24 3v32.085L10.403 45l5.189-15.054L2 19.043h16.807L24 3z"
        />
        <path
          d="M37.906 46a1 1 0 0 1-.581-.186L24 36.313l-13.325 9.5a1 1 0 0 1-1.53-1.129l5.08-15.343L.919 19.857a1 1 0 0 1 .581-1.814h16.466l5.085-15.357a1 1 0 0 1 1.9 0l5.084 15.357H46.5a1 1 0 0 1 .581 1.814l-13.306 9.486 5.08 15.343A1 1 0 0 1 37.906 46zM24 34.085a1 1 0 0 1 .581.186l11.414 8.139-4.35-13.138a1 1 0 0 1 .369-1.129l11.361-8.1H29.312a1 1 0 0 1-.949-.686L24 6.182l-4.362 13.175a1 1 0 0 1-.949.686H4.625l11.361 8.1a1 1 0 0 1 .369 1.129l-4.35 13.138 11.414-8.139a1 1 0 0 1 .581-.186z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M11.599 2.004v15.402l-6.55 4.759 2.499-7.226L1 9.705h8.097l2.502-7.701z"
        />
        <path
          d="M5.049 23.165a1 1 0 0 1-.949-1.309l2.277-7.011-5.965-4.331A1 1 0 0 1 1 8.705h7.371L10.648 1.7A1 1 0 0 1 11.6 1a1 1 0 0 1 .951.691l2.277 7.01H22.2a1 1 0 0 1 .588 1.809l-5.963 4.332 2.275 7.014a1 1 0 0 1-1.539 1.117L11.6 18.642l-5.963 4.332a1 1 0 0 1-.588.191zm-.971-12.46l4.061 2.95a1 1 0 0 1 .361 1.117l-1.549 4.775 4.061-2.95a1 1 0 0 1 1.176 0l4.061 2.95-1.549-4.775a1 1 0 0 1 .363-1.117l4.061-2.95H14.1a1 1 0 0 1-.951-.691L11.6 5.24l-1.551 4.773a1 1 0 0 1-.951.691z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

StarHalfIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

StarHalfIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default StarHalfIcon;

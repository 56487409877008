import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SendIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M1.5 14a.5.5 0 0 1-.474-.658L2.973 7.5 1.026 1.658a.5.5 0 0 1 .684-.612l13 6a.5.5 0 0 1 0 .908l-13 6A.493.493 0 0 1 1.5 14zm.84-11.562l1.634 4.9a.494.494 0 0 1 0 .316l-1.634 4.9L13.307 7.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M5 7h10v1H5z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M3 43a1 1 0 0 1-.914-1.406L9.906 24 2.086 6.406a1 1 0 0 1 1.308-1.325l42 18a1 1 0 0 1 0 1.838l-42 18A1 1 0 0 1 3 43zM4.949 7.923l6.964 15.671a1 1 0 0 1 0 .813l-6.964 15.67L42.461 24z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M15 23h30v2H15z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M1 22a1 1 0 0 1-.9-1.428l4.5-9.513L.1 1.544A1 1 0 0 1 1.41.2l22 9.884a1 1 0 0 1 0 1.822l-22 10A.992.992 0 0 1 1 22zM3.065 3.141l3.548 7.49a1 1 0 0 1 0 .855l-3.54 7.473L20.572 11z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M9 10h12v2H9z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SendIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SendIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SendIcon;

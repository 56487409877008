import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

export const TableBodyGroupedRowContext = React.createContext({
  open: false,
  hasGroupedRows: false
});

function TableBodyGroupedRow(props) {
  const { className, open, cells, children, ...other } = props;
  const [isOpen, setIsOpen] = useState(open);

  function showChildren() {
    if (open !== undefined) {
      return open;
    }
    return isOpen;
  }

  return (
    <TableBodyGroupedRowContext.Provider
      value={{ open: showChildren(), hasGroupedRows: true }}
    >
      <tr
        className={clsx("em-c-table__row", "em-js-table-row-parent", className)}
        onClick={() => setIsOpen(prev => !prev)}
        {...other}
      >
        {cells}
      </tr>

      {showChildren() && children}
    </TableBodyGroupedRowContext.Provider>
  );
}

TableBodyGroupedRow.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Indicates if the row is open to show its children */
  open: PropTypes.bool,
  /** Parent row that can clicked to expand its children */
  cells: PropTypes.node,
  /** children are the contents of the component */
  children: PropTypes.node
};

TableBodyGroupedRow.defaultProps = {
  className: "",
  open: undefined,
  cells: null,
  children: []
};

export default TableBodyGroupedRow;

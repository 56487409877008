import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FolderSharedIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M7 10h1v3H7z" />
        <circle cx={10.5} cy={8.245} r={0.75} fill="currentColor" />
        <circle cx={7.5} cy={8.245} r={0.75} fill="currentColor" />
        <path fill="currentColor" d="M5 10h8v1H5z" />
        <path fill="currentColor" d="M10 10h1v3h-1z" />
        <path
          d="M14.5 15h-11A1.5 1.5 0 0 1 2 13.5v-10A1.5 1.5 0 0 1 3.5 2h2.715a1.506 1.506 0 0 1 .691.168l1.494.776A.5.5 0 0 0 8.629 3H13.5A1.5 1.5 0 0 1 15 4.5V5h-1v-.5a.5.5 0 0 0-.5-.5H8.629a1.506 1.506 0 0 1-.691-.168l-1.493-.776A.5.5 0 0 0 6.215 3H3.5a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5V6H4V5h11.5a.5.5 0 0 1 .5.5v8a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M13 30h2v6h-2zM24.5 28a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM14.5 28a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM33.5 28a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5zM23 30h2v6h-2z"
        />
        <path fill="currentColor" d="M9 29h30v2H9z" />
        <path fill="currentColor" d="M33 30h2v6h-2z" />
        <path
          d="M42 44H6a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h11.2a2.982 2.982 0 0 1 1.7.526l4.814 3.3a.991.991 0 0 0 .565.176H39.5a3 3 0 0 1 3 3v1h-2V13a1 1 0 0 0-1-1H24.272a2.98 2.98 0 0 1-1.7-.526l-4.814-3.3A.993.993 0 0 0 17.2 8H6a1 1 0 0 0-1 1v32a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V18a1 1 0 0 0-1-1H7v-2h35a3 3 0 0 1 3 3v23a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M6 14h2v4H6zM12 13a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM7 13a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM17 13a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM11 14h2v4h-2z"
        />
        <path fill="currentColor" d="M4 14h16v2H4z" />
        <path fill="currentColor" d="M16 14h2v4h-2z" />
        <path
          d="M21.889 21H2.1A2.1 2.1 0 0 1 0 18.9V2.111A2.114 2.114 0 0 1 2.111 0h5.424A3.572 3.572 0 0 1 9.35.566l1.95 1.353A9.7 9.7 0 0 0 12.943 2H20.9a2.005 2.005 0 0 1 2.047 2.053v1h-2v-1L12.943 4a5.1 5.1 0 0 1-2.778-.437L8.212 2.211A1.646 1.646 0 0 0 7.535 2H2.111A.115.115 0 0 0 2 2.111V18.9a.1.1 0 0 0 .1.1h19.789a.115.115 0 0 0 .111-.111V8.1a.1.1 0 0 0-.1-.1H3V6h18.9A2.1 2.1 0 0 1 24 8.1v10.789A2.114 2.114 0 0 1 21.889 21z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FolderSharedIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FolderSharedIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FolderSharedIcon;

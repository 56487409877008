import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HeartLineIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 16a.5.5 0 0 1-.277-.084C7.407 15.706 0 10.719 0 6.5a4.5 4.5 0 0 1 8-2.826A4.5 4.5 0 0 1 16 6.5c0 4.219-7.407 9.206-7.723 9.416A.5.5 0 0 1 8 16zM4.5 3A3.5 3.5 0 0 0 1 6.5c0 2.93 4.81 6.876 7 8.394 1.328-.935 7-5.122 7-8.394a3.5 3.5 0 0 0-6.562-1.689.521.521 0 0 1-.877 0A3.49 3.49 0 0 0 4.5 3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a1 1 0 0 1-.5-.132 58.734 58.734 0 0 1-10.7-8.278C5.735 29.692 2 22.227 2 15a12 12 0 0 1 22-6.637A12 12 0 0 1 46 15c0 7.227-3.735 14.692-10.8 21.59a58.734 58.734 0 0 1-10.7 8.278A1 1 0 0 1 24 45zM14 5A10.011 10.011 0 0 0 4 15c0 14.783 16.865 25.9 20 27.833C27.135 40.9 44 29.783 44 15a10 10 0 0 0-19.091-4.172 1 1 0 0 1-1.818 0A10.028 10.028 0 0 0 14 5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 23a1 1 0 0 1-.6-.2C10.976 22.481 1 14.92 1 8a5.68 5.68 0 0 1 6-6 6.54 6.54 0 0 1 5 2.166A6.54 6.54 0 0 1 17 2a5.68 5.68 0 0 1 6 6c0 6.92-9.976 14.481-10.4 14.8a1 1 0 0 1-.6.2zM7 4a3.675 3.675 0 0 0-4 4c0 5.046 6.9 11.022 9 12.726 2.1-1.7 9-7.68 9-12.726a3.675 3.675 0 0 0-4-4 4.292 4.292 0 0 0-4.089 2.413 1 1 0 0 1-1.822 0A4.292 4.292 0 0 0 7 4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HeartLineIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HeartLineIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HeartLineIcon;

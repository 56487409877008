import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FactoryIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 15h-13a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5V5H4V2H2v12h12V4.076l-4.208 3.03A.5.5 0 0 1 9 6.7V3.979L5.19 7.2l-.646-.763 4.633-3.918A.5.5 0 0 1 10 2.9v2.824l4.208-3.03A.5.5 0 0 1 15 3.1v11.4a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          d="M13 13h-1v-3h-2v3H9V9.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 .5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M45 46H3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v9h-2V4H4v40h40V8.869L27.555 19.832A1 1 0 0 1 26 19V8.157L12.714 19.409l-1.292-1.526L26.354 5.237A1 1 0 0 1 28 6v11.131L44.445 6.168A1 1 0 0 1 46 7v38a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M41 43h-2V30h-8v13h-2V29a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1zM15 34H8a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2H9zM26 34h-7a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-6-2h5v-2h-5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 24H2a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5H6V2H3v20h18V5.869l-7.445 4.963A1 1 0 0 1 12 10V6.414l-5 5L5.586 10l6.707-6.707A1 1 0 0 1 14 4v4.131l7.445-4.963A1 1 0 0 1 23 4v19a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M19 23h-2v-7h-3v7h-2v-8a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FactoryIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FactoryIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FactoryIcon;

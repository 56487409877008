import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FaceSmileBigIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M6.5 8A1.5 1.5 0 1 1 8 6.5 1.5 1.5 0 0 1 6.5 8zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM10.5 8A1.5 1.5 0 1 1 12 6.5 1.5 1.5 0 0 1 10.5 8zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM8.5 14a4.582 4.582 0 0 1-4.46-4.354.5.5 0 0 1 .211-.409c.221-.158.4-.091.709.016a11.285 11.285 0 0 0 3.54.727 11.285 11.285 0 0 0 3.539-.727c.3-.106.488-.173.709-.016a.5.5 0 0 1 .211.409A4.582 4.582 0 0 1 8.5 14zm-3.362-3.63A3.58 3.58 0 0 0 8.5 13a3.58 3.58 0 0 0 3.362-2.63 11.323 11.323 0 0 1-3.362.61 11.323 11.323 0 0 1-3.362-.61z"
          fill="currentColor"
        />
        <path
          d="M8.5 16A7.5 7.5 0 1 1 16 8.5 7.508 7.508 0 0 1 8.5 16zm0-14A6.5 6.5 0 1 0 15 8.5 6.508 6.508 0 0 0 8.5 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          d="M17 23a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM31 23a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM24 39.5c-7.168 0-13-5.38-13-11.993a1.011 1.011 0 0 1 .43-.824c.457-.318.791-.2 1.685.133A33.37 33.37 0 0 0 24 29.146a33.37 33.37 0 0 0 10.885-2.33c.894-.33 1.228-.453 1.685-.133a1.011 1.011 0 0 1 .43.824C37 34.12 31.168 39.5 24 39.5zM13.113 28.943C13.882 33.775 18.471 37.5 24 37.5s10.118-3.725 10.887-8.557A33.9 33.9 0 0 1 24 31.146a33.9 33.9 0 0 1-10.887-2.203z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.5 24A11.5 11.5 0 1 1 23 12.5 11.513 11.513 0 0 1 11.5 24zm0-21a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 11.5 3z"
          fill="currentColor"
        />
        <path
          d="M8 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM15 11a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-2zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zm0 0zM11.5 19.5c-3.86 0-7-2.81-7-6.263a1 1 0 0 1 .423-.818c.436-.31.815-.177 1.295-.007A16.874 16.874 0 0 0 11.5 13.5a16.874 16.874 0 0 0 5.282-1.088c.48-.169.86-.3 1.295.007a1 1 0 0 1 .423.818c0 3.453-3.14 6.263-7 6.263zm-4.69-4.786A5.038 5.038 0 0 0 11.5 17.5a5.038 5.038 0 0 0 4.69-2.786 16.7 16.7 0 0 1-4.69.786 16.7 16.7 0 0 1-4.69-.786z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FaceSmileBigIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FaceSmileBigIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FaceSmileBigIcon;

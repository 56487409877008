import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TagsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 12H3.909a.5.5 0 0 1-.188-.037L.312 10.578A.5.5 0 0 1 0 10.115v-6.23a.5.5 0 0 1 .312-.463l3.409-1.385A.5.5 0 0 1 3.909 2H15.5a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5zM4.007 11H15V3H4.007L1 4.222v5.556z"
          fill="currentColor"
        />
        <path
          d="M6 9a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM14.013 15.643a.5.5 0 0 1-.146-.021l-6.172-1.89.293-.956 5.694 1.743.509-1.662.956.292-.655 2.141a.5.5 0 0 1-.478.354z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M44 35H12.385a3 3 0 0 1-1.112-.213l-8.387-3.355A2.987 2.987 0 0 1 1 28.646V13.354a2.987 2.987 0 0 1 1.886-2.786l8.385-3.354A3 3 0 0 1 12.385 7H44a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3zM12.385 9a1 1 0 0 0-.373.072l-8.383 3.354a1 1 0 0 0-.629.928v15.292a1 1 0 0 0 .629.929l8.385 3.354a1 1 0 0 0 .371.071H44a1 1 0 0 0 1-1V10a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M15 27a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM38.6 44.5a2.951 2.951 0 0 1-.9-.14l-16.462-5.21.6-1.906 16.462 5.21a.961.961 0 0 0 .744-.069 1.062 1.062 0 0 0 .52-.648l1.251-4.224 1.917.568-1.25 4.224a3.053 3.053 0 0 1-1.5 1.847 2.956 2.956 0 0 1-1.382.348z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 19H6.192a2 2 0 0 1-.74-.142l-4.2-1.679A1.989 1.989 0 0 1 0 15.323V7.677A1.988 1.988 0 0 1 1.258 5.82L5.45 4.143A2.01 2.01 0 0 1 6.192 4H22a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2zM6.192 6L2 7.678v7.646L6.193 17H22V6z"
          fill="currentColor"
        />
        <path
          d="M7.5 15a3.5 3.5 0 1 1 3.5-3.5A3.5 3.5 0 0 1 7.5 15zm0-5A1.5 1.5 0 1 0 9 11.5 1.5 1.5 0 0 0 7.5 10zM19.192 22.944a2 2 0 0 1-.589-.089l-13.85-4.187.579-1.915 13.854 4.189.774-2.517 1.913.586-.773 2.518a2 2 0 0 1-1.909 1.415z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TagsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TagsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TagsIcon;

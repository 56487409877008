import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FaceSadBigIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.977 8.511l-.447-.895 1.532-.766-1.533-.767.447-.895L7.4 6.4a.5.5 0 0 1 0 .895zM11.868 8.511L9.44 7.3a.5.5 0 0 1 0-.895l2.428-1.214.447.895-1.533.767 1.533.766zM10.911 12.534a1.222 1.222 0 0 1-.221-.021A12.709 12.709 0 0 0 8.5 12.3a12.709 12.709 0 0 0-2.19.213 1.161 1.161 0 0 1-.945-2.04A5.228 5.228 0 0 1 8.5 9.32a5.228 5.228 0 0 1 3.136 1.153 1.161 1.161 0 0 1-.725 2.061zM8.5 11.3a13.623 13.623 0 0 1 2.374.23.15.15 0 0 0 .175-.092.144.144 0 0 0-.042-.188 4.244 4.244 0 0 0-2.507-.93 4.244 4.244 0 0 0-2.506.93.16.16 0 0 0 .132.28A13.623 13.623 0 0 1 8.5 11.3z"
          fill="currentColor"
        />
        <path
          d="M8.5 16A7.5 7.5 0 1 1 16 8.5 7.508 7.508 0 0 1 8.5 16zm0-14A6.5 6.5 0 1 0 15 8.5 6.508 6.508 0 0 0 8.5 2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 45a21 21 0 1 1 21-21 21.024 21.024 0 0 1-21 21zm0-40a19 19 0 1 0 19 19A19.022 19.022 0 0 0 24 5z"
          fill="currentColor"
        />
        <path
          d="M13.553 23.342l-.895-1.789L17.764 19l-5.106-2.553.895-1.789 6.895 3.447a1 1 0 0 1 0 1.789zM33.447 23.342l-6.895-3.447a1 1 0 0 1 0-1.789l6.895-3.447.895 1.789L29.236 19l5.106 2.553zM16.536 35.5a3.006 3.006 0 0 1-2.729-1.765 3.062 3.062 0 0 1 .813-3.711A15.465 15.465 0 0 1 24 26.5a15.463 15.463 0 0 1 9.38 3.523 3.061 3.061 0 0 1 .813 3.711 3.007 3.007 0 0 1-3.31 1.709A39.234 39.234 0 0 0 24 34.761a39.244 39.244 0 0 0-6.885.682 3.092 3.092 0 0 1-.579.057zm7.464-7a13.38 13.38 0 0 0-8.1 3.063 1.064 1.064 0 0 0-.279 1.319 1.006 1.006 0 0 0 1.125.6A40.754 40.754 0 0 1 24 32.761a40.745 40.745 0 0 1 7.258.717 1 1 0 0 0 1.126-.6 1.064 1.064 0 0 0-.279-1.319A13.38 13.38 0 0 0 24 28.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M6.723 13.387l-1.11-1.664L8.2 10 5.613 8.277l1.11-1.664 3.832 2.555a1 1 0 0 1 0 1.664zM16.277 13.387l-3.832-2.555a1 1 0 0 1 0-1.664l3.832-2.555 1.109 1.664L14.8 10l2.584 1.723zM7.825 19.016a2.165 2.165 0 0 1-1.936-1.328 2.021 2.021 0 0 1 .481-2.464A8.773 8.773 0 0 1 11.5 13.5a8.776 8.776 0 0 1 5.13 1.723 2.021 2.021 0 0 1 .481 2.464 2.118 2.118 0 0 1-2.294 1.295l-.505-.1A16.835 16.835 0 0 0 11.5 18.5a16.835 16.835 0 0 0-2.812.388l-.505.1a1.947 1.947 0 0 1-.358.028zM11.5 15.5a6.686 6.686 0 0 0-3.87 1.277c.027.021.138.213.217.242l.467-.1A18.123 18.123 0 0 1 11.5 16.5a18.123 18.123 0 0 1 3.186.423l.5.094a.52.52 0 0 0 .146-.273A6.723 6.723 0 0 0 11.5 15.5z"
          fill="currentColor"
        />
        <path
          d="M11.5 24A11.5 11.5 0 1 1 23 12.5 11.513 11.513 0 0 1 11.5 24zm0-21a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 11.5 3z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FaceSadBigIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FaceSadBigIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default FaceSadBigIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SunCloudIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M10 .5h1v2h-1zM5.904 2.61l.707-.707 1.415 1.414-.708.708zM14.5 6h2v1h-2zM12.974 3.318l1.415-1.414.707.707-1.414 1.414zM12.4 9.441l-.539-.841A2.474 2.474 0 0 0 13 6.5a2.5 2.5 0 0 0-4.866-.8l-.46-.155H7.16V5.54a.494.494 0 0 1 .026-.16A3.5 3.5 0 1 1 12.4 9.441zM10.5 15h-7a2.5 2.5 0 0 1-.377-4.972 4 4 0 0 1 7.755 0A2.5 2.5 0 0 1 10.5 15zM7 8a3.007 3.007 0 0 0-2.965 2.57.5.5 0 0 1-.5.43 1.5 1.5 0 1 0-.04 3h7a1.5 1.5 0 0 0 0-3 .542.542 0 0 1-.535-.43A3.007 3.007 0 0 0 7 8z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M30 3h2v6h-2zM17.293 7.707l1.415-1.414 4 4-1.415 1.414zM44 19h4v2h-4zM39.293 12.292l4-4 1.414 1.414-4 4zM13.788 32.174l-1.962-.385.192-.981a8.8 8.8 0 0 1 3.959-5.665 8 8 0 0 1 6.265-1.112l.971.243-.485 1.94-.97-.243a6.02 6.02 0 0 0-4.707.86 6.8 6.8 0 0 0-3.069 4.363zM37.821 29.414l-1.427-1.4.7-.714a8.314 8.314 0 0 0 2.406-5.853A8.541 8.541 0 0 0 30.887 13a8.658 8.658 0 0 0-6.624 3.047l-.646.763-1.525-1.292.646-.763A10.653 10.653 0 0 1 30.887 11 10.543 10.543 0 0 1 41.5 21.447a10.3 10.3 0 0 1-2.978 7.253z"
        />
        <path
          d="M30.265 45.5H9.729a8.5 8.5 0 0 1-2.338-16.733 13.5 13.5 0 0 1 26.3.356 8.5 8.5 0 0 1 5.214 9.138 8.658 8.658 0 0 1-8.64 7.239zm-9.765-25a11.5 11.5 0 0 0-11.289 9.3 1 1 0 0 1-.814.8 6.5 6.5 0 0 0-5.292 7.59 6.677 6.677 0 0 0 6.624 5.31h20.536a6.651 6.651 0 0 0 6.665-5.525 6.5 6.5 0 0 0-4.416-7.153 1 1 0 0 1-.679-.783A11.467 11.467 0 0 0 20.5 20.5z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.895 13.447l-1.789-.895a3.21 3.21 0 0 0-.6-4c-1.061-.884-2.521-.792-3.906.247L11.4 7.2c2.113-1.584 4.619-1.658 6.387-.183a5.182 5.182 0 0 1 1.108 6.43zM11 2h2v3h-2zM21 11h3v2h-3zM18.293 5.293l2-2 1.414 1.415-2 2zM14.761 24H5.056C3.979 24 .7 24 0 20.9a4.946 4.946 0 0 1 .85-3.98 4.587 4.587 0 0 1 2.35-1.726 6.767 6.767 0 0 1 6.674-5.577c3.625 0 6.167 3.2 6.72 6.481A3.935 3.935 0 0 1 19 19.9a.972.972 0 0 1-.013.159A4.393 4.393 0 0 1 14.761 24zM9.874 11.617a4.821 4.821 0 0 0-4.812 4.537 1 1 0 0 1-.9.875 2.444 2.444 0 0 0-1.7 1.061 2.982 2.982 0 0 0-.52 2.367C2.118 21.2 2.511 22 5.056 22h9.705A2.407 2.407 0 0 0 17 19.824a2.044 2.044 0 0 0-1.643-2.068 1 1 0 0 1-.675-.856c-.236-2.6-2.099-5.283-4.808-5.283z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SunCloudIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SunCloudIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SunCloudIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SquareFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M4 4h8v8H4z" />
        <path d="M13 13H3V3h10zm-8-2h6V5H5z" fill="currentColor" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M7.5 8.5h31v31h-31z" />
        <path d="M40 41H6V7h34zM9 38h28V10H9z" fill="currentColor" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M4.5 4.5h15v15h-15z" />
        <path d="M21 21H3V3h18zM6 18h12V6H6z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SquareFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SquareFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SquareFilledIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * ButtonGroup is a wrapper that allow you to group two buttons in a common yes/no type ux.
 */
const ButtonGroup = props => {
  const { responsive, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-btn-group",
        {
          "em-c-btn-group--responsive": responsive
        },
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

ButtonGroup.propTypes = {
  /** Determines if the button group has default or responsive behaviour on smaller screens */
  responsive: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Child React components, in this case 2 buttons */
  children: PropTypes.node
};

ButtonGroup.defaultProps = {
  responsive: false,
  className: "",
  children: null
};

export default ButtonGroup;

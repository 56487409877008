import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RefreshIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.438 6.554a.477.477 0 0 1-.127-.017L9.436 5.52l.254-.967 3.4.892.926-3.389.965.264-1.06 3.866a.5.5 0 0 1-.483.368z"
          fill="currentColor"
        />
        <path
          d="M8 14A6 6 0 0 1 8 2a5.946 5.946 0 0 1 5.336 3.274l.226.446-.893.451-.226-.446A4.949 4.949 0 0 0 8 3a5 5 0 0 0 0 10 4.949 4.949 0 0 0 4.443-2.726l.226-.446.893.451-.226.446A5.946 5.946 0 0 1 8 14z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39.731 19a2.868 2.868 0 0 1-.688-.084l-10.255-2.548.482-1.941 10.255 2.547a.815.815 0 0 0 .983-.5l2.809-9.712 1.921.556-2.808 9.711A2.8 2.8 0 0 1 39.731 19z"
          fill="currentColor"
        />
        <path
          d="M23.7 43A18.872 18.872 0 0 1 5 24 18.872 18.872 0 0 1 23.7 5a18.643 18.643 0 0 1 17.224 11.617l.383.924-1.848.766-.383-.924A16.647 16.647 0 0 0 23.7 7 16.87 16.87 0 0 0 7 24a16.87 16.87 0 0 0 16.7 17 16.567 16.567 0 0 0 14.951-9.438l.438-.9 1.8.876-.438.9A18.551 18.551 0 0 1 23.7 43z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.585 11.751a1.815 1.815 0 0 1-.307-.026l-5.429-.9.328-1.973 5.273.877.7-4.859 1.979.286-.729 5.049a1.858 1.858 0 0 1-1.815 1.546zm.024-2z"
          fill="currentColor"
        />
        <path
          d="M12.236 21.5a9.236 9.236 0 0 1 0-18.473 8.853 8.853 0 0 1 8.733 6.73l.243.97-1.94.485-.242-.971a6.879 6.879 0 0 0-6.794-5.215 7.236 7.236 0 1 0 6.271 10.849l.5-.866 1.732 1-.5.866a9.269 9.269 0 0 1-8.003 4.625z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RefreshIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RefreshIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RefreshIcon;

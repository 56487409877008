import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const LockIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-6A1.5 1.5 0 0 1 3.5 6h9A1.5 1.5 0 0 1 14 7.5v6a1.5 1.5 0 0 1-1.5 1.5zm-9-8a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5zM11.47 5.2h-1v-.5a2.47 2.47 0 1 0-4.939 0v.5h-1v-.5a3.47 3.47 0 1 1 6.939 0z"
          fill="currentColor"
        />
        <path
          d="M8.5 12h-1A1.5 1.5 0 0 1 6 10.5v-1A1.5 1.5 0 0 1 7.5 8h1A1.5 1.5 0 0 1 10 9.5v1A1.5 1.5 0 0 1 8.5 12zm-1-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 44H9a3 3 0 0 1-3-3V21a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v20a3 3 0 0 1-3 3zM9 20a1 1 0 0 0-1 1v20a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V21a1 1 0 0 0-1-1zM34 15h-2v-1c0-4.962-3.589-9-8-9s-8 4.038-8 9v1h-2v-1c0-6.065 4.486-11 10-11s10 4.935 10 11z"
          fill="currentColor"
        />
        <path
          d="M24 38a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7zm0-12a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 28h2v6h-2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 23H4a2 2 0 0 1-2-2V11a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM4 11v10h16V11zM17.5 8h-2V7a3.5 3.5 0 0 0-7 0v1h-2V7a5.5 5.5 0 0 1 11 0z"
          fill="currentColor"
        />
        <path
          d="M13 19h-2a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2zm-2-4v2h2v-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

LockIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

LockIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default LockIcon;

import { MsalAuthProvider, LoginType } from 'react-aad-msal';
import axios from 'axios';
import { GRAPH_SCOPE, DASHBOARD_HUB_API_DEV_SCOPE, DASHBOARD_HUB_API_PRD_SCOPE } from '../constants';
import { getHostname } from '../shared/util';

const config = {
    auth: {
        clientId: '3107ef8d-1076-4932-b60e-e4b1520061f1',
        authority: 'https://login.microsoftonline.com/EMCloudAD.onmicrosoft.com/',
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
};

const authenticationParameters = {
    scopes: ['user.read'],
};

const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html',
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);

axios.interceptors.request.use(
    async (config) => {
        const hostname = getHostname(config.url);
        let scopes;
        switch (hostname) {
            case 'graph.microsoft.com':
                scopes = [GRAPH_SCOPE];
                break;
            case 'dashboard-api.apps-dev.xom.cloud':
                scopes = [DASHBOARD_HUB_API_DEV_SCOPE];
                break;
            case 'dashboard-api.apps.xom.cloud':
                scopes = [DASHBOARD_HUB_API_PRD_SCOPE];
                break;
            default:
                scopes = [];
        }

        let response = await authProvider.getAccessToken({ scopes: scopes });
        if (response) {
            config.headers.Authorization = `Bearer ${response.accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

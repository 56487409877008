import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PhotoIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4 8a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM13.5 13h-11a.5.5 0 0 1-.5-.5v-1.906a.5.5 0 0 1 .269-.443l4-2.094a.5.5 0 0 1 .509.027l2.706 1.8L13.2 7.1a.5.5 0 0 1 .8.4v5a.5.5 0 0 1-.5.5zM3 12h10V8.5l-3.2 2.4a.5.5 0 0 1-.577.016L6.47 9.08 3 10.9z"
          fill="currentColor"
        />
        <path
          d="M15.5 15H.5a.5.5 0 0 1-.5-.5v-12A.5.5 0 0 1 .5 2h15a.5.5 0 0 1 .5.5v12a.5.5 0 0 1-.5.5zM1 14h14V3H1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M13 21a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3zM39 38H9a2 2 0 0 1-2-2v-4.775a1 1 0 0 1 .585-.91l11.087-5.058a1.006 1.006 0 0 1 .829 0l7.9 3.588 12.019-8.656A1 1 0 0 1 41 21v15a2 2 0 0 1-2 2zM9 31.867V36h30V22.953l-10.9 7.854a.993.993 0 0 1-1 .1l-8.01-3.64z"
          fill="currentColor"
        />
        <path
          d="M42 42H6a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h36a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3zM6 8a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21 22H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h17a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2zM4 4v16h17V4z"
          fill="currentColor"
        />
        <path
          d="M18 19H6a1 1 0 0 1-1-1v-3a1 1 0 0 1 .137-.5c.161-.275.161-.275 5.354-3.355a1 1 0 0 1 1.217.153L14 13.586l3.293-3.293A1 1 0 0 1 19 11v7a1 1 0 0 1-1 1zM7 17h10v-3.586l-2.293 2.293a1 1 0 0 1-1.414 0l-2.45-2.45A465.75 465.75 0 0 0 7 15.557z"
          fill="currentColor"
        />
        <circle cx={9.5} cy={7.5} r={1.5} fill="currentColor" />
        <path
          d="M9.5 10A2.5 2.5 0 1 1 12 7.5 2.5 2.5 0 0 1 9.5 10zm0-3a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PhotoIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PhotoIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PhotoIcon;

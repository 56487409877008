import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import VerticalHeaderTitle from "./VerticalHeaderTitle";
import VerticalHeaderBody from "./VerticalHeaderBody";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-header--main";
    case "accent":
      return "em-c-header--accent";
    case "mainToAccent":
      return "em-c-header--mainToAccent";
    case "accentToMain":
      return "em-c-header--accentToMain";
    case "blue":
      return "em-c-header--blue";
    case "red":
      return "em-c-header--red";
    default:
      return "";
  }
}

/**
 * VerticalHeader gives you vertical sidebar navigation on wide screens and hamburger navigation on small screens.
 *
 * @visibleName Vertical header
 * */
const VerticalHeader = props => {
  const { className, color, children, ...other } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    // this fixes a unity bug of going from mobil menu open to fullscreen will create some nasty scrollbars... Not sure how to hand this
    window.addEventListener(
      "resize",
      event => event.target.outerWidth > 650 && setMobileOpen(false)
    );
    return () =>
      window.removeEventListener(
        "resize",
        event => event.target.outerWidth > 650 && setMobileOpen(false)
      );
  }, []);

  const handleMobileMenuClick = () => setMobileOpen(prev => !prev);

  return (
    <>
      <header
        className={clsx(
          "em-c-header",
          "em-c-header--vertical",
          getColor(color),
          className
        )}
        role="banner"
        {...other}
      >
        <div className="em-c-header__inner">
          {React.Children.toArray(children).map(child =>
            React.cloneElement(child, {
              isMobileMenuOpen: mobileOpen,
              onMobileMenuClick: handleMobileMenuClick
            })
          )}
        </div>
      </header>
      <div className="em-c-header-overlay" />
    </>
  );
};

VerticalHeader.propTypes = {
  /** Color of the header */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "mainToAccent",
    "accentToMain",
    "red",
    "blue"
  ]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

VerticalHeader.defaultProps = {
  color: "red",
  className: "",
  children: null
};

VerticalHeader.Title = VerticalHeaderTitle;
VerticalHeader.Body = VerticalHeaderBody;

export default VerticalHeader;

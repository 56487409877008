import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import "./Skeleton.css";
import SkeletonInput from "./SkeletonInput";

function getAnimationClass(animation) {
  switch (animation) {
    case "pulse":
      return "em-c-skeleton--pulse";
    case "wave":
      return "em-c-skeleton--wave";
    default:
      return "";
  }
}

/**
 * A skeleton loader to be used as a placeholder while content loads. You Should use these in your components using a loading boolean to swap them out with the content themselves, do not make entire new components for skeletons, build them into your component.
 *
 * @visibleName Skeleton
 * */
const Skeleton = props => {
  const {
    animation,
    circle,
    height,
    width,
    className,
    children,
    ...other
  } = props;

  const style = props.style || {};

  if (width !== null) {
    style.width = width;
  }

  if (height !== null) {
    style.height = height;
  }
  return (
    <div
      style={style}
      className={clsx(
        "em-c-skeleton",
        { "em-c-skeleton--circle": circle },
        getAnimationClass(animation),
        className
      )}
      {...other}
    >
      &zwnj;
    </div>
  );
};

Skeleton.Input = SkeletonInput;

Skeleton.propTypes = {
  /** choose animation type for the loader */
  animation: PropTypes.oneOf(["pulse", "wave", "none"]),
  /** Change the shape of the skeleton loader */
  circle: PropTypes.bool,
  /** Set width manually */
  width: PropTypes.string,
  /** Set height manually */
  height: PropTypes.string,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

Skeleton.defaultProps = {
  animation: "pulse",
  circle: false,
  height: null,
  width: null,
  className: "",
  children: null
};

export default Skeleton;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CircleQuestionMarkFilledIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.8 3.2a7.5 7.5 0 1 0 0 10.6 7.49 7.49 0 0 0 0-10.6zM8.152 14a.958.958 0 1 1 .958-.958.958.958 0 0 1-.958.958zm2.516-7.146a2.757 2.757 0 0 1-1.618 1.49.4.4 0 0 0-.258.284v2.177H7.513V8.628a1.656 1.656 0 0 1 1.118-1.492 1.467 1.467 0 0 0 .866-.8 1.534 1.534 0 0 0-.024-1.266 1.261 1.261 0 0 0-.645-.645 1.522 1.522 0 0 0-1.481.092 1.468 1.468 0 0 0-.665 1.23v.639H5.4v-.639a2.739 2.739 0 0 1 1.243-2.3 2.8 2.8 0 0 1 2.691-.2 2.557 2.557 0 0 1 1.306 1.306 2.838 2.838 0 0 1 .027 2.301z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39.556 8.444a22 22 0 1 0 0 31.113 22 22 0 0 0 0-31.113zM24 36a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm5.478-16.5a6.021 6.021 0 0 1-3.6 3.247A1.366 1.366 0 0 0 25 23.882V27a1 1 0 0 1-2 0v-3.118a3.347 3.347 0 0 1 2.252-3.033 4.012 4.012 0 0 0 2.4-2.165 4.227 4.227 0 0 0-.044-3.445 3.509 3.509 0 0 0-1.8-1.8 4.158 4.158 0 0 0-4 .26A4 4 0 0 0 20 17.049a1 1 0 0 1-2 0 5.989 5.989 0 0 1 2.713-5.021 6.123 6.123 0 0 1 5.895-.422 5.526 5.526 0 0 1 2.835 2.834 6.2 6.2 0 0 1 .035 5.06z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19.778 4.222a11 11 0 1 0 0 15.556 11 11 0 0 0 0-15.556zM12 19a1 1 0 1 1 1-1 1 1 0 0 1-1 1zm1-6.142V14a1 1 0 0 1-2 0v-2a1 1 0 0 1 1-1 2 2 0 1 0-2-2 1 1 0 0 1-2 0 4 4 0 1 1 5 3.858z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CircleQuestionMarkFilledIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CircleQuestionMarkFilledIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CircleQuestionMarkFilledIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CalendarIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M6 3h4v1H6zM4 1.5h1v3H4zM11 1.5h1v3h-1zM4.5 7.5h1v1h-1z"
        />
        <rect
          x={4}
          y={7}
          width={2}
          height={2}
          rx={0.5}
          ry={0.5}
          fill="currentColor"
        />
        <path fill="currentColor" d="M7.5 7.5h1v1h-1z" />
        <rect
          x={7}
          y={7}
          width={2}
          height={2}
          rx={0.5}
          ry={0.5}
          fill="currentColor"
        />
        <path fill="currentColor" d="M7.5 10.5h1v1h-1z" />
        <rect
          x={7}
          y={10}
          width={2}
          height={2}
          rx={0.5}
          ry={0.5}
          fill="currentColor"
        />
        <path fill="currentColor" d="M10.5 7.5h1v1h-1z" />
        <rect
          x={10}
          y={7}
          width={2}
          height={2}
          rx={0.5}
          ry={0.5}
          fill="currentColor"
        />
        <path fill="currentColor" d="M4.5 10.5h1v1h-1z" />
        <rect
          x={4}
          y={10}
          width={2}
          height={2}
          rx={0.5}
          ry={0.5}
          fill="currentColor"
        />
        <path
          d="M14.5 15h-13a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H3v1H2v10h12V4h-1V3h1.5a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M17 6h14v2H17z" />
        <path
          d="M42 45H6a3 3 0 0 1-3-3V9a3 3 0 0 1 3-3h5v2H6a1 1 0 0 0-1 1v33a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1h-5V6h5a3 3 0 0 1 3 3v33a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M13 3h2v8h-2zM33 3h2v8h-2zM17 28h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4v-4h-4zM17 39h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4v-4h-4zM27 28h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4v-4h-4zM27 39h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4v-4h-4zM37 28h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zm-5-2h4v-4h-4zM7 14h34v2H7z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M9 4h6v2H9z" />
        <path
          d="M21 23H3a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2v2H3v15h18V6h-2V4h2a2 2 0 0 1 2 2v15a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M6 2h2v6H6zM16 2h2v6h-2zM6 11h2v2H6z" />
        <rect
          x={5}
          y={10}
          width={4}
          height={4}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 11h2v2h-2z" />
        <rect
          x={10}
          y={10}
          width={4}
          height={4}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 16h2v2h-2z" />
        <rect
          x={10}
          y={15}
          width={4}
          height={4}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path fill="currentColor" d="M16 11h2v2h-2z" />
        <rect
          x={15}
          y={10}
          width={4}
          height={4}
          rx={1}
          ry={1}
          fill="currentColor"
        />
        <path fill="currentColor" d="M6 16h2v2H6z" />
        <rect
          x={5}
          y={15}
          width={4}
          height={4}
          rx={1}
          ry={1}
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CalendarIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CalendarIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CalendarIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CheckIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M5.36 12.106a1.158 1.158 0 0 1-.82-.339L1.032 8.259l.707-.707 3.508 3.508a.16.16 0 0 0 .227 0l8.787-8.787.707.707-8.787 8.787a1.158 1.158 0 0 1-.821.339z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M16 37.362a2.993 2.993 0 0 1-2.121-.876L3.615 26.222l1.414-1.414 10.264 10.263a1 1 0 0 0 1.414 0L42.971 8.808l1.414 1.414-26.264 26.263a2.993 2.993 0 0 1-2.121.877z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M8 18.828a1.993 1.993 0 0 1-1.414-.585L1.1 12.757l1.414-1.414L8 16.829 21.485 3.343 22.9 4.757 9.414 18.243A1.993 1.993 0 0 1 8 18.828z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CheckIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CheckIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CheckIcon;

import React, { useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { TableBodyGroupedRowContext } from "../TableBodyGroupedRow";

/** TableBodyRow is a row for grouping cells of data in the table body */
const TableBodyRow = props => {
  const { className, children, ...other } = props;
  const { hasGroupedRows, open } = useContext(TableBodyGroupedRowContext);

  return (
    <tr
      className={clsx(
        "em-c-table__row",
        {
          "em-c-table__row--secondary em-js-table-row-collapsible": hasGroupedRows,
          "em-is-visible": open
        },
        className
      )}
      {...other}
    >
      {children}
    </tr>
  );
};

TableBodyRow.propTypes = {
  /** children are the contents of the TableBodyRow */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TableBodyRow.defaultProps = {
  children: null,
  className: ""
};

export default TableBodyRow;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TrophyIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8.5 11A4.5 4.5 0 0 1 4 6.5v-4a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v4A4.5 4.5 0 0 1 8.5 11zM5 3v3.5a3.5 3.5 0 0 0 7 0V3zM10.5 15h-4a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM7 14h3v-1H7z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M8 10h1v3H8zM12.5 9H12V8h.5A2.5 2.5 0 0 0 15 5.5V4h-3V3h3.5a.5.5 0 0 1 .5.5v2A3.5 3.5 0 0 1 12.5 9zM5 9h-.5A3.5 3.5 0 0 1 1 5.5v-2a.5.5 0 0 1 .5-.5H5v1H2v1.5A2.5 2.5 0 0 0 4.5 8H5z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 33a13.015 13.015 0 0 1-13-13V4a1 1 0 0 1 1-1h24a1 1 0 0 1 1 1v16a13.015 13.015 0 0 1-13 13zM13 5v15a11 11 0 0 0 22 0V5zM32 45H16a1 1 0 0 1-1-1 7.008 7.008 0 0 1 7-7h4a7.008 7.008 0 0 1 7 7 1 1 0 0 1-1 1zm-14.9-2h13.8a5.009 5.009 0 0 0-4.9-4h-4a5.009 5.009 0 0 0-4.9 4zM8.365 23.366l-.866-.5A9.055 9.055 0 0 1 3 15.043V7a1 1 0 0 1 1-1h5v2H5v7.043a7.049 7.049 0 0 0 3.5 6.091l.865.5zM39.635 23.366l-1-1.731.865-.5a7.049 7.049 0 0 0 3.5-6.092V8h-4V6h5a1 1 0 0 1 1 1v8.043a9.055 9.055 0 0 1-4.5 7.823z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M23 31h2v8h-2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 19h-1a7.008 7.008 0 0 1-7-7V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9c0 4.187-2.411 7-6 7zM7 4v8a5.006 5.006 0 0 0 5 5h1c2.949 0 4-2.583 4-5V4zM16 25H8a1 1 0 0 1-1-1 4 4 0 0 1 4-4h2a4 4 0 0 1 4 4 1 1 0 0 1-1 1zm-6.731-2h5.463A2 2 0 0 0 13 22h-2a2 2 0 0 0-1.731 1zM3.983 14H3c-1.717 0-3-2.111-3-4V5a1 1 0 0 1 1-1h3v2H2v4a2.24 2.24 0 0 0 1 2l.982.033zM21 14h-1v-2h1a2.24 2.24 0 0 0 1-2V6h-2V4h3a1 1 0 0 1 1 1v5c0 1.889-1.283 4-3 4z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 17h2v4h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TrophyIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TrophyIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TrophyIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const QuoteRightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <rect
          x={9.5}
          y={2.59}
          width={5}
          height={5}
          rx={1}
          ry={1}
          transform="rotate(180 12 5.09)"
          fill="currentColor"
        />
        <path
          d="M13.5 8.09h-3A1.5 1.5 0 0 1 9 6.59v-3a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5zm-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <rect
          x={2.5}
          y={2.59}
          width={5}
          height={5}
          rx={1}
          ry={1}
          transform="rotate(180 5 5.09)"
          fill="currentColor"
        />
        <path
          d="M6.5 8.09h-3A1.5 1.5 0 0 1 2 6.59v-3a1.5 1.5 0 0 1 1.5-1.5h3A1.5 1.5 0 0 1 8 3.59v3a1.5 1.5 0 0 1-1.5 1.5zm-3-5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5zM9.088 14.075l-.163-.987.493-.081C12.041 12.573 14 9.261 14 6.59v-.5h1v.5c0 3.078-2.225 6.875-5.418 7.4z"
          fill="currentColor"
        />
        <path
          d="M2.088 14.075l-.163-.987.493-.081C5.041 12.573 7 9.261 7 6.59v-.5h1v.5c0 3.078-2.225 6.875-5.418 7.4z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <rect
          x={29}
          y={10}
          width={14}
          height={14}
          rx={2.667}
          ry={2.667}
          transform="rotate(180 36 17)"
          fill="currentColor"
        />
        <path
          d="M40.333 25h-8.666A3.671 3.671 0 0 1 28 21.333v-8.666A3.671 3.671 0 0 1 31.667 9h8.667A3.671 3.671 0 0 1 44 12.667v8.666A3.671 3.671 0 0 1 40.333 25zm-8.666-14A1.669 1.669 0 0 0 30 12.667v8.666A1.669 1.669 0 0 0 31.667 23h8.667A1.669 1.669 0 0 0 42 21.333v-8.666A1.669 1.669 0 0 0 40.333 11z"
          fill="currentColor"
        />
        <rect
          x={7}
          y={10}
          width={14}
          height={14}
          rx={2.667}
          ry={2.667}
          transform="rotate(180 14 17)"
          fill="currentColor"
        />
        <path
          d="M18.333 25H9.667A3.671 3.671 0 0 1 6 21.333v-8.666A3.671 3.671 0 0 1 9.667 9h8.667A3.671 3.671 0 0 1 22 12.667v8.666A3.671 3.671 0 0 1 18.333 25zM9.667 11A1.669 1.669 0 0 0 8 12.667v8.666A1.669 1.669 0 0 0 9.667 23h8.667A1.669 1.669 0 0 0 20 21.333v-8.666A1.669 1.669 0 0 0 18.333 11z"
          fill="currentColor"
        />
        <path
          d="M8.313 39.236l-.548-1.924.961-.274A15.664 15.664 0 0 0 20 22v-1h2v1A17.674 17.674 0 0 1 9.274 38.962zM30.313 39.236l-.548-1.924.961-.274A15.664 15.664 0 0 0 42 22v-1h2v1a17.674 17.674 0 0 1-12.726 16.962z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <rect
          x={15}
          y={5}
          width={7}
          height={7}
          rx={1.333}
          ry={1.333}
          transform="rotate(180 18.5 8.5)"
          fill="currentColor"
        />
        <path
          d="M20.667 13h-4.334A2.336 2.336 0 0 1 14 10.667V6.333A2.336 2.336 0 0 1 16.333 4h4.333A2.336 2.336 0 0 1 23 6.333v4.334A2.336 2.336 0 0 1 20.667 13zm-4.334-7a.334.334 0 0 0-.333.333v4.334a.334.334 0 0 0 .333.333h4.333a.334.334 0 0 0 .334-.333V6.333A.334.334 0 0 0 20.667 6z"
          fill="currentColor"
        />
        <rect
          x={4}
          y={5}
          width={7}
          height={7}
          rx={1.333}
          ry={1.333}
          transform="rotate(180 7.5 8.5)"
          fill="currentColor"
        />
        <path
          d="M9.667 13H5.333A2.336 2.336 0 0 1 3 10.667V6.333A2.336 2.336 0 0 1 5.333 4h4.334A2.336 2.336 0 0 1 12 6.333v4.334A2.336 2.336 0 0 1 9.667 13zM5.333 6A.334.334 0 0 0 5 6.333v4.334a.334.334 0 0 0 .333.333h4.334a.334.334 0 0 0 .333-.333V6.333A.334.334 0 0 0 9.667 6z"
          fill="currentColor"
        />
        <path
          d="M3.306 20.232l-.539-1.926.963-.27C6.623 17.229 10 14.9 10 11v-1h2v1c0 4.132-3.035 7.65-7.731 8.963zM14.306 20.232l-.539-1.926.963-.27C17.623 17.229 21 14.9 21 11v-1h2v1c0 4.132-3.035 7.65-7.731 8.963z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

QuoteRightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

QuoteRightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default QuoteRightIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** PullquoteText displays the quote part of a pullquote */
const PullquoteText = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-pullquote__quote", className)} {...other}>
      {children}
    </div>
  );
};

PullquoteText.propTypes = {
  /** children is the content of teh pullquote citation */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

PullquoteText.defaultProps = {
  children: null,
  className: ""
};

export default PullquoteText;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MapPinPersonIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11 6a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-5a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          d="M6.6 15.93a.5.5 0 0 1-.3-.1C6.05 15.643.16 11.178.16 6.66c0-3.083 2.889-5.59 6.44-5.59h.5v1h-.5c-3 0-5.44 2.059-5.44 4.59 0 3.478 4.259 7.175 5.44 8.133a22.34 22.34 0 0 0 3.531-3.593l.3-.4.8.6-.3.4A23.023 23.023 0 0 1 6.9 15.83a.5.5 0 0 1-.3.1z"
          fill="currentColor"
        />
        <path
          d="M15.5 10h-9a.5.5 0 0 1-.5-.5V9a3.956 3.956 0 0 1 1.144-2.791l.351-.356.712.7-.351.356A2.962 2.962 0 0 0 7 9h8a2.962 2.962 0 0 0-.856-2.089l-.351-.356.712-.7.351.356A3.956 3.956 0 0 1 16 9v.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M35 20a7 7 0 1 1 7-7 7.008 7.008 0 0 1-7 7zm0-12a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5z"
          fill="currentColor"
        />
        <path
          d="M45 30H25a1 1 0 0 1-1-1v-1a11.051 11.051 0 0 1 4.41-8.8l.8-.6 1.2 1.6-.8.6A9.041 9.041 0 0 0 26 28h18a8.94 8.94 0 0 0-2.637-6.363 8.419 8.419 0 0 0-.966-.832l-.805-.593 1.186-1.612.806.593a10.412 10.412 0 0 1 1.194 1.029A10.925 10.925 0 0 1 46 28v1a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M20.045 47a1 1 0 0 1-.6-.2C18.693 46.237 1 32.847 1 19.438 1 10.374 9.543 3 20.045 3A21.578 21.578 0 0 1 27.4 4.268l.94.339-.679 1.881-.941-.34A19.586 19.586 0 0 0 20.045 5C10.646 5 3 11.477 3 19.438c0 11.09 13.958 22.833 17.045 25.294A68.509 68.509 0 0 0 31.2 33.484l.605-.8L33.4 33.9l-.605.8a69.316 69.316 0 0 1-12.151 12.1 1 1 0 0 1-.599.2z"
          fill="currentColor"
        />
        <path
          d="M20.641 24.133l-.99-.143a6.027 6.027 0 1 1 5.639-9.655l.613.79-1.58 1.227-.613-.79a4.027 4.027 0 1 0-3.773 6.449l.99.143z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 16H10a1 1 0 0 1-1-1v-2.182a4.243 4.243 0 0 1 1.1-2.854l.671-.741 1.483 1.342-.671.742A2.246 2.246 0 0 0 11 12.818V14h10v-1.182a2.246 2.246 0 0 0-.581-1.511l-.671-.742 1.482-1.341.671.741A4.243 4.243 0 0 1 23 12.818V15a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M16 11.636a4.818 4.818 0 1 1 4.818-4.818A4.824 4.824 0 0 1 16 11.636zM16 4a2.818 2.818 0 1 0 2.818 2.818A2.821 2.821 0 0 0 16 4z"
          fill="currentColor"
        />
        <path
          d="M10 24a1 1 0 0 1-.665-.252C8.38 22.9 0 15.3 0 10c0-4.878 4.58-9 10-9h1v2h-1c-4.336 0-8 3.206-8 7 0 3.451 5.357 9.173 8.066 11.706A24.342 24.342 0 0 0 14.2 17.4l.6-.8 1.6 1.208-.6.8a23.555 23.555 0 0 1-5.25 5.233A1 1 0 0 1 10 24z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MapPinPersonIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MapPinPersonIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MapPinPersonIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ArchiveFileBoxIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.676 7.686l-.608-4.257A.5.5 0 0 0 12.573 3H3.427a.5.5 0 0 0-.495.429l-.608 4.257-.99-.142.608-4.257A1.507 1.507 0 0 1 3.427 2h9.146a1.507 1.507 0 0 1 1.485 1.288l.608 4.257zM14.5 14h-13a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5V10h4V8.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM2 13h12V9h-3v1.5a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5V9H2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42.316 20.238L38.784 7.995a1.009 1.009 0 0 1-.029-.136C38.674 7.289 38.42 7 38 7H10c-.42 0-.674.289-.755.858a1.009 1.009 0 0 1-.029.136L5.684 20.238l-1.921-.554L7.276 7.5A2.741 2.741 0 0 1 10 5h28a2.741 2.741 0 0 1 2.724 2.5l3.514 12.181zM42 41H6a3 3 0 0 1-3-3V25a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v4h14v-4a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v13a3 3 0 0 1-3 3zM5 25v13a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V25H33v4a2 2 0 0 1-2 2H17a2 2 0 0 1-2-2v-4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M21.215 12.177L20.02 6.2a.337.337 0 0 0-.11-.214L4 6c.055 0 0 .087-.01.141l-1.205 6.036-1.962-.392L2.02 5.8A2.106 2.106 0 0 1 4 4h16a2.124 2.124 0 0 1 1.99 1.859l1.187 5.926zM21 23H3a2 2 0 0 1-2-2v-7a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2h6v-2a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v7a2 2 0 0 1-2 2zM3 15v6h18v-6h-4v2a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ArchiveFileBoxIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ArchiveFileBoxIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ArchiveFileBoxIcon;

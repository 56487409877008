import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import AccordianItem from "./AccordianItem";

/** A list of headers that open/close on click to reveal/hide additional content */
const Accordian = props => {
  const { className, onChange, expanded, children, ...other } = props;
  const expandedAsArray = Array.isArray(expanded) ? expanded : [expanded];

  function handleAccordianItemClick(e, value) {
    onChange(e, value);
  }

  return (
    <ul className={clsx("em-c-accordion", className)} {...other}>
      {React.Children.toArray(children).map(child =>
        React.cloneElement(child, {
          open: expandedAsArray.includes(child.props.value),
          onChange: onChange ? handleAccordianItemClick : null
        })
      )}
    </ul>
  );
};

Accordian.Item = AccordianItem;

Accordian.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Accordian item(s) to expand */
  expanded: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
  ]),
  /** Callback when an accordian item is clicked */
  onChange: PropTypes.func,
  /** React component children */
  children: PropTypes.node
};

Accordian.defaultProps = {
  className: "",
  expanded: [],
  onChange: null,
  children: null
};

export default Accordian;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** Group to contain Tags and style them properly. In most cases Tags should be wrapped in a Tag Group, even if there is only 1. */
const TagGroup = props => {
  const { closing, className, children, ...other } = props;

  return (
    <ul
      className={clsx(
        "em-c-tags",
        { "em-c-tags--closing": closing },
        className
      )}
      {...other}
    >
      {children}
    </ul>
  );
};

TagGroup.propTypes = {
  /** Identifies if the tag list will have 'x's or not */
  closing: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** The children of TagList, which should most likely only be Tags */
  children: PropTypes.node
};

TagGroup.defaultProps = {
  closing: false,
  className: "",
  children: null
};

export default TagGroup;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TrashcanEmptyIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 15c-.409 0-4-.042-4-1.5L3 4.556a.5.5 0 0 1 1-.112l1 9A5.678 5.678 0 0 0 8 14a5.332 5.332 0 0 0 3.022-.59L12 4.444A.493.493 0 0 1 12.556 4a.5.5 0 0 1 .444.556l-1 9C12 14.958 8.409 15 8 15z"
          fill="currentColor"
        />
        <path
          d="M8 6c-1.173 0-5-.108-5-1.5S6.827 3 8 3s5 .108 5 1.5S9.173 6 8 6zM4.191 4.5A11.25 11.25 0 0 0 8 5a11.25 11.25 0 0 0 3.809-.5A11.25 11.25 0 0 0 8 4a11.25 11.25 0 0 0-3.809.5zm7.85.137z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 46c-4.792 0-12.878-.824-13-3.9L7.007 7.948A.928.928 0 0 1 7 7.832C7 4.185 21.161 4 24 4s17 .185 17 3.832a.928.928 0 0 1-.007.116L37 42.1c-.122 3.076-8.208 3.9-13 3.9zM9.023 7.973l3.97 33.938a.928.928 0 0 1 .007.116c.349.658 4.34 1.973 11 1.973s10.651-1.315 11.014-2.052v-.019s-.006.008 0-.02l3.97-33.937C38.113 7.259 33.024 6 24 6S9.887 7.259 9.023 7.973z"
          fill="currentColor"
        />
        <path
          d="M24 11.5c-2.839 0-17-.192-17-4a1 1 0 0 1 1.991-.136C9.669 8.111 14.746 9.5 24 9.5s14.331-1.389 15.009-2.136A1 1 0 0 1 41 7.5c0 3.808-14.161 4-17 4z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M13 24c-2.051 0-8-.738-8-3L4 5.063a2.316 2.316 0 0 1 1.684-2.011c3.186-1.062 10.447-1.062 13.633 0A2.319 2.319 0 0 1 21 5l-1 16.063C20 22.957 15.935 24 13 24zm-6.016-3.286A13.368 13.368 0 0 0 13 22a9.012 9.012 0 0 0 5.013-1.2l.976-15.66a.733.733 0 0 0-.306-.191c-2.773-.924-9.594-.924-12.367 0a.739.739 0 0 0-.308.193zM18 20.925A.608.608 0 0 0 18 21zm-11.006-.043v.056a.356.356 0 0 0 0-.056zM19.035 5.2z"
          fill="currentColor"
        />
        <path
          d="M13 8c-2.937 0-9-.733-9-3h2a.578.578 0 0 0-.115-.368C6.241 5.009 8.968 6 13 6c3.9 0 5.811-.922 6.066-1.257A.463.463 0 0 0 19 5h2c0 2.062-4.146 3-8 3z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TrashcanEmptyIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TrashcanEmptyIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TrashcanEmptyIcon;

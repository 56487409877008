import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PhoneIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.35 15.282a1.5 1.5 0 0 1-.866-.275l-1.066-.755a30.416 30.416 0 0 1-7.289-7.287L1.374 5.9a1.5 1.5 0 0 1-.062-1.639l1.127-1.879a1.5 1.5 0 0 1 2.347-.289l2.63 2.63a.5.5 0 0 1 0 .707L6.355 6.49l3.535 3.536 1.061-1.062a.515.515 0 0 1 .707 0l2.63 2.63A1.5 1.5 0 0 1 14 13.942l-1.878 1.126a1.5 1.5 0 0 1-.772.214zM3.727 2.653a.452.452 0 0 0-.062 0A.494.494 0 0 0 3.3 2.9L2.17 4.773a.5.5 0 0 0 .021.546l.755 1.067a29.4 29.4 0 0 0 7.049 7.049l1.067.755a.5.5 0 0 0 .546.021l1.878-1.126a.5.5 0 0 0 .1-.783l-2.276-2.276-1.061 1.062a.515.515 0 0 1-.707 0L5.295 6.844a.5.5 0 0 1 0-.707l1.06-1.061L4.079 2.8a.5.5 0 0 0-.352-.147zm10.016 10.86z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M35.721 43.2a8.978 8.978 0 0 1-5.18-1.646L28.33 40A80.773 80.773 0 0 1 8.7 20.369L7.146 18.16a8.985 8.985 0 0 1 1-11.544l2.31-2.316a1.029 1.029 0 0 1 1.414 0l9.9 9.9a1 1 0 0 1 0 1.414l-3.535 3.535 11.313 11.317 3.536-3.536a1.029 1.029 0 0 1 1.414 0l9.9 9.9a1 1 0 0 1 0 1.414l-2.313 2.313a8.982 8.982 0 0 1-6.364 2.643zM11.163 6.424L9.557 8.03a6.988 6.988 0 0 0-.775 8.979l1.556 2.209a78.776 78.776 0 0 0 19.143 19.144l2.211 1.556a6.988 6.988 0 0 0 8.978-.775l1.606-1.606-8.485-8.485-3.536 3.536a1.029 1.029 0 0 1-1.414 0l-12.728-12.73a1 1 0 0 1 0-1.414l3.535-3.535z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.611 22.981a5.144 5.144 0 0 1-3.244-1.207A126.9 126.9 0 0 1 3.235 9.644c-1.729-2.055-.508-5.786 1.058-7.351l1-1a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414L10.414 9 16 14.586l1.293-1.293a1 1 0 0 1 1.414 0l5 5a1 1 0 0 1 0 1.414l-2 2a4.334 4.334 0 0 1-3.096 1.274zM6 3.414l-.293.293c-1.1 1.1-1.782 3.65-.942 4.649a124.161 124.161 0 0 0 11.868 11.87 2.718 2.718 0 0 0 3.66.067L21.586 19 18 15.414l-1.293 1.293a1 1 0 0 1-1.414 0l-7-7a1 1 0 0 1 0-1.414L9.586 7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PhoneIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PhoneIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PhoneIcon;

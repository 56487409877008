import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Button from "../../../Button";
import { XFilledIcon, SearchIcon } from "../../../Icons";

/**  is the component in the header to toggle the search bar */
const HorizontalHeaderNavSearchToggle = props => {
  const { className, active, onClick, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-primary-nav__item",
        "em-c-primary-nav__item--search-trigger",
        className
      )}
      {...other}
    >
      <Button variant="bare" onClick={onClick}>
        {active ? <XFilledIcon /> : <SearchIcon />}
      </Button>
    </li>
  );
};

HorizontalHeaderNavSearchToggle.propTypes = {
  /** Active determines if this toggle shows the search icon or x icon */
  active: PropTypes.bool,
  /** OnClickAction is what is called when  */
  onClick: PropTypes.func,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

HorizontalHeaderNavSearchToggle.defaultProps = {
  active: false,
  onClick: () => {},
  className: ""
};

export default HorizontalHeaderNavSearchToggle;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import BulletedListItem from "./BulletedListItem";

/**
 * A simple list demarcated by bullet points.
 *
 * @visibleName Bulleted list
 * */
const BulletedList = props => {
  const { className, color, children, ...other } = props;

  return (
    <ul className={clsx("em-c-bulleted-list", className)} {...other}>
      {React.Children.toArray(children).map(c =>
        React.cloneElement(c, { color })
      )}
    </ul>
  );
};

BulletedList.Item = BulletedListItem;

BulletedList.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the list items */
  color: PropTypes.oneOf(["default", "main", "accent"]),
  /** React component children */
  children: PropTypes.node
};

BulletedList.defaultProps = {
  className: "",
  children: null,
  color: "default"
};

export default BulletedList;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StarEmptyIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.568 15.688a.5.5 0 0 1-.294-.1L8 12.488l-4.274 3.1a.5.5 0 0 1-.77-.559l1.632-5.023L.315 6.9A.5.5 0 0 1 .609 6h5.283L7.524.976a.52.52 0 0 1 .951 0L10.108 6h5.283a.5.5 0 0 1 .294.9l-4.274 3.106 1.632 5.023a.5.5 0 0 1-.476.654zM8 11.37a.5.5 0 0 1 .294.1l3.323 2.414-1.269-3.91a.5.5 0 0 1 .182-.559L13.853 7H9.745a.5.5 0 0 1-.476-.346L8 2.748 6.731 6.654A.5.5 0 0 1 6.255 7H2.147l3.324 2.415a.5.5 0 0 1 .182.559l-1.27 3.906 3.323-2.414A.5.5 0 0 1 8 11.37z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37.6 46a1 1 0 0 1-.589-.192L24 36.322l-13.007 9.486a1 1 0 0 1-1.541-1.115l4.97-15.354-13.011-9.488A1 1 0 0 1 2 18.043h16.079l4.97-15.351a1 1 0 0 1 1.9 0l4.97 15.351H46a1 1 0 0 1 .589 1.808l-13.011 9.487 4.97 15.354A1 1 0 0 1 37.6 46zM24 34.085a1 1 0 0 1 .589.192l11.107 8.1-4.244-13.112a1 1 0 0 1 .362-1.115l11.117-8.106H29.193a1 1 0 0 1-.951-.692L24 6.247l-4.242 13.1a1 1 0 0 1-.951.692H5.069l11.117 8.106a1 1 0 0 1 .362 1.115L12.3 42.377l11.107-8.1a1 1 0 0 1 .593-.192z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17.951 23.445a1 1 0 0 1-.587-.19L11.4 18.927l-5.963 4.328A1 1 0 0 1 3.9 22.136l2.278-7L.214 10.81A1 1 0 0 1 .8 9h7.372l2.277-7a1 1 0 0 1 1.9 0l2.277 7H22a1 1 0 0 1 .587 1.81l-5.962 4.326 2.277 7a1 1 0 0 1-.951 1.31zM11.4 16.691a1 1 0 0 1 .587.19l4.061 2.947-1.548-4.765a1 1 0 0 1 .364-1.119L18.919 11H13.9a1 1 0 0 1-.951-.69L11.4 5.541 9.85 10.31a1 1 0 0 1-.95.69H3.882l4.058 2.944a1 1 0 0 1 .36 1.119l-1.548 4.766 4.061-2.947a1 1 0 0 1 .587-.191z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

StarEmptyIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

StarEmptyIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default StarEmptyIcon;

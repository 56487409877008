import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PersonRunningIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 4a1.5 1.5 0 1 1 1.061-.438A1.494 1.494 0 0 1 11.5 4zm0-2a.5.5 0 1 0 .354.146A.5.5 0 0 0 11.5 2zM2.015 13.485a1.5 1.5 0 0 1-1.061-2.561l2.061-2.06.707.707-2.061 2.061a.5.5 0 0 0 0 .707.511.511 0 0 0 .707 0l2.061-2.061.707.707-2.061 2.061a1.489 1.489 0 0 1-1.06.439z"
          fill="currentColor"
        />
        <path
          d="M6.55 16.02a1.5 1.5 0 0 1-1.06-2.56l1.768-1.768L4.075 8.51a.5.5 0 0 1 0-.707l4.243-4.242a.5.5 0 0 1 .708 0l3.182 3.182 1.061-1.061a1.5 1.5 0 0 1 2.12 2.118l-2.121 2.125a1.5 1.5 0 0 1-2.121 0L8.671 7.45l-.707.707 3.182 3.182a.5.5 0 0 1 0 .707l-3.535 3.536a1.494 1.494 0 0 1-1.061.438zM5.136 8.157l3.182 3.182a.5.5 0 0 1 0 .707L6.2 14.167a.5.5 0 0 0 .707.708l3.182-3.182L6.9 8.51a.5.5 0 0 1 0-.707l1.418-1.414a.5.5 0 0 1 .708 0l2.828 2.829a.5.5 0 0 0 .707 0L14.682 7.1a.5.5 0 0 0 0-.707.513.513 0 0 0-.707 0L12.561 7.8a.5.5 0 0 1-.707 0L8.671 4.621zM3.934 5.91a1.5 1.5 0 0 1-1.061-2.561l1.91-1.91a1.536 1.536 0 0 1 2.121 0L7.964 2.5l-.707.707L6.2 2.146a.511.511 0 0 0-.707 0L3.58 4.056a.5.5 0 0 0 0 .707.511.511 0 0 0 .707 0l1.556-1.556.707.707L4.994 5.47a1.491 1.491 0 0 1-1.06.44z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M36 11a5 5 0 1 1 3.536-1.462A4.985 4.985 0 0 1 36 11zm0-8a3 3 0 1 0 2.122.877A2.992 2.992 0 0 0 36 3zm2.829 5.827zM4.453 40.5a2.951 2.951 0 0 1-2.088-5.04l10.373-10.374 1.414 1.414L3.779 36.873a.953.953 0 1 0 1.348 1.348L15.5 27.848l1.414 1.414L6.541 39.635a2.944 2.944 0 0 1-2.088.865z"
          fill="currentColor"
        />
        <path
          d="M19.469 46.5a2.92 2.92 0 0 1-2.109-.9 3.079 3.079 0 0 1 0-4.261l7.677-7.9-7.677-7.9a3.079 3.079 0 0 1 0-4.261L28.5 9.8a1.03 1.03 0 0 1 1.435 0l9.029 9.3 3.46-3.562a3 3 0 0 1 4.219 0 3.079 3.079 0 0 1 0 4.261l-5.569 5.734a3 3 0 0 1-4.219 0L31.283 19.8a.952.952 0 0 0-1.35 0l-4.177 4.3a1.065 1.065 0 0 0 0 1.474l5.569 5.734a3.081 3.081 0 0 1 0 4.26L21.579 45.6a2.92 2.92 0 0 1-2.11.9zm9.747-34.564l-10.422 10.73a1.065 1.065 0 0 0 0 1.474l8.354 8.6a1 1 0 0 1 0 1.394l-8.354 8.6a1.065 1.065 0 0 0 0 1.474.952.952 0 0 0 1.35 0l9.747-10.035a1.066 1.066 0 0 0 0-1.474l-5.569-5.734a3.079 3.079 0 0 1 0-4.261L28.5 18.4a3 3 0 0 1 4.219 0l5.569 5.734a.952.952 0 0 0 1.35 0l5.568-5.734a1.065 1.065 0 0 0 0-1.474.952.952 0 0 0-1.35 0l-4.177 4.3a1 1 0 0 1-.717.3 1 1 0 0 1-.717-.3zM16.017 15a2.97 2.97 0 0 1-2.143-.909 3.12 3.12 0 0 1 0-4.325l5.7-5.858a3.05 3.05 0 0 1 4.285 0l3.55 3.646-1.433 1.4-3.55-3.646a1 1 0 0 0-1.419 0l-5.7 5.858a1.106 1.106 0 0 0 0 1.534 1 1 0 0 0 1.419 0l4.279-4.394a1 1 0 0 1 .716-.3 1 1 0 0 1 .716.3l2.124 2.181-1.433 1.4-1.407-1.445-3.562 3.658a2.97 2.97 0 0 1-2.142.9z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.5 7a3.5 3.5 0 0 1-2.475-5.975 3.584 3.584 0 0 1 4.95 0 3.5 3.5 0 0 1 0 4.949A3.477 3.477 0 0 1 18.5 7zm0-5a1.5 1.5 0 0 0-1.061 2.561 1.536 1.536 0 0 0 2.121 0A1.5 1.5 0 0 0 18.5 2zM3.777 22.1a4.332 4.332 0 0 1-2.484-1.39 1.54 1.54 0 0 1 0-2.414L6 13.586 7.414 15l-4.492 4.492a5.156 5.156 0 0 0 .676.5l4.4-4.4L9.414 17l-4.707 4.707a1.269 1.269 0 0 1-.93.393z"
          fill="currentColor"
        />
        <path
          d="M9.989 23.532a2.468 2.468 0 0 1-1.7-.825 2.778 2.778 0 0 1 0-3.414L10.586 17l-3.293-3.293a1 1 0 0 1-.017-1.4L13.958 5.3a1 1 0 0 1 1.4-.043l3.612 3.354 1.318-1.318a2.108 2.108 0 0 1 2.121-.308 2.458 2.458 0 0 1 1.645 1.338 1.715 1.715 0 0 1-.352 2.384l-4 4c-1.118 1.12-2.9-.482-3.414-1L14.5 11.914l-.793.793a1.7 1.7 0 0 0 .055.655l2.363 2.558a1.954 1.954 0 0 1 .557 1.382 1.985 1.985 0 0 1-.586 1.416c-.073.068-3.356 3-4.406 4.006a2.479 2.479 0 0 1-1.701.808zM9.4 12.983l3.31 3.31a1 1 0 0 1 0 1.414l-3 3a1.7 1.7 0 0 0 .055.655.829.829 0 0 0 .27.17c.031-.02.175-.157.253-.231 1.1-1.047 4.3-3.914 4.438-4.035l-2.458-2.589a2.775 2.775 0 0 1 .029-3.384l1-1a1.539 1.539 0 0 1 2.414 0l2 2a5.146 5.146 0 0 0 .891.694l3.695-3.694a.961.961 0 0 1-.107-.271 1.879 1.879 0 0 0-.62-.172l-1.861 1.86a1 1 0 0 1-1.388.025l-3.596-3.341zM7.044 9.449a2.562 2.562 0 0 1-1.751-.742 2.778 2.778 0 0 1 0-3.414l4-4c1.119-1.121 2.9.482 3.414 1L14.414 4 13 5.414l-1.707-1.707a5.146 5.146 0 0 0-.891-.694L6.707 6.707a1.854 1.854 0 0 0 .04.619.346.346 0 0 0 .546-.033L10 4.586 11.414 6 8.707 8.707a2.315 2.315 0 0 1-1.663.742z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PersonRunningIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PersonRunningIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PersonRunningIcon;

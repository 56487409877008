import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import DefinitionListItem from "./DefinitionListItem";

/**
 * A display pattern for data that comes in pairs
 *
 * @visibleName Definition list
 * */
const DefinitionList = props => {
  const {
    small,
    vertical,
    responsive,
    color,
    className,
    children,
    ...other
  } = props;

  return (
    <ul
      className={clsx(
        "em-c-definition-list",
        {
          "em-c-definition-list--small": small,
          "em-c-definition-list--vertical": vertical,
          "em-c-definition-list--responsive": responsive
        },
        className
      )}
      {...other}
    >
      {React.Children.toArray(children).map(c =>
        React.cloneElement(c, { color })
      )}
    </ul>
  );
};

DefinitionList.Item = DefinitionListItem;

DefinitionList.propTypes = {
  /** Use for a more compact list style */
  small: PropTypes.bool,
  /** Use to present the key/value pairs in a single column instead of the default two-column display. */
  vertical: PropTypes.bool,
  /** Use to present the key/value pairs in a single column on small viewports and in two columns on wider screens. */
  responsive: PropTypes.bool,
  /** Theme color of the list items */
  color: PropTypes.oneOf(["default", "main", "accent"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

DefinitionList.defaultProps = {
  small: false,
  vertical: false,
  responsive: false,
  color: "default",
  className: "",
  children: null
};

export default DefinitionList;

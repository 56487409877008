import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TileHeadlineIcon = props => {
  const { children } = props;

  return children
    ? React.cloneElement(children, {
        className: clsx("em-c-tile__icon", children.props.className),
        size: "medium"
      })
    : "";
};

TileHeadlineIcon.propTypes = {
  /** React component children */
  children: PropTypes.node
};

TileHeadlineIcon.defaultProps = {
  children: null
};

export default TileHeadlineIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import Logo from "../Logo";
import FooterLink from "./FooterLink";

function getVariantClass(type) {
  switch (type) {
    case "horizontal":
      return "em-c-multicolumn-nav--three";
    case "3-column":
      return "em-c-multicolumn-nav--three";
    default:
      return "";
  }
}

/** Logo and housekeeping links displayed at page bottom */
const Footer = props => {
  const { variant, hideLogo, className, children, ...other } = props;

  return (
    <footer
      className={clsx("em-c-footer", className)}
      role="contentinfo"
      {...other}
    >
      <div className="em-l-container">
        <div className="em-c-footer__inner">
          <ul
            className={clsx("em-c-multicolumn-nav", getVariantClass(variant))}
            role="navigation"
          >
            {children}
          </ul>
          {!hideLogo && <Logo />}
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  /** Column Layout */
  variant: PropTypes.oneOf(["default", "horizontal", "3-column"]), // eslint-disable-line
  /** Option to hide ExxonMobil logo */
  hideLogo: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case footerlinks usually */
  children: PropTypes.node
};

Footer.defaultProps = {
  variant: "default",
  hideLogo: false,
  className: "",
  children: null
};

Footer.Link = FooterLink;

export default Footer;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getVariant(variant) {
  switch (variant) {
    case "avatar":
      return "em-c-avatar photo";
    default:
      return "";
  }
}

const MediaBlockMediaImage = props => {
  const { src, variant, className, ...other } = props;

  return (
    <img
      src={src}
      className={clsx("em-c-media-block__img", getVariant(variant), className)}
      {...other}
    />
  );
};

MediaBlockMediaImage.propTypes = {
  /** Address of image to be displayed */
  src: PropTypes.string,
  /** Ability to specify if this is an avatar */
  variant: PropTypes.oneOf(["default", "avatar"]),
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string
};

MediaBlockMediaImage.defaultProps = {
  src: "default",
  src: "",
  className: ""
};

export default MediaBlockMediaImage;

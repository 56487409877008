import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PersonWalkingIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 4a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM5.5 16A1.5 1.5 0 0 1 4 14.5v-4h1v4a.5.5 0 0 0 1 0v-2.465a1.5 1.5 0 0 1 .668-1.248l2.109-1.406A.5.5 0 0 0 9 8.965V6.5a.5.5 0 0 0-.5-.5H8V5h.5A1.5 1.5 0 0 1 10 6.5v2.465a1.5 1.5 0 0 1-.668 1.248l-2.109 1.406a.5.5 0 0 0-.223.416V14.5A1.5 1.5 0 0 1 5.5 16z"
          fill="currentColor"
        />
        <path
          d="M5.5 9.5A1.5 1.5 0 0 1 4 8V6.5a1.5 1.5 0 0 1 3 0V8a1.5 1.5 0 0 1-1.5 1.5zm0-3.5a.5.5 0 0 0-.5.5V8a.5.5 0 0 0 1 0V6.5a.5.5 0 0 0-.5-.5zM9.953 15.893a1.508 1.508 0 0 1-.469-.075 1.49 1.49 0 0 1-.871-.748l-.9-1.784.893-.452.9 1.784a.492.492 0 0 0 .29.25.5.5 0 0 0 .381-.029.5.5 0 0 0 .221-.672L9.042 11.49l.893-.452 1.355 2.676a1.5 1.5 0 0 1-1.337 2.178z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M23 11a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5zm0-8a3 3 0 1 0 3 3 3 3 0 0 0-3-3zM28.037 48a2.921 2.921 0 0 1-1.419-.37 2.96 2.96 0 0 1-1.421-1.8l-2.159-7.642 1.925-.544 2.159 7.643a.973.973 0 0 0 .466.594.929.929 0 0 0 .713.081.992.992 0 0 0 .661-1.227l-3.238-11.463 1.925-.544 3.238 11.464a3 3 0 0 1-2.026 3.69 2.959 2.959 0 0 1-.824.118z"
          fill="currentColor"
        />
        <path
          d="M19 47.5a3.019 3.019 0 0 1-3-3.031V31.777h2v12.692a1 1 0 1 0 2 0v-9.575a3.025 3.025 0 0 1 1.328-2.512l.658-.445A9.18 9.18 0 0 0 26 24.314v-8.783a1.017 1.017 0 0 0-1-1.031h-2v-2h2a3.019 3.019 0 0 1 3 3.031v8.783a11.178 11.178 0 0 1-4.894 9.279l-.658.446a1.029 1.029 0 0 0-.448.855v9.575a3.019 3.019 0 0 1-3 3.031z"
          fill="currentColor"
        />
        <path
          d="M19 31a3 3 0 0 1-3-3V16a3 3 0 0 1 6 0v12a3 3 0 0 1-3 3zm0-16a1 1 0 0 0-1 1v12a1 1 0 0 0 2 0V16a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16 24a4.062 4.062 0 0 1-2.707-1.293 1 1 0 0 1-.242-.391l-1-3 1.9-.633.92 2.76a2.289 2.289 0 0 0 1.027.539l.04-.016-1.884-5.65 1.9-.633 2 6c.237.824-.088 1.822-1.673 2.277A.978.978 0 0 1 16 24z"
          fill="currentColor"
        />
        <path
          d="M9 24H8a1.959 1.959 0 0 1-2-2v-5h2v5h1v-4.965a1 1 0 0 1 .988-1C11.914 16.013 12 12.04 12 12V8h2v4c0 1.971-.66 5.158-3 5.885V22a1.959 1.959 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M8 16a1 1 0 0 1-.408-.087C7.218 15.746 6 15.114 6 14V9c0-1.5 2.365-2 3-2a1 1 0 0 1 .408.087C9.782 7.254 11 7.886 11 9v5c0 1.5-2.365 2-3 2zm0-2.17a1.7 1.7 0 0 0 .2.145A3.45 3.45 0 0 0 9 13.7V9.17a1.7 1.7 0 0 0-.2-.145A3.45 3.45 0 0 0 8 9.3zM10.5 7a3.5 3.5 0 0 1-2.475-5.975 3.584 3.584 0 0 1 4.95 0 3.5 3.5 0 0 1 0 4.949A3.477 3.477 0 0 1 10.5 7zm0-5a1.5 1.5 0 0 0-1.061 2.561 1.536 1.536 0 0 0 2.121 0A1.5 1.5 0 0 0 10.5 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PersonWalkingIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PersonWalkingIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PersonWalkingIcon;

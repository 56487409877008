import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ButtonBarItem from "./ButtonBarItem";

/**
 * Segmented control of connected buttons, optionally separated into groups.
 *
 * @visibleName Button bar
 */
const ButtonBar = props => {
  const { noWrap, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-c-btn-bar",
        {
          "em-c-btn-bar--no-wrap": noWrap
        },
        className
      )}
      {...other}
    >
      <ul className="em-c-btn-bar__list ">{children}</ul>
    </div>
  );
};

ButtonBar.Item = ButtonBarItem;

ButtonBar.propTypes = {
  /** Enable to prevent the bar from wrapping */
  noWrap: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Child React components, in this case 2 buttons */
  children: PropTypes.node
};

ButtonBar.defaultProps = {
  noWrap: false,
  className: "",
  children: null
};

export default ButtonBar;

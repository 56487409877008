import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** FooterLink are the links to put in the Footer. Should not use used outside the footer. */
const FooterLink = props => {
  const { className, children, ...other } = props;

  return (
    <li className={clsx("em-c-multicolumn-nav__item", className)} {...other}>
      <div className="em-c-multicolumn-nav__link">{children}</div>
    </li>
  );
};

FooterLink.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node
};

FooterLink.defaultProps = {
  className: "",
  children: null
};

export default FooterLink;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const VideoCameraIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 13h-10a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5zM2 12h9V4H2z"
          fill="currentColor"
        />
        <path
          d="M15.5 11.8a.5.5 0 0 1-.205-.044l-4-1.8A.5.5 0 0 1 11 9.5v-3a.5.5 0 0 1 .295-.456l4-1.8A.5.5 0 0 1 16 4.7v6.6a.5.5 0 0 1-.5.5zM12 9.177l3 1.35V5.474l-3 1.35z"
          fill="currentColor"
        />
        <circle cx={3.5} cy={5.5} r={0.5} fill="#112" />
        <path d="M3.5 5a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z" fill="currentColor" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M32 41H4a3 3 0 0 1-3-3V12a3 3 0 0 1 3-3h28a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3zM4 11a1 1 0 0 0-1 1v26a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1V12a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          d="M46 36a1 1 0 0 1-.447-.105l-12-6A1 1 0 0 1 33 29V19a1 1 0 0 1 .553-.895l12-6A1 1 0 0 1 47 13v22a1 1 0 0 1-1 1zm-11-7.618l10 5V14.618l-10 5zM9.5 21a3.5 3.5 0 1 1 3.5-3.5A3.5 3.5 0 0 1 9.5 21zm0-5a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 9.5 16z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M16 21H2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zM2 5v14h14V5z"
          fill="currentColor"
        />
        <path
          d="M23 19a1 1 0 0 1-.447-.105l-6-3A1 1 0 0 1 16 15V9a1 1 0 0 1 .553-.895l6-3A1 1 0 0 1 24 6v12a1 1 0 0 1-1 1zm-5-4.618l4 2V7.618l-4 2zM4 7h3v3H4z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

VideoCameraIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

VideoCameraIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default VideoCameraIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SearchIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M6.5 13A6.5 6.5 0 0 1 1.9 1.9a6.5 6.5 0 0 1 9.192 0A6.5 6.5 0 0 1 6.5 13zm0-11a4.5 4.5 0 1 0 3.182 1.318A4.469 4.469 0 0 0 6.5 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M9.586 10L11 8.586 16.414 14 15 15.415z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M20 40A18 18 0 0 1 7.272 9.272a18 18 0 1 1 25.456 25.456A17.88 17.88 0 0 1 20 40zm0-34a16 16 0 1 0 11.314 4.687A15.894 15.894 0 0 0 20 6z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M31.586 33l1.415-1.414L47.415 46l-1.414 1.415z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9.5 19a9.5 9.5 0 1 1 6.717-16.218A9.5 9.5 0 0 1 9.5 19zm0-17a7.5 7.5 0 0 0-5.3 12.8A7.5 7.5 0 0 0 14.8 4.2 7.452 7.452 0 0 0 9.5 2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M14.585 15L16 13.585 23.414 21l-1.415 1.415z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SearchIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SearchIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SearchIcon;

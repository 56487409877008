import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const WindowsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 13H.5a.5.5 0 0 1-.5-.5v-11A.5.5 0 0 1 .5 1h13a.5.5 0 0 1 .5.5v11a.5.5 0 0 1-.5.5zM1 12h12V2H1z"
          fill="currentColor"
        />
        <path
          d="M15.5 15H2v-1h13V5h1v9.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <circle cx={2.5} cy={3.5} r={0.5} fill="currentColor" />
        <circle cx={4.5} cy={3.5} r={0.5} fill="currentColor" />
        <circle cx={6.5} cy={3.5} r={0.5} fill="currentColor" />
        <path fill="currentColor" d="M0 5h13v1H0z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M42 40H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h40a2 2 0 0 1 2 2v32a2 2 0 0 1-2 2zM2 6v32h40V6z"
          fill="currentColor"
        />
        <path
          d="M46 44H4v-2h42V8h2v34a2 2 0 0 1-2 2zM7 14a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM14 14a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM21 14a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M0 16h44v2H0z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 20H1a1 1 0 0 1-1-1V4.015A2.017 2.017 0 0 1 2.015 2h16.97A2.017 2.017 0 0 1 21 4.015V19a1 1 0 0 1-1 1zM2 18h17V4.015L2.015 4z"
          fill="currentColor"
        />
        <path
          d="M23 23H4v-2h18V7h2v15a1 1 0 0 1-1 1zM5 7.5A1.5 1.5 0 1 1 6.5 6 1.5 1.5 0 0 1 5 7.5zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M8 7.5A1.5 1.5 0 1 1 9.5 6 1.5 1.5 0 0 1 8 7.5zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
        <path
          d="M11 7.5A1.5 1.5 0 1 1 12.5 6 1.5 1.5 0 0 1 11 7.5zm0-2a.5.5 0 1 0 .5.5.5.5 0 0 0-.5-.5zM0 9h21v2H0z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

WindowsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

WindowsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default WindowsIcon;

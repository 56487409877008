import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DeviceTvIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 13H.5a.5.5 0 0 1-.5-.5v-10A.5.5 0 0 1 .5 2h15a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5zM1 12h14V3H1zM2 14h12v1H2z"
          fill="currentColor"
        />
        <path
          d="M6.5 10a.5.5 0 0 1-.5-.5V5.3a.5.5 0 0 1 .752-.432l3.6 2.1a.5.5 0 0 1 0 .863l-3.6 2.1A.5.5 0 0 1 6.5 10zM7 6.171V8.63L9.108 7.4z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M45 40H3a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h42a3 3 0 0 1 3 3v30a3 3 0 0 1-3 3zM3 6a1 1 0 0 0-1 1v30a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V7a1 1 0 0 0-1-1zM4 42h40v2H4z"
          fill="currentColor"
        />
        <path
          d="M19.5 29.5a1 1 0 0 1-1-1v-13a1 1 0 0 1 1.509-.861l11 6.5a1 1 0 0 1 0 1.723l-11 6.5a1.008 1.008 0 0 1-.509.138zm1-12.247v9.494L28.534 22z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 20H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2zM2 4v14h20V4zM1 21h22v2H1z"
          fill="currentColor"
        />
        <path
          d="M9 16a1 1 0 0 1-1-1V7a1 1 0 0 1 1.5-.868l7 4a1 1 0 0 1 0 1.736l-7 4A1 1 0 0 1 9 16zm1-7.276v4.553L13.984 11z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DeviceTvIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DeviceTvIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DeviceTvIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const PowerOnIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M12 7h1v3h-1z" />
        <path
          d="M14.5 13.5h-13a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm-7-1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1z"
          fill="currentColor"
        />
        <path
          d="M14.5 14h-13A1.5 1.5 0 0 1 0 12.5v-8A1.5 1.5 0 0 1 1.5 3h13A1.5 1.5 0 0 1 16 4.5v8a1.5 1.5 0 0 1-1.5 1.5zM1.5 4a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-8a.5.5 0 0 0-.5-.5h-1A1.5 1.5 0 0 1 15 5.5v6a1.5 1.5 0 0 1-1.5 1.5h-6A1.5 1.5 0 0 1 6 11.5v-6A1.5 1.5 0 0 1 7.5 4zm6 1a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 12H8a6 6 0 0 0-6 6v14a6 6 0 0 0 6 6h32a6 6 0 0 0 6-6V18a6 6 0 0 0-6-6zm0 24H28a4.012 4.012 0 0 1-4-4V18a4.012 4.012 0 0 1 4-4h12a4.012 4.012 0 0 1 4 4v14a4.012 4.012 0 0 1-4 4z"
          fill="currentColor"
        />
        <path
          d="M40 39H8a7.008 7.008 0 0 1-7-7V18a7.008 7.008 0 0 1 7-7h32a7.008 7.008 0 0 1 7 7v14a7.008 7.008 0 0 1-7 7zM8 13a5.006 5.006 0 0 0-5 5v14a5.006 5.006 0 0 0 5 5h32a5.006 5.006 0 0 0 5-5 5.006 5.006 0 0 1-5 5H28a5.006 5.006 0 0 1-5-5V18a5.006 5.006 0 0 1 5-5zm20 2a3 3 0 0 0-3 3v14a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V18a3 3 0 0 0-3-3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M39 18h2v14h-2z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20 4H4a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V7a3 3 0 0 0-3-3zm0 13h-6a2.006 2.006 0 0 1-2-2V8a2.006 2.006 0 0 1 2-2h6a2.006 2.006 0 0 1 2 2v7a2.006 2.006 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M20 20H4a4 4 0 0 1-4-4V7a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v9a4 4 0 0 1-4 4zM4 5a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h10a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3zm10 2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M17 8h2v7h-2z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

PowerOnIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

PowerOnIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default PowerOnIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaControlPauseIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 1a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"
          fill="currentColor"
        />
        <path
          d="M6.5 12a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 1 0v7a.5.5 0 0 1-.5.5zM9.5 12a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 1 0v7a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 48a24 24 0 1 1 24-24 24.027 24.027 0 0 1-24 24zm0-46a22 22 0 1 0 22 22A22.025 22.025 0 0 0 24 2z"
          fill="currentColor"
        />
        <path
          d="M20 36h-4a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2zm-4-22v20h4V14zM32 36h-4a2 2 0 0 1-2-2V14a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v20a2 2 0 0 1-2 2zm-4-22v20h4V14z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M10 18H7a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zm-2-2h1V8H8zM17 18h-3a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zm-2-2h1V8h-1z"
          fill="currentColor"
        />
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MediaControlPauseIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MediaControlPauseIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MediaControlPauseIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const UndoIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 13H4v-1h8.5a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5H2V4h10.5A1.5 1.5 0 0 1 14 5.5v6a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M4.5 8.206L1.146 4.854a.5.5 0 0 1 0-.707L4.5.793l.707.707-3 3 3 3z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M28.477 40H18v-2h10.477A10.535 10.535 0 0 0 39 27.477v-.953A10.535 10.535 0 0 0 28.477 16H13v-2h15.477A12.538 12.538 0 0 1 41 26.523v.953A12.538 12.538 0 0 1 28.477 40z"
          fill="currentColor"
        />
        <path
          d="M16.387 24.414l-7.126-7.3a3.043 3.043 0 0 1 0-4.225l7.126-7.3 1.432 1.4-7.126 7.3a1.03 1.03 0 0 0 0 1.432l7.126 7.3z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14.238 20H8v-2h6.238A4.767 4.767 0 0 0 19 13.238v-.477A4.767 4.767 0 0 0 14.238 8H8V6h6.238A6.769 6.769 0 0 1 21 12.762v.477A6.769 6.769 0 0 1 14.238 20z"
          fill="currentColor"
        />
        <path
          d="M8.539 13.414L3.68 8.816a2.145 2.145 0 0 1 0-3.132l4.86-4.6 1.374 1.455-4.859 4.6a.145.145 0 0 0 0 .228l4.858 4.6z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

UndoIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

UndoIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default UndoIcon;

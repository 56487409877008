import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TableIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 16h-14a.5.5 0 0 1-.5-.5v-14a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 .5.5v14a.5.5 0 0 1-.5.5zM2 15h13V2H2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M3 13h11v1H3zM3 11h11v1H3zM3 9h11v1H3z" />
        <path fill="currentColor" d="M5 5h1v9H5z" />
        <path fill="currentColor" d="M3 7h11v1H3zM3 5h11v1H3z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M41 45H6a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h35a3 3 0 0 1 3 3v36a3 3 0 0 1-3 3zM6 5a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h35a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M8 39h31v2H8zM8 35h31v2H8zM8 31h31v2H8zM8 27h31v2H8zM8 23h31v2H8zM8 19h31v2H8zM8 15h31v2H8zM8 11h31v2H8z"
        />
        <path
          fill="currentColor"
          d="M12 11h2v30h-2zM20 11h2v30h-2zM33 11h2v30h-2z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 23H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v18a2 2 0 0 1-2 2zM2 3v18h20V3z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4 17h16v2H4zM4 13h16v2H4zM4 9h16v2H4z" />
        <path fill="currentColor" d="M7 6h2v13H7z" />
        <path fill="currentColor" d="M4 5h16v2H4z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TableIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TableIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TableIcon;

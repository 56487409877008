import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CircleQuestionMarkIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M9 10H8V8.3a1.292 1.292 0 0 1 .875-1.167 1.154 1.154 0 0 0 .678-.633 1.205 1.205 0 0 0-.019-.993.993.993 0 0 0-.5-.5 1.183 1.183 0 0 0-1.16.071 1.149 1.149 0 0 0-.521.963v.5h-1v-.5a2.146 2.146 0 0 1 .973-1.8 2.186 2.186 0 0 1 2.103-.151 1.992 1.992 0 0 1 1.022 1.022 2.217 2.217 0 0 1 .017 1.793A2.158 2.158 0 0 1 9.2 8.074.31.31 0 0 0 9 8.3z"
          fill="currentColor"
        />
        <circle cx={8.5} cy={11.75} r={0.75} fill="currentColor" />
        <path
          d="M8.5 11a.75.75 0 1 0 .75.75.75.75 0 0 0-.75-.75z"
          fill="currentColor"
        />
        <path
          d="M8.5 16A7.5 7.5 0 0 1 3.2 3.2 7.5 7.5 0 1 1 8.5 16zm0-14a6.458 6.458 0 0 0-4.6 1.9A6.5 6.5 0 1 0 8.5 2zM3.55 3.55z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 27a1 1 0 0 1-1-1v-3.118a3.343 3.343 0 0 1 2.251-3.032 4.014 4.014 0 0 0 2.4-2.167 4.226 4.226 0 0 0-.044-3.445 3.5 3.5 0 0 0-1.8-1.8 4.159 4.159 0 0 0-4 .26A3.993 3.993 0 0 0 20 16.049a1 1 0 0 1-2 0 5.987 5.987 0 0 1 2.713-5.02 6.121 6.121 0 0 1 5.895-.422 5.522 5.522 0 0 1 2.835 2.834 6.2 6.2 0 0 1 .035 5.06 6.016 6.016 0 0 1-3.6 3.248A1.365 1.365 0 0 0 25 22.882V26a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
        <path
          d="M24 46.989A22.992 22.992 0 0 1 7.737 7.737 23 23 0 1 1 24 46.989zM24 3A20.862 20.862 0 0 0 9.151 9.151a21 21 0 0 0 29.7 29.7A21 21 0 0 0 24 3zM8.444 8.444z"
          fill="currentColor"
        />
        <path d="M24 31a2 2 0 1 0 2 2 2 2 0 0 0-2-2z" fill="currentColor" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12 23.994A11.994 11.994 0 0 1 3.515 3.515 12 12 0 1 1 12 23.994zM4.929 4.929a10 10 0 1 0 14.143 0 10.013 10.013 0 0 0-14.143 0z"
          fill="currentColor"
        />
        <circle cx={12} cy={18} r={1} fill="currentColor" />
        <path
          d="M12 17a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM12 13a1 1 0 0 1 0-2 2 2 0 1 0-2-2 1 1 0 0 1-2 0 4 4 0 1 1 4 4z"
          fill="currentColor"
        />
        <path
          d="M12 15a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CircleQuestionMarkIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CircleQuestionMarkIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CircleQuestionMarkIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const FilePdf1Icon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M12.5 15h-9A1.5 1.5 0 0 1 2 13.5v-11A1.5 1.5 0 0 1 3.5 1h6.349a1.491 1.491 0 0 1 1.063.441L13.563 4.1A1.49 1.49 0 0 1 14 5.163V13.5a1.5 1.5 0 0 1-1.5 1.5zm-9-13a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V5.163a.5.5 0 0 0-.146-.353L10.2 2.147A.5.5 0 0 0 9.849 2z"
          fill="currentColor"
        />
        <path
          d="M5.078 11.649a.67.67 0 0 1-.481-.195.786.786 0 0 1-.212-.829c.111-.312.408-.648 1.537-.918A6 6 0 0 0 6.6 8.371a13.467 13.467 0 0 0 .472-1.615c-.7-1.406-.5-1.777-.412-1.944A.81.81 0 0 1 7.992 4.8a2.622 2.622 0 0 1 .134 1.8c.219.407.509.9.885 1.474A8.589 8.589 0 0 0 10 9.33c1.137.073 1.453.384 1.581.651a.759.759 0 0 1-.785 1.05 2.453 2.453 0 0 1-1.217-.717 14.944 14.944 0 0 0-1.662.09c-.6.061-1.058.13-1.417.2a2.687 2.687 0 0 1-1.25 1.024.8.8 0 0 1-.172.021zM7.82 9.41l.051.5-.051-.5q.475-.048.872-.072c-.2-.249-.375-.5-.518-.715S7.9 8.2 7.779 8c-.079.256-.159.495-.233.7a6.732 6.732 0 0 1-.334.774c.188-.018.388-.041.608-.064z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M40 46H10a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3h20.343a4.967 4.967 0 0 1 3.535 1.464l7.657 7.657A4.967 4.967 0 0 1 43 16.657V43a3 3 0 0 1-3 3zM10 6a1 1 0 0 0-1 1v36a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V16.657a3.022 3.022 0 0 0-.878-2.121l-7.658-7.658A3.022 3.022 0 0 0 30.343 6z"
          fill="currentColor"
        />
        <path
          d="M17.2 36.5a1.5 1.5 0 0 1-1.089-.449 1.872 1.872 0 0 1-.508-1.989c.288-.8 1.125-1.738 4.465-2.514a19.081 19.081 0 0 0 2.207-4.373 41.6 41.6 0 0 0 1.481-5.2c-1.4-2.8-1.843-4.695-1.326-5.642a1.633 1.633 0 0 1 1.489-.838 1.963 1.963 0 0 1 1.641.848c.763 1.074.686 3.153.295 5.321.7 1.342 1.629 2.936 2.776 4.72a26.347 26.347 0 0 0 3.2 4.055c3.791.2 4.3 1.266 4.508 1.7a1.787 1.787 0 0 1-1.845 2.472 7.13 7.13 0 0 1-3.526-2.2 43.364 43.364 0 0 0-5.246.279c-1.9.2-3.364.425-4.492.652-1.24 1.67-2.571 2.876-3.6 3.107a1.919 1.919 0 0 1-.43.051zm17.362-3.448zm-9.385-8.461a50.92 50.92 0 0 1-1.015 3.244 20.658 20.658 0 0 1-1.462 3.222 62.05 62.05 0 0 1 2.812-.353 55.662 55.662 0 0 1 3.627-.27 30.77 30.77 0 0 1-2.191-2.964q-.985-1.537-1.767-2.878z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M6.26 19.072a1.314 1.314 0 0 1-.946-.378 1.413 1.413 0 0 1-.345-1.5c.292-.821 1.344-1.47 3.14-1.941a8.8 8.8 0 0 0 1.223-2.243 22.68 22.68 0 0 0 .861-3.11c-.67-1.529-1.146-3.148-.678-4.014a1.379 1.379 0 0 1 1.235-.724 1.519 1.519 0 0 1 1.268.666c.563.8.508 2.316.257 3.809a23.524 23.524 0 0 0 1.555 2.821 11.987 11.987 0 0 0 1.713 2.042c1.828.115 2.94.574 3.32 1.374a1.415 1.415 0 0 1-.313 1.638 1.456 1.456 0 0 1-1.211.4 5.524 5.524 0 0 1-2.6-1.433 23.971 23.971 0 0 0-2.706.143 26.655 26.655 0 0 0-2.787.417 5.9 5.9 0 0 1-2.623 2 1.665 1.665 0 0 1-.363.033zm5.31-6.487a24.988 24.988 0 0 1-.353 1.095 9.617 9.617 0 0 1-.459 1.074q.514-.068 1.073-.125.541-.056 1.041-.091c-.262-.327-.5-.658-.715-.982a23.54 23.54 0 0 1-.588-.971z"
          fill="currentColor"
        />
        <path
          d="M19.875 24H4.125A2.115 2.115 0 0 1 2 21.9V2.1A2.115 2.115 0 0 1 4.125 0h10.318a3.258 3.258 0 0 1 2.29.929l4.307 4.212a3.2 3.2 0 0 1 .96 2.27V21.9a2.115 2.115 0 0 1-2.125 2.1zM4.125 2A.116.116 0 0 0 4 2.1v19.8a.116.116 0 0 0 .125.1h15.75a.116.116 0 0 0 .125-.1V7.411a1.167 1.167 0 0 0-.358-.84l-4.307-4.212A1.287 1.287 0 0 0 14.443 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

FilePdf1Icon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

FilePdf1Icon.defaultProps = {
  size: "medium",

  className: ""
};

export default FilePdf1Icon;

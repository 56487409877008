import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GiftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 7h-13a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM2 6h12V5H2zM13.5 14h-11a.5.5 0 0 1-.5-.5V7h1v6h10V7h1v6.5a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M6 4h1v3H6zM9 4h1v3H9zM6 7h1v7H6zM9 7h1v7H9z"
        />
        <path
          d="M3.743 4.926c-.785-1.085-.864-2.268-.2-2.943.8-.792 2.322-.512 3.469.634a3.6 3.6 0 0 1 1.056 1.94l-.988.153a2.635 2.635 0 0 0-.771-1.385c-.791-.789-1.718-.971-2.055-.634-.293.3-.165 1.007.3 1.65z"
          fill="currentColor"
        />
        <path
          d="M12.257 4.926l-.811-.586c.465-.643.593-1.354.3-1.654-.336-.331-1.261-.151-2.051.638A2.635 2.635 0 0 0 8.92 4.71l-.988-.153a3.6 3.6 0 0 1 1.052-1.939c1.146-1.147 2.668-1.427 3.465-.638.672.679.593 1.861-.192 2.946z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M46 20H4a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1h42a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1zM5 18h40v-4H5zM40 44H10a3 3 0 0 1-3-3V22h2v19a1 1 0 0 0 1 1h30a1 1 0 0 0 1-1V22h2v19a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M21 12h2v8h-2zM27 12h2v8h-2zM21 22h2v22h-2zM27 22h2v22h-2z"
        />
        <path
          d="M10.47 13.064C8.154 9.679 7.862 5.9 9.76 3.865A4.574 4.574 0 0 1 13.172 2.5h.016a10.361 10.361 0 0 1 7.057 3.561 12.09 12.09 0 0 1 3.245 6.293l-1.979.291a10.067 10.067 0 0 0-2.72-5.211A8.493 8.493 0 0 0 13.183 4.5a2.643 2.643 0 0 0-1.969.738c-1.226 1.313-.848 4.132.906 6.7zM37.873 13.093l-1.611-1.186c1.875-2.547 2.281-5.344.967-6.651-1.649-1.6-5.462-.6-8.315 2.205a9.821 9.821 0 0 0-2.926 5.195l-1.975-.312a11.841 11.841 0 0 1 3.5-6.309c3.734-3.668 8.616-4.638 11.12-2.2 2.061 2.047 1.747 5.853-.76 9.258z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M23 12H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zM2 10h20V8H2z"
          fill="currentColor"
        />
        <path
          d="M20 24H4c-1.907 0-2-.9-2-2.5v-10h2V22h16V12h2v10a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M9 7h2v5H9zM13 6.5h2v5h-2z" />
        <path
          fill="currentColor"
          d="M9 11.5h2v12H9zM13 11.5h2v12h-2zM5.293 7.707c-1.332-1.332-2.522-3.892-1-5.414a3.085 3.085 0 0 1 3.358-.666 7.234 7.234 0 0 1 4.342 5.257l-1.986.23a5.318 5.318 0 0 0-3.013-3.6 1.105 1.105 0 0 0-1.287.19c-.378.378.146 1.731 1 2.586zM19.707 7.707l-1.414-1.414a2.105 2.105 0 0 0 .25-2.586c-.122-.122-.49-.491-1.4-.175a5.433 5.433 0 0 0-3.154 3.583l-1.986-.23a7.331 7.331 0 0 1 4.487-5.241 3.182 3.182 0 0 1 3.467.649c1.454 1.454 1.06 4.107-.25 5.414z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GiftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GiftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default GiftIcon;

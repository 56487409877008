import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TicketIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 13h-13a.5.5 0 0 1-.5-.5V9.41a.5.5 0 0 1 .337-.473.994.994 0 0 0 0-1.875A.5.5 0 0 1 1 6.59V3.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 .5.5v3.09a.5.5 0 0 1-.337.473.994.994 0 0 0 0 1.875.5.5 0 0 1 .337.472v3.09a.5.5 0 0 1-.5.5zM2 12h12V9.729a2 2 0 0 1 0-3.459V4H2v2.271a2 2 0 0 1 0 3.458z"
          fill="currentColor"
        />
        <path
          d="M11.5 11h-7a.5.5 0 0 1-.5-.5v-5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-.5.5zM5 10h6V6H5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M47 40H1a1 1 0 0 1-1-1V28.9a1 1 0 0 1 .8-.98A4.007 4.007 0 0 0 4 24a4.006 4.006 0 0 0-3.2-3.92 1 1 0 0 1-.8-.98V9a1 1 0 0 1 1-1h46a1 1 0 0 1 1 1v10.1a1 1 0 0 1-.8.98 4 4 0 0 0 0 7.84 1 1 0 0 1 .8.98V39a1 1 0 0 1-1 1zM2 38h44v-8.341a6 6 0 0 1 0-11.318V10H2v8.341A6.018 6.018 0 0 1 6 24a6.02 6.02 0 0 1-4 5.659z"
          fill="currentColor"
        />
        <path
          d="M37 34H11a1 1 0 0 1-1-1V15a1 1 0 0 1 1-1h26a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-25-2h24V16H12z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M14 20h20v2H14zM14 25h20v2H14z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M23 20H1a1 1 0 0 1-1-1v-5a1 1 0 0 1 .8-.98c.536-.108.7-.141.7-1.02s-.162-.911-.7-1.02A1 1 0 0 1 0 10V5a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v5a1 1 0 0 1-.8.98c-1.2.243-1.2.729-1.2 1.02s0 .777 1.2 1.02a1 1 0 0 1 .8.98v5a1 1 0 0 1-1 1zM2 18h20v-3.245A2.809 2.809 0 0 1 20 12a2.809 2.809 0 0 1 2-2.755V6H2v3.271A2.684 2.684 0 0 1 3.5 12 2.684 2.684 0 0 1 2 14.729z"
          fill="currentColor"
        />
        <path
          d="M18 17H6a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zM7 15h10V9H7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

TicketIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

TicketIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default TicketIcon;

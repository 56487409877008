import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** PageBody is the body portion of a page layout */
const PageBody = props => {
  const { className, children, ...other } = props;
  return (
    <div className={clsx("page-body", className)} {...other}>
      {children}
    </div>
  );
};

PageBody.propTypes = {
  /** children will hold the header, footer, and body */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

PageBody.defaultProps = {
  children: null,
  className: ""
};

export default PageBody;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const VisibilityOffIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.56 11.018l-.685-.729.364-.343A15.249 15.249 0 0 0 14.975 8C14.219 6.988 11.4 3.55 8 3.55a5.409 5.409 0 0 0-1.362.179l-.484.125-.254-.968.484-.126A6.443 6.443 0 0 1 8 2.55c4.506 0 7.865 4.962 8.006 5.173a.5.5 0 0 1 0 .555 15.37 15.37 0 0 1-2.082 2.4zM8 13.45c-4.506 0-7.865-4.962-8.006-5.173a.5.5 0 0 1 0-.555 15.37 15.37 0 0 1 2.082-2.4l.364-.343.685.729-.364.343A15.249 15.249 0 0 0 1.025 8C1.781 9.012 4.6 12.45 8 12.45a5.422 5.422 0 0 0 1.363-.179l.484-.125.25.968-.484.126A6.443 6.443 0 0 1 8 13.45z"
          fill="currentColor"
        />
        <path
          d="M8 11a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-5a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M1.022 1.73l.708-.707 13.246 13.246-.707.707z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zm0-14a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6z"
          fill="currentColor"
        />
        <path
          d="M38.76 34.407l-1.267-1.548.774-.633a47.305 47.305 0 0 0 7.542-7.979.95.95 0 0 0 .038-1.087C43.911 20.065 36.761 10 25.77 10a19.576 19.576 0 0 0-8.345 1.943l-.905.425-.85-1.811.905-.425A21.554 21.554 0 0 1 25.77 8c12.019 0 19.7 10.784 21.766 14.09a2.932 2.932 0 0 1-.124 3.353 49.317 49.317 0 0 1-7.879 8.331zM23.714 41c-9.826 0-18.7-9.587-22.059-13.708a2.943 2.943 0 0 1-.123-3.527A39.617 39.617 0 0 1 9.718 15.2l.8-.6 1.206 1.6-.8.6a37.585 37.585 0 0 0-7.754 8.112.922.922 0 0 0 .035 1.117C5.685 29.071 14.494 39 23.714 39a16.4 16.4 0 0 0 7.809-2.192l.878-.478.955 1.758-.878.478A18.374 18.374 0 0 1 23.714 41zM3.586 5L5 3.586 44.414 43 43 44.414z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M20.415 16.914l-1.371-1.456.728-.686a22.975 22.975 0 0 0 1.991-2.135.993.993 0 0 0 0-1.274C20.317 9.6 16.471 5.5 12 5.5a8.038 8.038 0 0 0-2 .262l-.968.251-.5-1.937.968-.25A10 10 0 0 1 12 3.5c5.134 0 9.289 4.132 11.309 6.595a3.011 3.011 0 0 1 0 3.812 24.772 24.772 0 0 1-2.166 2.323zM12 20.5c-5.134 0-9.289-4.132-11.309-6.595a3.011 3.011 0 0 1 0-3.812 24.772 24.772 0 0 1 2.166-2.322l.728-.685 1.372 1.456-.728.686a22.975 22.975 0 0 0-1.991 2.135.993.993 0 0 0 0 1.274C3.683 14.4 7.529 18.5 12 18.5a8.022 8.022 0 0 0 2-.263l.968-.251.5 1.936-.968.251a10 10 0 0 1-2.5.327z"
          fill="currentColor"
        />
        <path
          d="M12 16.5a4.5 4.5 0 1 1 4.5-4.5 4.5 4.5 0 0 1-4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 12 9.5z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M1.585 3L3 1.586 22.414 21 21 22.414z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

VisibilityOffIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

VisibilityOffIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default VisibilityOffIcon;

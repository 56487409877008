import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import HorizontalHeaderNavSearchToggle from "./HorizontalHeaderNavSearchToggle";
import HorizontalHeaderNavDropdown from "./HorizontalHeaderNavDropdown";
import HorizontalHeaderNavItem from "./HorizontalHeaderNavItem";
import { Searchbar } from "../../index";

/** HeaderNav is the Unity header nav. */
const HorizontalHeaderNav = props => {
  const { className, searchbar, searchbarProps, children, ...other } = props;
  const {
    className: searchbarClassName,
    ...otherSearchBarProps
  } = searchbarProps;
  const [searchOpen, setSearchOpen] = useState(false); // TODO: open to have search start and/or stay open?

  return (
    <div {...other}>
      <nav id="nav" className={clsx("em-c-primary-nav", className)}>
        <ul className="em-c-primary-nav__list">
          {children}
          {searchbar && (
            <HorizontalHeaderNavSearchToggle
              onClick={() => setSearchOpen(prev => !prev)}
            />
          )}
        </ul>
      </nav>
      {searchOpen && (
        <div className="em-c-header__nav-container">
          <Searchbar
            className={clsx(
              "em-c-search-form",
              "em-is-active",
              searchbarClassName
            )}
            {...otherSearchBarProps}
          />
        </div>
      )}
    </div>
  );
};

HorizontalHeaderNav.propTypes = {
  /** if it has the searchbar toggle or not */
  searchbar: PropTypes.bool,
  /** Props to pass to the searchbar */
  searchbarProps: PropTypes.object,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

HorizontalHeaderNav.defaultProps = {
  searchbar: false,
  searchbarProps: {},
  className: "",
  children: null
};

HorizontalHeaderNav.Item = HorizontalHeaderNavItem;

HorizontalHeaderNav.SearchToggle = HorizontalHeaderNavSearchToggle;

HorizontalHeaderNav.Dropdown = HorizontalHeaderNavDropdown;

export default HorizontalHeaderNav;

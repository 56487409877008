import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BellLineIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 14.77a2.6 2.6 0 0 1-2.363-1.529l-.205-.456.912-.411.205.456a1.59 1.59 0 0 0 2.9 0l.205-.456.912.411-.205.456A2.6 2.6 0 0 1 8 14.77zM13.5 12h-11a.5.5 0 0 1-.5-.5V9.713a3.513 3.513 0 0 1 1.1-2.549l.112-.1A2.513 2.513 0 0 0 4 5.238V4.5A3.5 3.5 0 0 1 7.5 1h1A3.5 3.5 0 0 1 12 4.5v.72a2.515 2.515 0 0 0 .771 1.806l.151.145A3.517 3.517 0 0 1 14 9.7v1.8a.5.5 0 0 1-.5.5zM3 11h10V9.7a2.514 2.514 0 0 0-.771-1.805l-.151-.145A3.517 3.517 0 0 1 11 5.22V4.5A2.5 2.5 0 0 0 8.5 2h-1A2.5 2.5 0 0 0 5 4.5v.738a3.515 3.515 0 0 1-1.1 2.55l-.112.105A2.511 2.511 0 0 0 3 9.713z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M39 37H11a3 3 0 0 1-3-3v-4.657a7.028 7.028 0 0 1 2.2-5.1l2.853-2.683A3.01 3.01 0 0 0 14 19.376V15a11 11 0 0 1 22 0v4.293a3.013 3.013 0 0 0 .925 2.166l2.918 2.8A7.034 7.034 0 0 1 42 29.311V34a3 3 0 0 1-3 3zM25 6a9.01 9.01 0 0 0-9 9v4.376a5.02 5.02 0 0 1-1.573 3.642L11.574 25.7A5.018 5.018 0 0 0 10 29.343V34a1 1 0 0 0 1 1h28a1 1 0 0 0 1-1v-4.689a5.026 5.026 0 0 0-1.541-3.611l-2.918-2.8A5.024 5.024 0 0 1 34 19.293V15a9.01 9.01 0 0 0-9-9zM25 45c-3.757 0-6.687-1.86-7.466-4.739l-.261-.965 1.931-.521.262.965C20 41.72 22.173 43 25 43s5-1.28 5.534-3.261l.262-.965 1.931.521-.261.965C31.688 43.14 28.757 45 25 45z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.5 24A4.5 4.5 0 0 1 8 19.5v-1h2v1a2.5 2.5 0 0 0 5 0v-1h2v1a4.5 4.5 0 0 1-4.5 4.5z"
          fill="currentColor"
        />
        <path
          d="M19.866 19H5.134A2.118 2.118 0 0 1 3 16.9v-2.55a4.258 4.258 0 0 1 1.4-3.136l1.261-1.146A2.254 2.254 0 0 0 6.4 8.41V5.936A6.026 6.026 0 0 1 12.5 0a6.026 6.026 0 0 1 6.1 5.936v2.435a2.256 2.256 0 0 0 .728 1.643l1.3 1.209A4.258 4.258 0 0 1 22 14.332V16.9a2.118 2.118 0 0 1-2.134 2.1zM12.5 2a4.025 4.025 0 0 0-4.1 3.936V8.41A4.258 4.258 0 0 1 7 11.546l-1.257 1.146A2.255 2.255 0 0 0 5 14.35v2.55a.122.122 0 0 0 .134.1h14.732a.122.122 0 0 0 .134-.1v-2.568a2.257 2.257 0 0 0-.728-1.643l-1.3-1.209A4.258 4.258 0 0 1 16.6 8.371V5.936A4.025 4.025 0 0 0 12.5 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BellLineIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BellLineIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BellLineIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretRightIcon } from "../../Icons";

/** PagerNext is the pager button to navigate to the next page */
const PagerNext = props => {
  const { disabled, onChange, className, children, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-pagination__item",
        { "em-u-clickable": !disabled },
        className
      )}
      onClick={!disabled ? onChange : () => {}}
      {...other}
    >
      <div
        className={clsx("em-c-pagination__link", "em-c-pager__item", {
          "em-is-disabled": disabled
        })}
      >
        <span>{children}</span>
        <CaretRightIcon
          className="em-c-pagination__icon em-pagination-icon"
          size="small"
        />
      </div>
    </li>
  );
};

PagerNext.propTypes = {
  disabled: PropTypes.bool,
  /** function emitted on change, returns this pages inner value */
  onChange: PropTypes.func,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** child content,i.e. the text in the pager */
  children: PropTypes.node
};

PagerNext.defaultProps = {
  disabled: false,
  onChange: () => {},
  className: "",
  children: null
};

export default PagerNext;

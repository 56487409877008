import React from "react";
import PropTypes, { oneOfType } from "prop-types";
import { BanIcon, LockIcon, CircleCheckIcon, WarningIcon } from "../Icons";
import Field from "../Field";

/**
 * A multi-line text input control
 *
 * @visibleName Text area
 * */
const TextArea = props => {
  const {
    value,
    onChange,
    placeholder,
    name,
    id,
    label,
    note,
    valid,
    error,
    readOnly,
    disabled,
    className,
    children,
    ...other
  } = props;

  return (
    <Field
      className={className}
      error={error}
      valid={valid}
      disabled={disabled}
      readOnly={readOnly}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <textarea
          name={name}
          id={id}
          className="em-c-input"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          disabled={disabled}
          readOnly={readOnly}
        />
        {disabled ? (
          <BanIcon className="em-c-field__icon" size="medium" />
        ) : readOnly ? (
          <LockIcon className="em-c-field__icon" size="medium" />
        ) : valid ? (
          <CircleCheckIcon className="em-c-field__icon" size="medium" />
        ) : error ? (
          <WarningIcon className="em-c-field__icon" size="medium" />
        ) : null}
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

TextArea.propTypes = {
  /** the value of the textarea */
  value: PropTypes.string,
  /** Function that is triggered when users type */
  onChange: PropTypes.func,
  /** Placeholder text */
  placeholder: PropTypes.string,
  /** Name for accessibility reasons */
  name: PropTypes.string,
  /** Id for accessibility reasons */
  id: PropTypes.string,
  /** Label that sits above the textarea */
  label: oneOfType([PropTypes.string, PropTypes.number]),
  /** note below the field */
  note: oneOfType([PropTypes.string, PropTypes.number]),
  /** If the field is valid or not */
  valid: PropTypes.bool,
  /** If the field is error state or not */
  error: PropTypes.bool,
  /** If the field is read-only or not */
  readOnly: PropTypes.bool,
  /** if the field is disabled or not */
  disabled: PropTypes.bool,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TextArea.defaultProps = {
  value: "",
  onChange: () => {},
  placeholder: "",
  name: "",
  id: "",
  label: "",
  note: "",
  valid: false,
  error: false,
  readOnly: false,
  disabled: false,
  className: ""
};

export default TextArea;

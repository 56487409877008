import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-link-list__link--main";
    case "accent":
      return "em-c-link-list__link--accent";
    case "default":
    default:
      return "";
  }
}

const LinkListItem = props => {
  const { className, color, icon, children, ...other } = props;

  if (icon === null) {
    return (
      <li className={clsx("em-c-link-list__item", className)} {...other}>
        <div className={clsx("em-c-link-list__link", getColor(color))}>
          {children}
        </div>
      </li>
    );
  }

  return (
    <li className={clsx("em-c-link-list__item", className)} {...other}>
      <div className={clsx("em-c-link-list__link", getColor(color))}>
        {React.cloneElement(icon, {
          className: clsx(
            "em-c-btn__icon em-c-link-list__icon",
            icon.props.className
          ),
          size: "small"
        })}
        <span className="em-c-link-list__text">{children}</span>
      </div>
    </li>
  );
};

LinkListItem.propTypes = {
  /** Icon element to use. */
  icon: PropTypes.node,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the list items */
  color: PropTypes.oneOf(["default", "main", "accent"]),
  /** React component children */
  children: PropTypes.node
};

LinkListItem.defaultProps = {
  icon: null,
  className: "",
  color: "default",
  children: null
};

export default LinkListItem;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const VideoGameIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M4 5h1v5H4z" />
        <path fill="currentColor" d="M2 7h5v1H2zM7 0h1v4H7z" />
        <circle cx={10} cy={6} r={1} fill="#112" />
        <path d="M10 5a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
        <circle cx={12} cy={8} r={1} fill="#112" />
        <path d="M12 7a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
        <path
          d="M10.5 12a4.51 4.51 0 0 1-2.822-1h-.356A4.5 4.5 0 1 1 4.5 3h6a4.5 4.5 0 0 1 0 9zm-3.36-2h.72a.5.5 0 0 1 .329.123A3.5 3.5 0 1 0 10.5 4h-6a3.5 3.5 0 1 0 2.311 6.123A.5.5 0 0 1 7.14 10z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M11 25h2v10h-2z" />
        <path
          fill="currentColor"
          d="M7 29h10v2H7zM33 30a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM39 34a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM23 5h2v12h-2z"
        />
        <path
          d="M36.011 41a11.211 11.211 0 0 1-1.347-.081A11.068 11.068 0 0 1 27.516 37h-7.032a11.068 11.068 0 0 1-7.148 3.919 12.287 12.287 0 0 1-9.407-3l-.243-.255c-2.97-4.05-3.519-8.653-1.5-12.628A10.956 10.956 0 0 1 12 19h24a10.956 10.956 0 0 1 9.812 6.024c2.022 3.978 1.475 8.585-1.5 12.642l-.241.253A12.615 12.615 0 0 1 36.011 41zM5.254 36.419a10.416 10.416 0 0 0 7.839 2.515A9.058 9.058 0 0 0 19.2 35.4l.3-.4h9l.3.4a9.058 9.058 0 0 0 6.107 3.534 10.413 10.413 0 0 0 7.84-2.516c2.471-3.41 2.939-7.228 1.282-10.487A8.966 8.966 0 0 0 36 21H12a8.966 8.966 0 0 0-8.034 4.94c-1.651 3.26-1.181 7.074 1.288 10.479zm38.186.725zm0 0z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M6 11h2v4H6z" />
        <path fill="currentColor" d="M5 12h4v2H5z" />
        <circle cx={16} cy={12} r={1} fill="#112" />
        <path d="M16 11a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
        <circle cx={19} cy={14} r={1} fill="#112" />
        <path
          d="M19 13a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM12 0h2v6h-2z"
          fill="currentColor"
        />
        <path
          d="M18 19a6.33 6.33 0 0 1-4.467-2h-2.066A6.33 6.33 0 0 1 7 19a6.237 6.237 0 0 1-6-6 6.237 6.237 0 0 1 6-6h11a6.237 6.237 0 0 1 6 6 5.741 5.741 0 0 1-6 6zm-7.5-4h4l.3.4A4.319 4.319 0 0 0 18 17a3.749 3.749 0 0 0 4-4 4.214 4.214 0 0 0-4-4H7a4.214 4.214 0 0 0-4 4 4.214 4.214 0 0 0 4 4 4.319 4.319 0 0 0 3.2-1.6z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

VideoGameIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

VideoGameIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default VideoGameIcon;

import React, { useState, useEffect } from "react";
import PropTypes, { oneOfType } from "prop-types";
import clsx from "clsx";
import { UploadIcon } from "../Icons";
import Field from "../Field";

/**
 * Drag-and-drop (or click) to upload one or more files.
 *
 * @visibleName File upload
 * */
const FileUpload = props => {
  const {
    value,
    label,
    note,
    onChange,
    valid,
    error,
    readOnly,
    disabled,
    multiple,
    className,
    ...other
  } = props;

  const fileUpload = React.createRef();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    onChange(files);
  }, [files]);

  async function handleChange(e) {
    e.stopPropagation();
    setFiles([...Array.from(fileUpload.current.files)]);
  }
  return (
    <Field
      className={clsx("em-c-field--file-upload", className)}
      error={error}
      valid={valid}
      disabled={disabled}
      readOnly={readOnly}
      {...other}
    >
      {label && <Field.Label>{label}</Field.Label>}
      <Field.Body>
        <UploadIcon
          size="large"
          className="em-c-field__block-icon em-u-width-100"
        />
        <input
          value={value}
          type="file"
          title=" "
          className="em-c-file-upload"
          ref={fileUpload}
          onChange={handleChange}
          disabled={disabled}
          readOnly={readOnly}
          multiple={multiple}
        />
        <ul className="em-c-field__list em-js-field-list">
          {files.length < 1 && (
            <li className="em-c-field__item">Drag files here</li>
          )}
          {files.length < 1 && (
            <li className="em-c-field__item em-c-field__item--small">
              Or click to choose file
            </li>
          )}
          {Array.from(files).map(file => (
            <li className="em-c-field__item" key={file.name}>
              {file.name}
            </li>
          ))}
        </ul>
      </Field.Body>
      {note && <Field.Note>{note}</Field.Note>}
    </Field>
  );
};

FileUpload.propTypes = {
  /** value of the fileupload */
  value: PropTypes.string,
  /** label above the field */
  label: oneOfType([PropTypes.string, PropTypes.number]),
  /** note below the field */
  note: oneOfType([PropTypes.string, PropTypes.number]),
  /** function emitted on change, emits the files uploaded as an array */
  onChange: PropTypes.func,
  /** If the field is valid or not */
  valid: PropTypes.bool,
  /** If the field is error or not */
  error: PropTypes.bool,
  /** If the field is read-only or not */
  readOnly: PropTypes.bool,
  /** if the field is disabled or not */
  disabled: PropTypes.bool,
  /** multiple defines if multiple files are allowed or not */
  multiple: PropTypes.bool,
  /** additional css classes to add to the parent outer element */
  className: PropTypes.string
};

FileUpload.defaultProps = {
  value: "",
  label: "",
  note: "",
  onChange: () => {},
  valid: false,
  error: false,
  readOnly: false,
  disabled: false,
  multiple: true,
  className: ""
};

export default FileUpload;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const VisibilityOnIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 13.45c-4.506 0-7.865-4.962-8.006-5.173a.5.5 0 0 1 0-.555C.135 7.512 3.494 2.55 8 2.55s7.865 4.962 8.006 5.173a.5.5 0 0 1 0 .555c-.141.21-3.5 5.172-8.006 5.172zM1.025 8C1.782 9.013 4.6 12.45 8 12.45S14.22 9.014 14.975 8C14.218 6.987 11.4 3.55 8 3.55S1.78 6.986 1.025 8z"
          fill="currentColor"
        />
        <path
          d="M8 11a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-5a2 2 0 1 0 2 2 2 2 0 0 0-2-2z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 32a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zm0-14a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6z"
          fill="currentColor"
        />
        <path
          d="M24.459 41C14.312 41 5.141 31.062 1.668 26.789a3.017 3.017 0 0 1-.122-3.614C4.522 18.913 12.712 9 24.459 9c12.156 0 19.97 10.939 22.076 14.294a2.985 2.985 0 0 1-.125 3.39C43.914 30.042 34.956 41 24.459 41zm0-30C13.35 11 5.368 21.2 3.187 24.319a1 1 0 0 0 .033 1.208C5.789 28.688 14.91 39 24.459 39 34.023 39 42.45 28.66 44.8 25.49a1 1 0 0 0 .037-1.132C42.874 21.225 35.59 11 24.459 11z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.366 20.5c-4.975 0-9-4-10.958-6.386a2.947 2.947 0 0 1 0-3.728C3.365 8 7.392 4 12.366 4s9 4 10.958 6.387a2.945 2.945 0 0 1 0 3.727C21.368 16.5 17.344 20.5 12.366 20.5zm0-14.5C8.06 6 4.35 9.955 2.955 11.655a.928.928 0 0 0 0 1.19c1.395 1.7 5.1 5.654 9.411 5.654s8.017-3.954 9.411-5.654a.929.929 0 0 0 0-1.191C20.383 9.954 16.675 6 12.366 6z"
          fill="currentColor"
        />
        <path
          d="M12.25 16.5a4.25 4.25 0 1 1 4.25-4.25 4.254 4.254 0 0 1-4.25 4.25zm0-6.5a2.25 2.25 0 1 0 2.25 2.25A2.253 2.253 0 0 0 12.25 10z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

VisibilityOnIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

VisibilityOnIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default VisibilityOnIcon;

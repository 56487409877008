import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-text-link--main";
    case "accent":
      return "em-c-text-link--accent";
    case "blue":
    default:
  }
}

/**
 * A basic string of text wrapped in a link.
 *
 * @visibleName Text link
 * */
const TextLink = props => {
  const { href, external, color, className, children, ...other } = props;

  return (
    <a
      className={clsx("em-c-text-link", getColor(color), className)}
      href={href}
      {...other}
    >
      {children}
      {external && (
        <svg
          height="100%"
          width="100%"
          viewBox="0 0 16 16"
          className="external-link-icon"
        >
          <path fill="currentColor" d="M6 7h9v1H6z" />
          <path
            d="M13.5 10.207l-.707-.707 2-2-2-2 .707-.707 2.354 2.354a.5.5 0 0 1 0 .707zM10.5 13H.5a.5.5 0 0 1-.5-.5v-10A.5.5 0 0 1 .5 2h10a.5.5 0 0 1 .5.5V5h-1V3H1v9h9v-2h1v2.5a.5.5 0 0 1-.5.5z"
            fill="currentColor"
          />
        </svg>
      )}
    </a>
  );
};

TextLink.propTypes = {
  /** URL for link */
  href: PropTypes.string,
  /** Styles link with an icon indicating that the link is external */
  external: PropTypes.bool,
  /** Theme color of the link */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TextLink.defaultProps = {
  href: "#",
  external: false,
  color: "blue",
  className: ""
};

export default TextLink;

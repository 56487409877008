import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const OilBottleIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M11.5 16h-7A1.5 1.5 0 0 1 3 14.5v-3.859A4.491 4.491 0 0 1 5 6.9l4.635-3.09.555.832-4.631 3.087A3.494 3.494 0 0 0 4 10.641V14.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V4h1v10.5a1.5 1.5 0 0 1-1.5 1.5zM13.5 3h-4a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5zM10 2h3V1h-3z"
          fill="currentColor"
        />
        <path
          d="M8.35 13.58a1.852 1.852 0 0 1-1.85-1.85 9.623 9.623 0 0 1 1.424-3.692.519.519 0 0 1 .853 0A9.623 9.623 0 0 1 10.2 11.73a1.852 1.852 0 0 1-1.85 1.85zm0-4.26a6.649 6.649 0 0 0-.85 2.41.85.85 0 0 0 1.7 0 6.63 6.63 0 0 0-.85-2.41z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M36 48H10a3 3 0 0 1-3-3V27.749a8.462 8.462 0 0 1 4.521-7.627l16.8-10.5 1.06 1.7L12.53 21.848A6.464 6.464 0 0 0 9 27.749V45a1 1 0 0 0 1 1h26a1 1 0 0 0 1-1V10h2v35a3 3 0 0 1-3 3zM40 8H28a1 1 0 0 1-1-1V3a3 3 0 0 1 3-3h8a3 3 0 0 1 3 3v4a1 1 0 0 1-1 1zM29 6h10V3a1 1 0 0 0-1-1h-8a1 1 0 0 0-1 1z"
          fill="currentColor"
        />
        <path
          d="M23 37.5a5.579 5.579 0 0 1-5.5-5.643c0-2.825 4.228-8.351 4.71-8.971a1.034 1.034 0 0 1 1.579 0c.482.62 4.71 6.146 4.71 8.971A5.579 5.579 0 0 1 23 37.5zm0-12.328c-1.591 2.208-3.5 5.309-3.5 6.686a3.5 3.5 0 1 0 7 0c0-1.379-1.909-4.479-3.5-6.686z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M19 24H6a2 2 0 0 1-2-2v-9a4.138 4.138 0 0 1 2.442-3.834l5.714-4.571 1.25 1.562-5.781 4.624a1.045 1.045 0 0 1-.146.1C6.387 11.474 6 12.029 6 13v9h13V6h2v16a2 2 0 0 1-2 2z"
          fill="currentColor"
        />
        <path
          d="M20 5h-6a1 1 0 0 1-1-1V2c0-1.3 1.546-2 3-2h3a2 2 0 0 1 2 2v2a1 1 0 0 1-1 1zm-5-2h4V2h-3a2.2 2.2 0 0 0-1 .224zM13 19a2.84 2.84 0 0 1-3-3c0-1.454 1.677-3.879 2.19-4.587a1.036 1.036 0 0 1 1.619 0C14.323 12.121 16 14.546 16 16a2.84 2.84 0 0 1-3 3zm0-5.192A6.524 6.524 0 0 0 12 16c0 .691.309 1 1 1s1-.309 1-1a6.524 6.524 0 0 0-1-2.192z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

OilBottleIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

OilBottleIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default OilBottleIcon;

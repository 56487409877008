import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronRightIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M6.9 14.317l-.7-.717 5.628-5.492a.159.159 0 0 0 0-.222L6.2 2.4l.7-.716 5.628 5.492a1.163 1.163 0 0 1 0 1.645z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M16.5 41.914L15.086 40.5l15.086-15.086a2 2 0 0 0 0-2.828L15.086 7.5 16.5 6.086l15.086 15.086a4 4 0 0 1 0 5.656z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M9.483 21.914l-1.4-1.431 8.7-8.492-8.7-8.474 1.4-1.431 8.7 8.491a2 2 0 0 1 .009 2.837z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronRightIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronRightIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronRightIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import MediaBlockMediaImage from "./MediaBlockMediaImage";

const MediaBlockMedia = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-media-block__media", className)} {...other}>
      {children}
    </div>
  );
};

MediaBlockMedia.Image = MediaBlockMediaImage;

MediaBlockMedia.propTypes = {
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** React component children */
  children: PropTypes.node
};

MediaBlockMedia.defaultProps = {
  className: "",
  children: null
};

export default MediaBlockMedia;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DeviceLaptopIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14 10h-1V3.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5V10H2V3.5A1.5 1.5 0 0 1 3.5 2h9A1.5 1.5 0 0 1 14 3.5zM14.5 14h-13a1.5 1.5 0 0 1 0-3h13a1.5 1.5 0 0 1 0 3zm-13-2a.5.5 0 0 0 0 1h13a.5.5 0 0 0 0-1z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M44.5 41h-41a3 3 0 0 1 0-6h41a3 3 0 0 1 0 6zm-41-4a1 1 0 0 0 0 2h41a1 1 0 0 0 0-2zM43 34h-2V10a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v24H5V10a3 3 0 0 1 3-3h32a3 3 0 0 1 3 3z"
          fill="currentColor"
        />
        <path d="M40 33H8V10h32zm-30-2h28V12H10z" fill="currentColor" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 22H2a2 2 0 0 1-2-1.89 3.5 3.5 0 0 1 .023-1.338A2.113 2.113 0 0 1 2 17h20a1.959 1.959 0 0 1 2 2l.016.1a2.376 2.376 0 0 1-.078 1.252A2.454 2.454 0 0 1 22 22zM2.074 19a.617.617 0 0 0-.1.229 1.622 1.622 0 0 0 0 .557A.987.987 0 0 1 2 20h19.817a1.049 1.049 0 0 0 .245-.348 1.107 1.107 0 0 0-.023-.248A2.7 2.7 0 0 1 22 19zM22 15h-2V4H4v11H2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DeviceLaptopIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DeviceLaptopIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DeviceLaptopIcon;

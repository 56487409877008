import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const SortDownIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M1 4h14v1H1zM3 8h10v1H3zM5 12h6v1H5z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M7 16h14v2H7zM3 6h42v2H3zM27 16h14v2H27zM13 26h8v2h-8zM27 26h8v2h-8zM23 10h2v30h-2z"
        />
        <path
          d="M24 44a3.022 3.022 0 0 1-2.11-.848l-6.3-6.129 1.395-1.434 6.3 6.128a1.038 1.038 0 0 0 1.435 0l6.3-6.127 1.395 1.434-6.3 6.128A3.022 3.022 0 0 1 24 44z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11 14h2v8h-2z" />
        <path
          d="M11.985 23.7a1.844 1.844 0 0 1-1.213-.519L6.586 19 8 17.586l4 4 4-4L17.414 19l-4.136 4.136a1.979 1.979 0 0 1-1.293.564zM2 2h20v2H2zM4 6h16v2H4zM7 10h10v2H7z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

SortDownIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

SortDownIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default SortDownIcon;

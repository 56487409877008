import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CameraIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M13.5 13h-11A1.5 1.5 0 0 1 1 11.5v-8A1.5 1.5 0 0 1 2.5 2H5v1H2.5a.5.5 0 0 0-.5.5v8a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 .5-.5v-6a.5.5 0 0 0-.5-.5H3V4h10.5A1.5 1.5 0 0 1 15 5.5v6a1.5 1.5 0 0 1-1.5 1.5z"
          fill="currentColor"
        />
        <path
          d="M8.5 11A2.5 2.5 0 1 1 11 8.5 2.5 2.5 0 0 1 8.5 11zm0-4A1.5 1.5 0 1 0 10 8.5 1.5 1.5 0 0 0 8.5 7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M43 40H5a3 3 0 0 1-3-3V11a3 3 0 0 1 3-3h9v2H5a1 1 0 0 0-1 1v26a1 1 0 0 0 1 1h38a1 1 0 0 0 1-1V15a1 1 0 0 0-1-1H6v-2h37a3 3 0 0 1 3 3v22a3 3 0 0 1-3 3z"
          fill="currentColor"
        />
        <path
          d="M28 37a11 11 0 1 1 11-11 11.012 11.012 0 0 1-11 11zm0-20a9 9 0 1 0 9 9 9.01 9.01 0 0 0-9-9z"
          fill="currentColor"
        />
        <path
          d="M28 32a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM12 23a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-4a1 1 0 1 0 1 1 1 1 0 0 0-1-1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M14 18a4.5 4.5 0 1 1 4.5-4.5A4.5 4.5 0 0 1 14 18zm0-7a2.5 2.5 0 1 0 2.5 2.5A2.5 2.5 0 0 0 14 11z"
          fill="currentColor"
        />
        <circle cx={5} cy={11} r={1} fill="#112" />
        <path d="M5 10a1 1 0 1 0 1 1 1 1 0 0 0-1-1z" fill="currentColor" />
        <path
          d="M23 21H1a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h22a1 1 0 0 1 1 1v13a1 1 0 0 1-1 1zM2 19h20V8H2zM3 3h6v2H3z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

CameraIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

CameraIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default CameraIcon;

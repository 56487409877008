import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretRightIcon } from "../../Icons";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-pagination__link--main";
    case "accent":
      return "em-c-pagination__link--accent";
    case "blue":
    default:
      return "";
  }
}

/** PaginationLast is a button to navigate to the last page */
const PaginationLast = props => {
  const { disabled, color, onChange, className, children, ...other } = props;

  return (
    <li
      className={clsx(
        "em-c-pagination__item",
        { "em-u-clickable": !disabled },
        className
      )}
      onClick={!disabled ? onChange : () => {}}
      {...other}
    >
      <div
        className={clsx("em-c-pagination__link", getColor(color), {
          "em-is-disabled": disabled
        })}
      >
        <CaretRightIcon
          className="em-c-pagination__icon em-pagination-icon"
          size="small"
        />
      </div>
    </li>
  );
};

PaginationLast.propTypes = {
  /** Indicates that the item is disabled */
  disabled: PropTypes.bool,
  /** URL of the page */
  href: PropTypes.string,
  /** function emitted on change, returns this pages inner value */
  onChange: PropTypes.func,
  /** Theme color of the buttons */
  color: PropTypes.oneOf(["blue", "main", "accent"]),
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

PaginationLast.defaultProps = {
  disabled: false,
  onChange: () => {},
  className: "",
  color: "blue"
};

export default PaginationLast;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const ChevronHardStopIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          fill="currentColor"
          d="M11 2h1v12h-1zM3.53 13.657l-.7-.717L7.8 8.108a.159.159 0 0 0 0-.222L2.833 3.06l.7-.717L8.5 7.175a1.163 1.163 0 0 1 0 1.646z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          fill="currentColor"
          d="M35 6h2v36h-2zM11.5 41.914L10.086 40.5l15.086-15.086a2 2 0 0 0 0-2.828L10.086 7.5 11.5 6.086l15.086 15.086a4.006 4.006 0 0 1 0 5.656z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          fill="currentColor"
          d="M17 3h2v19h-2zM5.48 20.914L4.086 19.48l7.7-7.49-7.7-7.47L5.48 3.086l7.7 7.49a2 2 0 0 1 .01 2.838z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

ChevronHardStopIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

ChevronHardStopIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default ChevronHardStopIcon;

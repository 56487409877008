import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TableHead from "./TableHead";
import TableBody from "./TableBody";

/** Responsive data table that can display even very dense data in any screen size. */
const Table = props => {
  const { header, striped, condensed, className, children, ...other } = props;

  return (
    <div className={clsx("em-c-table-object", className)} {...other}>
      <div className="em-c-table-object__header">{header}</div>
      <div className="em-c-table-object__body">
        <div className="em-c-table-object__body-inner">
          <table
            className={clsx("em-c-table", {
              "em-c-table--striped": striped,
              "em-c-table--condensed": condensed
            })}
          >
            {children}
          </table>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  /** header lets you put header such as a toolbar on top of a table and have it scale with the table */
  header: PropTypes.element,
  /** condensed determins whether the table is condensed or not */
  condensed: PropTypes.bool,
  /** striped determines if the rows will be striped or not */
  striped: PropTypes.bool,
  /** children are the contents of the Table */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

Table.defaultProps = {
  header: null,
  condensed: false,
  striped: false,
  children: null,
  className: ""
};

Table.Head = TableHead;
Table.Body = TableBody;

export default Table;

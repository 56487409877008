import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const GroupIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M8 11a3 3 0 1 1 3-3 3 3 0 0 1-3 3zm0-5a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM13 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM15.5 10H12V9h3v-.49a1.774 1.774 0 0 0-.53-1.273l.693-.72A2.807 2.807 0 0 1 16 8.51v.99a.5.5 0 0 1-.5.5zM3 6a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm0-3a1 1 0 1 0 1 1 1 1 0 0 0-1-1zM4 10H.5a.5.5 0 0 1-.5-.5v-.99A2.757 2.757 0 0 1 .854 6.5l.362-.346.69.723-.361.345A1.762 1.762 0 0 0 1 8.51V9h3z"
          fill="currentColor"
        />
        <path
          d="M12.5 16h-9a.5.5 0 0 1-.5-.5v-.33a4.718 4.718 0 0 1 2.348-4.059l.429-.257.514.858-.429.257A3.737 3.737 0 0 0 4 15h8a3.7 3.7 0 0 0-1.158-2.506 3.794 3.794 0 0 0-.693-.522l-.433-.251.5-.865.433.251a4.769 4.769 0 0 1 .883.664A4.687 4.687 0 0 1 13 15.17v.33a.5.5 0 0 1-.5.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M24 31a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zm0-14a6 6 0 1 0 6 6 6.007 6.007 0 0 0-6-6zM38 17a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM47 27H36v-2h10v-2a5.885 5.885 0 0 0-1.761-4.228l-.712-.7 1.337-1.485.753.735A7.89 7.89 0 0 1 48 23v3a1 1 0 0 1-1 1zM10 17a6 6 0 1 1 6-6 6.007 6.007 0 0 1-6 6zm0-10a4 4 0 1 0 4 4 4 4 0 0 0-4-4zM12 27H1a1 1 0 0 1-1-1v-3a7.833 7.833 0 0 1 2.43-5.724l.724-.69 1.381 1.447-.724.69A5.846 5.846 0 0 0 2 23v2h10z"
          fill="currentColor"
        />
        <path
          d="M37 45H11a1 1 0 0 1-1-1v-1a13.917 13.917 0 0 1 6.548-11.847l.847-.532 1.064 1.694-.847.532A11.926 11.926 0 0 0 12 43h24a12 12 0 0 0-3.51-8.5 11.41 11.41 0 0 0-2.091-1.65l-.854-.521 1.041-1.708.854.521a13.493 13.493 0 0 1 2.467 1.946A13.992 13.992 0 0 1 38 43v1a1 1 0 0 1-1 1z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M12.5 17a4.5 4.5 0 1 1 4.5-4.5 4.5 4.5 0 0 1-4.5 4.5zm0-7a2.5 2.5 0 1 0 2.5 2.5 2.5 2.5 0 0 0-2.5-2.5zM18 9a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM23 16h-5v-2h4v-2c0-.521-.115-.65-.725-1.25l.665-.75.707-.707.023.023A3.385 3.385 0 0 1 24 12v3a1 1 0 0 1-1 1zM6 9a4 4 0 1 1 4-4 4 4 0 0 1-4 4zm0-6a2 2 0 1 0 2 2 2 2 0 0 0-2-2zM7 16H1a1 1 0 0 1-1-1v-3a3.377 3.377 0 0 1 1.369-2.723l.723-.691 1.382 1.446-.724.691c-.631.6-.75.734-.75 1.277v2h5z"
          fill="currentColor"
        />
        <path
          d="M19 24H6a1 1 0 0 1-1-1v-1a6.177 6.177 0 0 1 3.6-5.9l.9-.44.88 1.8-.9.44A4.14 4.14 0 0 0 7 22h11a6.836 6.836 0 0 0-2.118-4h-.922v-2h1c1.859 0 4.04 3.966 4.04 6v1a1 1 0 0 1-1 1zm-3.153-6.016z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

GroupIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

GroupIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default GroupIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import ProgressTrackerItem from "./ProgressTrackerItem";

/**
 * A navigation or status bar showing progress through a fixed series of steps; sometimes called a “stepper.”
 *
 * @visibleName Progress tracker
 * */
const ProgressTracker = props => {
  const { variant, className, children, ...other } = props;

  return (
    <ol
      className={clsx(
        "em-c-progress-tracker",
        { "em-c-progress-tracker--vertical": variant === "vertical" },
        className
      )}
      {...other}
    >
      {children}
    </ol>
  );
};

ProgressTracker.propTypes = {
  /** wether the progress tracker is horiontal or vertical */
  variant: PropTypes.oneOf(["horizontal", "vertical"]),
  /** children are the contents of the ProgressTracker */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

ProgressTracker.defaultProps = {
  variant: "horizontal",
  children: PropTypes.node,
  className: ""
};

ProgressTracker.Item = ProgressTrackerItem;

export default ProgressTracker;

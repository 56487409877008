import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function TypeaheadItemSuggestion(props) {
  const { className, children, ...other } = props;

  return (
    <span className={clsx("em-c-typeahead__suggestion", className)} {...other}>
      {children}
    </span>
  );
}

TypeaheadItemSuggestion.defaultProps = {
  className: ""
};

TypeaheadItemSuggestion.propTypes = {
  /** Additional classname */
  className: PropTypes.string,
  /** React Children */
  children: PropTypes.node
};

export default TypeaheadItemSuggestion;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const StickyNoteIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M10.5 15h-8a.5.5 0 0 1-.5-.5v-12a.5.5 0 0 1 .5-.5h12a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.146.354l-4 4A.5.5 0 0 1 10.5 15zM3 14h7.293L14 10.293V3H3z"
          fill="currentColor"
        />
        <path
          d="M10.5 15a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .354.854l-4 4A.5.5 0 0 1 10.5 15zm.5-4v2.293L13.293 11z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M32 45H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h40a1 1 0 0 1 1 1v26a1 1 0 0 1-.293.707l-12 12A1 1 0 0 1 32 45zM5 43h26.586L43 31.586V7H5z"
          fill="currentColor"
        />
        <path d="M32 45h-2V31a1 1 0 0 1 1-1h13v2H32z" fill="currentColor" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M17 23H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h21a1 1 0 0 1 1 1v14a1 1 0 0 1-.293.707l-6 6A1 1 0 0 1 17 23zM3 21h13.586L22 15.586V3H3z"
          fill="currentColor"
        />
        <path d="M17 22h-2v-7a1 1 0 0 1 1-1h6v2h-5z" fill="currentColor" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

StickyNoteIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

StickyNoteIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default StickyNoteIcon;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

function VerticalHeaderBodySearch(props) {
  const { children, className, isMobileMenuOpen, isOpen } = props;

  if (!isOpen && !isMobileMenuOpen) {
    return <Fragment />;
  }

  return React.Children.toArray(children).map(child =>
    React.cloneElement(child, {
      className: clsx(
        "em-c-search-form",
        "em-is-active",
        className,
        child.props.className
      )
    })
  );
}

VerticalHeaderBodySearch.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** children of component */
  children: PropTypes.node,
  /** Automatically passed by the VerticalHeader to indicate if the mobile menu is open */
  isMobileMenuOpen: PropTypes.bool,
  /** Indicates if the children should be shown */
  isOpen: PropTypes.bool
};

VerticalHeaderBodySearch.defaultProps = {
  className: "",
  children: null,
  isOpen: false
};

export default VerticalHeaderBodySearch;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const RotateLeftIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M4.135 12.033l-.3-.4q-.16-.218-.3-.449l-.263-.426.852-.525.263.426c.08.131.165.259.256.381l.3.4zM8.753 14.11c-.148 0-.3 0-.443-.016l-.5-.036.072-1 .5.036a5.19 5.19 0 0 0 .88-.012l.5-.051.1 1-.5.05a5.957 5.957 0 0 1-.609.029zm-2.02-.32l-.457-.2a6.176 6.176 0 0 1-.921-.509L4.94 12.8l.557-.83.415.278a5.191 5.191 0 0 0 .771.425l.457.2zm4.2-.056l-.432-.9.451-.216a5.309 5.309 0 0 0 .76-.445l.408-.289.576.817-.408.288a6.156 6.156 0 0 1-.9.531zm2.548-1.826l-.819-.573.286-.409a5.024 5.024 0 0 0 .44-.762l.213-.453.905.425-.212.452a6.12 6.12 0 0 1-.526.91zM14.795 9.042l-1-.088.044-.5c.014-.15.021-.3.021-.456V7.5h1V8c0 .183-.009.364-.024.544zM3.469 6.554a.5.5 0 0 1-.482-.368L1.93 2.319l.965-.264.925 3.389 3.4-.892.254.967L3.6 6.537a.477.477 0 0 1-.131.017z"
          fill="currentColor"
        />
        <path
          d="M14.863 8.5h-1V8a5.111 5.111 0 0 0-9.7-2.258l-.221.449-.9-.442.229-.449A6.111 6.111 0 0 1 14.863 8z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M9.459 19a2.677 2.677 0 0 1-2.575-1.99l-2.61-9.716 1.932-.52 2.609 9.717a.664.664 0 0 0 .8.487l9.588-2.564.517 1.933-9.582 2.563a2.631 2.631 0 0 1-.679.09z"
          fill="currentColor"
        />
        <path
          d="M44 25h-2v-1A16.87 16.87 0 0 0 25.3 7 16.647 16.647 0 0 0 9.924 17.383l-.383.924-1.848-.766.383-.924A18.643 18.643 0 0 1 25.3 5 18.872 18.872 0 0 1 44 24z"
          fill="currentColor"
        />
        <path
          d="M25.065 44c-.62 0-1.245-.03-1.86-.091l-1-.1.2-1.99 1 .1a17.242 17.242 0 0 0 2.831.041l1-.066.134 2-1 .067q-.649.039-1.305.039zm6.512-1.127l-.787-1.838.919-.394a16.839 16.839 0 0 0 2.5-1.328l.841-.542 1.084 1.682-.846.547a18.888 18.888 0 0 1-2.788 1.479zm-13.551-.2l-.907-.422a19.023 19.023 0 0 1-2.747-1.568l-.824-.566 1.133-1.648.824.566a17.059 17.059 0 0 0 2.458 1.4l.906.421zm21.3-5.136L37.754 36.3l.618-.786a16.957 16.957 0 0 0 1.557-2.362l.479-.878 1.756.957-.479.878a18.981 18.981 0 0 1-1.74 2.642zM10.437 37.1l-.593-.8a19.015 19.015 0 0 1-1.658-2.691l-.452-.893 1.784-.9.451.892a17.216 17.216 0 0 0 1.484 2.41l.594.8zm33.142-7.968l-1.979-.323.164-.987A17.337 17.337 0 0 0 42 25v-1h2v1a19.284 19.284 0 0 1-.259 3.149z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M4.889 9.767a1.772 1.772 0 0 1-1.706-1.3L1.839 3.551l1.93-.527 1.282 4.692 4.7-1.236.509 1.935-4.926 1.294a1.748 1.748 0 0 1-.445.058z"
          fill="currentColor"
        />
        <path
          d="M22 11.785h-2v-1A7.287 7.287 0 0 0 6.176 7.568l-.441.9-1.795-.887.442-.9A9.286 9.286 0 0 1 22 10.785zM7.255 19.486l-.772-.635a9.819 9.819 0 0 1-.818-.755l-.7-.719 1.438-1.391.7.719a7.727 7.727 0 0 0 .65.6l.772.635zM12.5 21a9.489 9.489 0 0 1-1.428-.107l-.988-.15.3-1.977.988.15a7.8 7.8 0 0 0 2 .034l.993-.114.228 1.987-.994.113A9.53 9.53 0 0 1 12.5 21zm4.96-1.335l-1.212-1.591.795-.605a7.584 7.584 0 0 0 .757-.669 7.692 7.692 0 0 0 .655-.748l.607-.794 1.588 1.215-.607.794a9.507 9.507 0 0 1-1.791 1.788z"
          fill="currentColor"
        />
        <path
          d="M21.82 13.607l-1.986-.23.115-.993A7.768 7.768 0 0 0 20 11.5v-1h2v1a9.55 9.55 0 0 1-.064 1.114z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

RotateLeftIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

RotateLeftIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default RotateLeftIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DownloadCloudIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M8 6h1v9H8z" />
        <path
          d="M8.5 16a.5.5 0 0 1-.277-.084l-3.416-2.277.555-.832L8.5 14.9l3.139-2.092.555.832-3.417 2.276A.5.5 0 0 1 8.5 16zM13 11h-3v-1h3a2 2 0 0 0 .4-3.96.5.5 0 0 1-.4-.49 4.5 4.5 0 1 0-9-.05.552.552 0 0 1-.18.409.522.522 0 0 1-.411.132A2.247 2.247 0 0 0 3 6a2 2 0 0 0 0 4h4v1H3a3 3 0 1 1 .026-6 5.5 5.5 0 0 1 10.964.167A3 3 0 0 1 13 11z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M37.737 35H28v-2h9.737a8.159 8.159 0 0 0 8.178-6.808 8.017 8.017 0 0 0-5.315-8.745 1 1 0 0 1-.675-.857 14.995 14.995 0 0 0-29.811-.43 1 1 0 0 1-.9.875 8.024 8.024 0 0 0-7.179 8.75A8.215 8.215 0 0 0 10.293 33H22v2H10.293A10.231 10.231 0 0 1 .046 25.973a10.035 10.035 0 0 1 8.192-10.821 17 17 0 0 1 33.609.629A10.028 10.028 0 0 1 47.9 26.473 10.169 10.169 0 0 1 37.737 35z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M24 24h2v20h-2z" />
        <path
          d="M25 48a3.019 3.019 0 0 1-2.111-.849l-6.3-6.127 1.394-1.434 6.3 6.128a1.036 1.036 0 0 0 1.434 0l6.3-6.128 1.394 1.434-6.3 6.127A3.021 3.021 0 0 1 25 48z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18.1 18H16v-2h2.1a3.137 3.137 0 0 0 3.151-2.511 3.028 3.028 0 0 0-2-3.371 1 1 0 0 1-.675-.857 6.155 6.155 0 0 0-12.24-.176 1 1 0 0 1-.9.875 3.032 3.032 0 0 0-2.649 3.71A3.1 3.1 0 0 0 5.863 16H9v2H5.863a5.107 5.107 0 0 1-5.03-3.9 5.033 5.033 0 0 1 3.642-5.99 8.158 8.158 0 0 1 16.012.363 5.031 5.031 0 0 1 2.736 5.337A5.15 5.15 0 0 1 18.1 18z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 10h2v9h-2z" />
        <path
          d="M12 23.6a2.5 2.5 0 0 1-1.707-.9L7.586 20 9 18.586l2.707 2.707a2.935 2.935 0 0 0 .293.263 2.935 2.935 0 0 0 .293-.263L15 18.586 16.414 20l-2.707 2.707A2.5 2.5 0 0 1 12 23.6zm.09-1.991z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DownloadCloudIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DownloadCloudIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DownloadCloudIcon;

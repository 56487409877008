import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/** GridItem is an item that will live within a grid layout and hold content. */
const GridItem = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-l-grid__item", className)} {...other}>
      {children}
    </div>
  );
};
GridItem.propTypes = {
  /** children of the container */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

GridItem.defaultProps = {
  children: null,
  className: ""
};

export default GridItem;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

/**
 * SwitchList is a wrapper that allow you to group two buttons in a common yes/no type ux.
 */
const SwitchList = props => {
  const { className, children, ...other } = props;

  return (
    <div className={clsx("em-c-switch-list", className)} {...other}>
      {children}
    </div>
  );
};

SwitchList.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** Child React components, in this case 2 buttons */
  children: PropTypes.node
};

SwitchList.defaultProps = {
  className: "",
  children: null
};

export default SwitchList;

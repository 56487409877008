import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const HeadsetIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M3.5 12h-2A1.5 1.5 0 0 1 0 10.5v-2A1.5 1.5 0 0 1 1.5 7h2A1.5 1.5 0 0 1 5 8.5v2A1.5 1.5 0 0 1 3.5 12zm-2-4a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5zM14.5 12h-2a1.5 1.5 0 0 1-1.5-1.5v-2A1.5 1.5 0 0 1 12.5 7h2A1.5 1.5 0 0 1 16 8.5v2a1.5 1.5 0 0 1-1.5 1.5zm-2-4a.5.5 0 0 0-.5.5v2a.5.5 0 0 0 .5.5h2a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 0-.5-.5zM12.5 15H8v-1h4.5a.5.5 0 0 0 .5-.5V12h1v1.5a1.5 1.5 0 0 1-1.5 1.5zM14 6.5h-1A4.5 4.5 0 0 0 8.5 2h-1A4.5 4.5 0 0 0 3 6.5H2A5.506 5.506 0 0 1 7.5 1h1A5.506 5.506 0 0 1 14 6.5z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M12 37H7a5.006 5.006 0 0 1-5-5v-4a5.006 5.006 0 0 1 5-5h5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zM7 25a3 3 0 0 0-3 3v4a3 3 0 0 0 3 3h5V25zM41 37h-5a2 2 0 0 1-2-2V25a2 2 0 0 1 2-2h5a5.006 5.006 0 0 1 5 5v4a5.006 5.006 0 0 1-5 5zm-5-12v10h5a3 3 0 0 0 3-3v-4a3 3 0 0 0-3-3zM42 21h-2v-1A15.017 15.017 0 0 0 25 5h-2A15.017 15.017 0 0 0 8 20v1H6v-1A17.019 17.019 0 0 1 23 3h2a17.019 17.019 0 0 1 17 17zM37 45h-6v-2h6a3 3 0 0 0 3-3v-3h2v3a5.006 5.006 0 0 1-5 5zM29 47h-5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2zm-5-4v2h5v-2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M18 24h-7v-2h7a1 1 0 0 0 1-1v-2h2v2a3 3 0 0 1-3 3zM7 21H3a3 3 0 0 1-3-3v-4a3 3 0 0 1 3-3h4a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm-4-8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h3v-6z"
          fill="currentColor"
        />
        <path
          d="M21 21h-4a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h4a3 3 0 0 1 3 3v4a3 3 0 0 1-3 3zm-3-2h3a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-3zM21.5 10h-2V9a7.008 7.008 0 0 0-7-7h-1a7.008 7.008 0 0 0-7 7v1h-2V9a9.01 9.01 0 0 1 9-9h1a9.01 9.01 0 0 1 9 9z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

HeadsetIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

HeadsetIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default HeadsetIcon;

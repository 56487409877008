import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const MediaControlNextIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M5.5 11.406a.5.5 0 0 1-.5-.5V5.094a.5.5 0 0 1 .783-.412l4.226 2.906a.5.5 0 0 1 0 .824l-4.226 2.906a.5.5 0 0 1-.283.088zM6 6.044v3.912L8.843 8z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M10 5h1v6h-1z" />
        <path
          d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zM8 1a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M16 32a1.986 1.986 0 0 1-.918-.226A2.007 2.007 0 0 1 14 29.986V16.014a2.007 2.007 0 0 1 1.079-1.787 1.978 1.978 0 0 1 2.055.133l10.007 6.987a2.02 2.02 0 0 1 0 3.307L17.134 31.64A1.98 1.98 0 0 1 16 32zm-.012-16L16 29.986l10-6.973zM32 14h2v20h-2z"
          fill="currentColor"
        />
        <path
          d="M24 48a24 24 0 1 1 24-24 24.027 24.027 0 0 1-24 24zm0-46a22 22 0 1 0 22 22A22.025 22.025 0 0 0 24 2z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M7.5 16.953a1.5 1.5 0 0 1-1.5-1.5V8.547a1.5 1.5 0 0 1 2.351-1.235l5.02 3.452a1.5 1.5 0 0 1 0 2.473L8.35 16.688a1.5 1.5 0 0 1-.85.265zM8 9.5v5l3.638-2.5zm4.237 2.914zM7.217 8.96zM15 7h2v10h-2z"
          fill="currentColor"
        />
        <path
          d="M12 24a12 12 0 1 1 12-12 12.013 12.013 0 0 1-12 12zm0-22a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

MediaControlNextIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

MediaControlNextIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default MediaControlNextIcon;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import FieldsetLegend from "./FieldsetLegend";

/** Group related fields when displaying lengthy forms */
const Fieldset = props => {
  const { className, children, ...other } = props;

  return (
    <fieldset className={clsx("em-c-fieldset", className)} {...other}>
      {children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case footerlinks usually */
  children: PropTypes.node
};

Fieldset.defaultProps = {
  className: "",
  children: null
};

Fieldset.Legend = FieldsetLegend;

export default Fieldset;

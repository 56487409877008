import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const User1PlusIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M12 8h1v5h-1z" />
        <path
          fill="currentColor"
          d="M10 10h5v1h-5zM8.5 8A3.5 3.5 0 1 1 12 4.5 3.5 3.5 0 0 1 8.5 8zm0-6A2.5 2.5 0 1 0 11 4.5 2.5 2.5 0 0 0 8.5 2zM15 16H2.5a.5.5 0 0 1-.5-.5v-2a6.445 6.445 0 0 1 1.669-4.335L4 8.794l.741.671-.335.371A5.449 5.449 0 0 0 3 13.5V15h12z"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M23 23a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10zm0-18a8 8 0 1 0 8 8 8.009 8.009 0 0 0-8-8zM41 45H8a3 3 0 0 1-3-3v-3a17.981 17.981 0 0 1 9.549-15.882l.882-.471.942 1.765-.883.471A15.984 15.984 0 0 0 7 39v3a1 1 0 0 0 1 1h33zM35 23h2v14h-2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M29 29h14v2H29z" />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M11.5 12A5.5 5.5 0 1 1 17 6.5a5.507 5.507 0 0 1-5.5 5.5zm0-9A3.5 3.5 0 1 0 15 6.5 3.5 3.5 0 0 0 11.5 3zM21 23H4a2 2 0 0 1-2-2v-1.5c0-4.644 1.932-6.584 3.553-7.395l.895-.447.895 1.789-.895.447C4.823 14.706 4 16.593 4 19.5V21h17z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M17 11h2v8h-2z" />
        <path fill="currentColor" d="M14 14h8v2h-8z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

User1PlusIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

User1PlusIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default User1PlusIcon;

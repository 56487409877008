import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DollarSignIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path fill="currentColor" d="M7 1h1v14H7z" />
        <path
          d="M7.5 14A3.5 3.5 0 0 1 4 10.5V10h1v.5A2.5 2.5 0 0 0 7.5 13a2.533 2.533 0 0 0 2.5-2.5c0-.488-1.6-1.379-2.658-1.969C5.623 7.573 4 6.669 4 5.5A3.462 3.462 0 0 1 7.5 2a3.5 3.5 0 0 1 2.9 1.542l.28.415-.829.56-.278-.417A2.5 2.5 0 0 0 7.5 3 2.473 2.473 0 0 0 5 5.5c0 .582 1.7 1.53 2.828 2.157C9.53 8.605 11 9.425 11 10.5A3.547 3.547 0 0 1 7.5 14z"
          fill="currentColor"
        />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path fill="currentColor" d="M18 1h2v44h-2zM24 1h2v44h-2z" />
        <path
          d="M22 41c-6.617 0-12-4.486-12-10v-1h2v1c0 4.411 4.486 8 10 8s10-3.589 10-8c0-4.9-4.7-6.427-9.666-8.04C17.254 21.313 12 19.607 12 14c0-4.963 4.486-9 10-9s10 4.037 10 9v1h-2v-1c0-3.859-3.589-7-8-7s-8 3.141-8 7c0 4.154 4.148 5.5 8.951 7.058C28.13 22.738 34 24.642 34 31c0 5.514-5.383 10-12 10z"
          fill="currentColor"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path fill="currentColor" d="M9 0h2v24H9zM13 0h2v24h-2z" />
        <path
          d="M12 22c-3.859 0-7-2.586-7-5.765v-1h2v1C7 18.312 9.243 20 12 20s5-1.688 5-3.765c0-2.1-1.87-2.836-4.95-3.806-2.793-.88-5.959-1.877-5.959-5.194C6.091 4.349 8.741 2 12 2s5.909 2.349 5.909 5.235v1h-2v-1C15.909 5.451 14.155 4 12 4S8.091 5.451 8.091 7.235c0 1.726 1.723 2.393 4.561 3.286s6.348 2 6.348 5.714C19 19.414 15.859 22 12 22z"
          fill="currentColor"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DollarSignIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DollarSignIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DollarSignIcon;

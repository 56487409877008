import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import TwoColumnLayoutMain from "./TwoColumnLayoutMain";
import TwoColumnLayoutSecondary from "./TwoColumnLayoutSecondary";

/**
 * TwoColumnLayout creates a layout of for a large main section and a small secondary section
 *
 * @visibleName 2-column layout
 * */
const TwoColumnLayout = props => {
  const { variant, className, children, ...other } = props;

  return (
    <div
      className={clsx(
        "em-l",
        {
          "em-l--two-column": variant !== "wide" && variant !== "fixed",
          "em-l--two-column-wide": variant === "wide",
          "em-l--two-column-fixed": variant === "fixed"
        },
        className
      )}
      {...other}
    >
      {children}
    </div>
  );
};

TwoColumnLayout.propTypes = {
  /** Determines how placement of the columns */
  variant: PropTypes.oneOf(["default", "wide", "fixed"]),
  /** children of the container */
  children: PropTypes.node,
  /** Additional css classes to append to the outer element */
  className: PropTypes.string
};

TwoColumnLayout.defaultProps = {
  variant: "default",
  children: null,
  className: ""
};

TwoColumnLayout.Main = TwoColumnLayoutMain;
TwoColumnLayout.Secondary = TwoColumnLayoutSecondary;

export default TwoColumnLayout;

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const DeviceMonitorIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M15.5 12h-14a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h14a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM2 11h13V4H2zM8 12h1v2H8z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M4 13h9v1H4z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M44 37H4a3 3 0 0 1-3-3V8a3 3 0 0 1 3-3h40a3 3 0 0 1 3 3v26a3 3 0 0 1-3 3zM4 7a1 1 0 0 0-1 1v26a1 1 0 0 0 1 1h40a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1zM23 39h2v6h-2z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M5 43h38v2H5zM44 34H4V8h40zM6 32h36V10H6z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22.5 18h-21A1.5 1.5 0 0 1 0 16.5v-13A1.5 1.5 0 0 1 1.5 2h21A1.5 1.5 0 0 1 24 3.5v13a1.5 1.5 0 0 1-1.5 1.5zM2 16h20V4H2z"
          fill="currentColor"
        />
        <path fill="currentColor" d="M11 17h2v4h-2z" />
        <path fill="currentColor" d="M5 20h14v2H5z" />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

DeviceMonitorIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

DeviceMonitorIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default DeviceMonitorIcon;

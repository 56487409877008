import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const BuildingsIcon = ({ size, className, ...other }) => (
  /* eslint-disable */

  <div
    className={clsx(
      "em-c-icon",
      {
        "em-c-icon--small": size === "small",
        "em-c-icon--medium": size !== "small" && size !== "large",
        "em-c-icon--large": size === "large"
      },
      "em-icon-wrapper",
      className
    )}
    {...other}
  >
    {size === "small" ? (
      <svg height="100%" width="100%" viewBox="0 0 16 16">
        <path
          d="M14.5 15h-5a.5.5 0 0 1-.5-.5v-8a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-.5.5zM10 14h4V7h-4z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M11 8h2v1h-2zM11 10h2v1h-2zM11 12h2v1h-2zM6.5 15h-5a.5.5 0 0 1-.5-.5v-6a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5zM2 14h4V9H2z"
        />
        <path
          fill="currentColor"
          d="M3 10h2v1H3zM3 12h2v1H3zM4 7H3V2.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5V5h-1V3H4z"
        />
        <path fill="currentColor" d="M4 0h1v3H4z" />
      </svg>
    ) : size === "large" ? (
      <svg height="100%" width="100%" viewBox="0 0 48 48">
        <path
          d="M46 48H27a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h19a1 1 0 0 1 1 1v36a1 1 0 0 1-1 1zm-18-2h17V12H28zM18 48H2a1 1 0 0 1-1-1V22a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v25a1 1 0 0 1-1 1zM3 46h14V23H3z"
          fill="currentColor"
        />
        <path
          d="M11 20H9V3a1 1 0 0 1 1-1h31a1 1 0 0 1 1 1v6h-2V4H11zM20 46h5v2h-5z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M13 0h2v4h-2zM5 25h10v2H5zM5 29h10v2H5zM5 33h10v2H5zM5 37h10v2H5zM5 41h10v2H5zM42 19H31a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-10-2h9v-2h-9zM42 27H31a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-10-2h9v-2h-9zM42 35H31a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-10-2h9v-2h-9zM42 43H31a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1h11a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1zm-10-2h9v-2h-9zM19 13h-5a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1zm-4-2h3V8h-3zM38 9h-2V8h-3v1h-2V7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1zM20 20h-2v-3h-3v3h-2v-4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1zM25 13h-2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v2h-2V8h-3v3h1zM25 22h-2a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h2v2h-1v3h1zM25 31h-2a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h2v2h-1v3h1zM25 40h-2a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h2v2h-1v3h1z"
        />
      </svg>
    ) : (
      <svg height="100%" width="100%" viewBox="0 0 24 24">
        <path
          d="M22 24h-9a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm-8-2h7V7h-7z"
          fill="currentColor"
        />
        <path
          fill="currentColor"
          d="M15.003 9h5v2h-5zM15.003 12h5v2h-5zM15.003 15h5v2h-5zM15.003 18h5v2h-5zM10 24H2a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1zm-7-2h6V10H3z"
        />
        <path
          fill="currentColor"
          d="M4.003 15h4v2h-4zM4.003 12h4v2h-4zM4.003 18h4v2h-4zM7 7H5V1a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v2.25h-2V2H7z"
        />
      </svg>
    )}
  </div>

  /* eslint-enable */
);

BuildingsIcon.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),

  /** className allows you to append css classes */

  className: PropTypes.string
};

BuildingsIcon.defaultProps = {
  size: "medium",

  className: ""
};

export default BuildingsIcon;

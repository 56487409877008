import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { CaretDownIcon, CaretRightIcon } from "../Icons";

function getColor(color) {
  switch (color) {
    case "main":
      return "em-c-btn--main";
    case "accent":
      return "em-c-btn--accent";
    case "mainToAccent":
      return "em-c-btn--mainToAccent";
    case "accentToMain":
      return "em-c-btn--accentToMain";
    case "action":
      return "em-c-btn--action";
    case "caution":
      return "em-c-btn--caution";
    case "positive":
      return "em-c-btn--positive";
    case "negative":
      return "em-c-btn--negative";
    case "blue":
    default:
      return "";
  }
}

/**
 * Toggles associated content on click
 *
 * @visibleName Expandable button
 * */
const ExpandableButton = props => {
  const { showText, hideText, className, color, children, ...other } = props;

  const [open, setOpen] = useState(props.open || false);

  const toggleContent = () => {
    setOpen(prev => !prev);
  };

  return (
    <div
      className={clsx(
        "em-c-expandable-button",
        { "em-is-closed": !open },
        className
      )}
      {...other}
    >
      <button
        onClick={toggleContent}
        className={clsx(
          "em-c-btn",
          "em-c-btn--small",
          "em-c-btn--primary",
          "em-c-btn--is-expandable",
          getColor(color)
        )}
        data-show-hide-label={hideText}
      >
        <div className="em-c-btn__inner">
          <span className="em-c-btn__text em-js-btn-text">
            {open ? hideText : showText}
          </span>
          {open ? (
            <CaretDownIcon
              size="small"
              className="em-c-btn__icon em-c-btn__icon-only"
            />
          ) : (
            <CaretRightIcon
              size="small"
              className="em-c-btn__icon em-c-btn__icon-only"
            />
          )}
        </div>
      </button>
      <div
        className={clsx("em-js-show-hide-target", { "em-u-is-hidden": !open })}
      >
        {children}
      </div>
    </div>
  );
};

ExpandableButton.propTypes = {
  /** Iniital open state of the button */
  open: PropTypes.bool,
  /** Text to show on button when content is hidden. */
  showText: PropTypes.node,
  /** Text to show on button when content is shown. */
  hideText: PropTypes.node,
  /** Additional css classNames to append to the outer element */
  className: PropTypes.string,
  /** Theme color of the button */
  color: PropTypes.oneOf([
    "main",
    "accent",
    "mainToAccent",
    "accentToMain",
    "action",
    "caution",
    "positive",
    "negative",
    "blue"
  ]),
  /** React component children */
  children: PropTypes.node
};

ExpandableButton.defaultProps = {
  open: false,
  showText: "",
  hideText: "",
  className: "",
  color: "blue",
  children: null
};

export default ExpandableButton;

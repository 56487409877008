import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import HorizontalHeaderGlobalNavDropdown from "./HorizontalHeaderGlobalNavDropdown";
import HorizontalHeaderGlobalNavItem from "./HorizontalHeaderGlobalNavItem";

/** GlobalNav is the top bar on a header, typically used for global navigation. */
const HorizontalHeaderGlobalNav = props => {
  const { className, children, ...other } = props;

  return (
    <nav
      id="global-nav"
      className={clsx("em-c-global-nav", className)}
      {...other}
    >
      <ul className="em-c-global-nav__list">{children}</ul>
    </nav>
  );
};

HorizontalHeaderGlobalNav.propTypes = {
  /** Additional css classes to append to the outer element */
  className: PropTypes.string,
  /** React component children, in this case Checkboxs */
  children: PropTypes.node
};

HorizontalHeaderGlobalNav.defaultProps = {
  className: "",
  children: null
};

HorizontalHeaderGlobalNav.Item = HorizontalHeaderGlobalNavItem;
HorizontalHeaderGlobalNav.Dropdown = HorizontalHeaderGlobalNavDropdown;

export default HorizontalHeaderGlobalNav;
